import { getMainWeight } from '@apis/mainApi';
import { MainWeightResponse } from '@models/dietDiary';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

const useGetMainWeightQuery = (
  created_at: string,
  options?: UseQueryOptions<MainWeightResponse, AxiosError, MainWeightResponse>,
) => {
  return useQuery<MainWeightResponse, AxiosError, MainWeightResponse>(
    ['#weight', created_at],
    () => getMainWeight(created_at),
    {
      ...options,
    },
  );
};

export default useGetMainWeightQuery;
