import DimmedWrapper from '../Wrapper/DimmedWrapper';
import PopupBase from './PopupBase';
import PopupFood from './PopupFood';

type PopupProps = {
  headText: string;
  subText1?: string;
  subText2?: string;
  buttonText1: string;
  buttonText2?: string;
  onClick?(): void;
  onClose?(): void;
  cancel?(): void;
};

interface Props extends PopupProps {
  isFood?: boolean;
}

const Popup = (props: Props) => {
  if (props.isFood) {
    return (
      <DimmedWrapper onClose={props.onClose}>
        <PopupFood {...props} />
      </DimmedWrapper>
    );
  }

  return (
    <DimmedWrapper level={15000} onClose={props.onClose}>
      <PopupBase {...props} />
    </DimmedWrapper>
  );
};

export default Popup;
