import styled, { keyframes } from 'styled-components';
import HalfGaugeGradient from '@pages/main/TodayNutrients/components/molecules/HalfGaugeGradient';

type Status = 'none' | 'recorded' | 'exceed';

interface Props {
  day: number;
  goal: number;
  usage: number;
  themeColor: string;
}

const HalfGaugeChartSVG = ({ day, goal, usage, themeColor }: Props) => {
  const isEmpty = day === 0;
  const isFilled = 0 > goal - day;

  const radius = (304 - 48) / 2;
  const semicirclePerimeter = Math.PI * radius; // 반원 둘레
  const progressRatio = isFilled ? 0 : 1 - day / goal;
  const dashOffset = semicirclePerimeter * progressRatio;

  const progressStatus = (): Status => {
    switch (true) {
      case day === 0:
        return 'none';
      case goal + usage >= Math.round(day):
        return 'recorded';
      default:
        return 'exceed';
    }
  };

  return (
    <Container dashOffset={dashOffset} halfCircumference={semicirclePerimeter}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="304"
        height="176"
        viewBox="0 0 304 176"
        fill="none"
      >
        <path
          d="M280 152C280 81.3076 222.692 24 152 24C81.3076 24 24 81.3076 24 152"
          stroke={isEmpty ? 'url(#none)' : themeColor}
          strokeWidth="48"
          strokeLinecap="round"
        />
        <path />
        <path
          className="progress"
          d="M280 152C280 81.3076 222.692 24 152 24C81.3076 24 24 81.3076 24 152"
          stroke={`url(#${progressStatus()})`}
          strokeWidth="48"
          strokeLinecap="round"
          strokeDasharray={semicirclePerimeter}
          strokeDashoffset={dashOffset}
        />

        <HalfGaugeGradient status={progressStatus()} themeColor={themeColor} />
      </svg>
    </Container>
  );
};

export default HalfGaugeChartSVG;

const Container = styled.div<{ dashOffset: number; halfCircumference: number }>`
  width: 100%;
  position: relative;
  height: 174px;
  margin: 0 auto;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(-1);
  }

  .progress {
    animation: ${(props) => progress(props.dashOffset, props.halfCircumference)} 1.5s ease-in-out
      forwards;
  }
`;

const progress = (dashOffset: number, halfCircumference: number) => keyframes`

0% {
  stroke-dashoffset: ${halfCircumference};
}

100% { 
  stroke-dashoffset: ${dashOffset}; 
  }
`;
