import CharacterSora from '@assets/images/webp/mainTheme/character_sora.webp';
import CharacterBlack from '@assets/images/webp/mainTheme/character_black.webp';
import CharacterPink from '@assets/images/webp/mainTheme/character_pink.webp';
import CharacterYellowishGreen from '@assets/images/webp/mainTheme/character_yellowishGreen.webp';
import {
  BlackExample,
  Bread,
  DashboardSky,
  ExerciseDumbbell,
  GluttonSpoon,
  NoThinking,
  PinkExample,
  SkyExample,
  SoraExample,
  YellowishGreenExample,
} from '@assets/svgs/mainTheme';
import { MainTheme, ThemeContentsInfo, ThemeImageInfo, ThemeType } from '@models/mainTheme';

import { COLORS } from '@styles/constants/_colors';

export const mainThemePriceList: MainTheme[] = [
  {
    theme: 'sora',
    price: 0,
  },
  {
    theme: 'black',
    price: 0,
  },
  {
    theme: 'pink',
    price: 0,
  },
  {
    theme: 'yellowishGreen',
    price: 0,
  },
  {
    theme: 'skyblue',
    price: 0,
  },
];

export const themeImage: Record<ThemeType, ThemeImageInfo> = {
  sora: {
    thumbnail: <GluttonSpoon />,
    gif: CharacterSora,
    example: <SoraExample />,
  },
  black: {
    thumbnail: <ExerciseDumbbell />,
    gif: CharacterBlack,
    example: <BlackExample />,
  },
  pink: {
    thumbnail: <Bread />,
    gif: CharacterPink,
    example: <PinkExample />,
  },
  yellowishGreen: {
    thumbnail: <NoThinking />,
    gif: CharacterYellowishGreen,
    example: <YellowishGreenExample />,
  },
  skyblue: {
    thumbnail: <DashboardSky />,
    example: <SkyExample />,
  },
};

export const mainTheme: Record<ThemeType, ThemeContentsInfo> = {
  sora: {
    name: '먹보 말랑이',
    img: themeImage.sora,
    description: `먹는 것에 진심인 먹보 말랑이는\n다이어트 중에도 맛을 포기하지 않아요.\n그래서 오늘도 더 맛있는 닭가슴살과\n샐러드를 찾아 헤매고 있답니다.\n실컷 먹으며 살뺄 수 있는 방법이 있다면\n악마에게 영혼이라도 팔 수 있어요.`,
  },
  black: {
    name: '운동 말랑이',
    img: themeImage.black,
    description: `귀신보다 근손실이 무서운\n운동 말랑이는 늘 단백질이 부족해요.\n프로틴 쉐이크와 닭가슴살이\n떨어지지 않도록 항상 신경써 주세요.\n운동은 꾸준함이 생명!\n펌핑은 내 근육이 아니란 걸 기억합시다.`,
  },
  pink: {
    name: '빵순이 말랑이',
    img: themeImage.pink,
    description: `빵순이 말랑이에게 다이어트란\n더 많은 빵을 먹기 위해 한 템포\n쉬어가는 준비 과정일 뿐이에요.\n밥은 안 먹어도 살 수 있지만\n빵을 안 먹으면 죽기 때문에\n밀가루 어쩌구 조언은 사양합니다.`,
  },
  yellowishGreen: {
    name: '무념무상 말랑이',
    img: themeImage.yellowishGreen,
    description: `평생 다이어터 무념무상 말랑이는\n아무 생각이 없어요.\n왜냐면 아무 생각이 없기 때문이에요.\n다이어트 그거 뭐 알아서 되겠지...\n식단 관리와 운동 모두 귀찮지만\n오늘도 슬림탄탄한 몸매를 꿈꿔요.`,
  },
  skyblue: {
    name: '대시보드 스카이',
    img: themeImage.skyblue,
    description: `심플한 취향의 다이어터들을 위해\n데이터 표시에 초점을 맞춘 테마예요.\n탄단지 비율이 그래프로 표시되어\n칼로리보단 식단 밸런스에 집중하고 싶은\n건강한 다이어터들에게 딱 맞는\n깔끔한 테마랍니다.`,
  },
};

export const themeColorChip = {
  sora: {
    color1: COLORS.PRIMITIVES_SORA_400,
    color2: COLORS.PRIMITIVES_SORA_500,
    color3: COLORS.PRIMITIVES_SORA_550,
  },
  black: {
    color1: '#2F2F2F',
    color2: '#232323',
    color3: '#101010',
  },
  pink: {
    color1: '#FA9898',
    color2: '#F88383',
    color3: '#ED6969',
  },
  yellowishGreen: {
    color1: '#A3BE78',
    color2: '#8DA862',
    color3: '#70884F',
  },
  skyblue: {
    color1: COLORS.PRIMITIVES_SKY_BLUE_400,
    color2: COLORS.PRIMITIVES_SKY_BLUE_500,
    color3: '#469AA6',
  },
};

export const defaultTheme: MainTheme = {
  theme: 'sora',
  price: 0,
};
