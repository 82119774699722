import { CARBOHYDRATE_CALORIES, FAT_CALORIES, PROTEIN_CALORIES } from '@constants/nutrient';
import { NutrientType } from '@models/main';

export const calculateCalories = (carbohydrate: number, protein: number, fat: number) => {
  const carbohydrateCalories = carbohydrate * CARBOHYDRATE_CALORIES;
  const proteinCalories = protein * PROTEIN_CALORIES;
  const fatCalories = fat * FAT_CALORIES;

  return carbohydrateCalories + proteinCalories + fatCalories;
};

export const calculateCalorie = (type: NutrientType, value: number) => {
  const calorie: Record<NutrientType, number> = {
    net_carbohydrate: CARBOHYDRATE_CALORIES,
    protein: PROTEIN_CALORIES,
    fat: FAT_CALORIES,
  };

  return calorie[type] * value;
};
