import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import EmptyItem from './EmptyItem';
import CompletedItem from './CompletedItem';
import CheckDefault from '@assets/images/icon/Atoms/CheckDefault';
import Add from '@assets/images/icon/Add';

interface Props {
  type: 'front' | 'side';
  isDeleted?: boolean;
  imgUrl?: string;
  onClick?: () => void;
}

const BodyCheckItem = ({ imgUrl, type, isDeleted, onClick }: Props) => {
  const displayImage = () => {
    if (isDeleted) {
      return <EmptyItem type={type} isDeleted />;
    }
    if (imgUrl) {
      return <CompletedItem imgUrl={imgUrl} />;
    }
    return <EmptyItem type={type} />;
  };

  return (
    <Wrapper onClick={onClick}>
      <CardLayerField>{displayImage()}</CardLayerField>
      <IconBox onClick={onClick}>
        {imgUrl ? (
          <CheckDefault size="extraLarge" color="white" />
        ) : (
          <Add size="semiLarge" color={COLORS.PRIMITIVES_GRAY_400} />
        )}
      </IconBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 9/13;
`;

const CardLayerField = styled.div`
  display: flex;
  overflow: hidden;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 0;
  gap: 9px;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 16px;
`;

const IconBox = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

export default BodyCheckItem;
