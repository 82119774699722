import { useEffect, useState } from 'react';

const useScrollDirectionHook = () => {
  const [scrollDirection, setScrollDirection] = useState<null | 'down' | 'up'>(null);
  let prevY = 0;

  const checkDirection = () => {
    const scrollY = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    // if (scrollHeight <= clientHeight + 100) {
    //   setScrollDirection('down');
    // } else
    if (scrollY <= 100) {
      setScrollDirection('up');
    } else if (scrollHeight - scrollY - clientHeight < 50) {
      setScrollDirection('down');
    } else if (scrollY > prevY && scrollDirection !== 'down') {
      setScrollDirection('down');
    } else if (scrollY <= prevY && scrollDirection !== 'up') {
      setScrollDirection('up');
    }
    prevY = scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', checkDirection);
    return () => {
      window.removeEventListener('scroll', checkDirection);
    };
  }, []);

  return {
    scrollDirection,
  };
};

export { useScrollDirectionHook };
