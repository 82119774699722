import { ConvertTimeDd } from 'pages/exercise/utils/styles';
import { cx } from 'styles';

interface Props {
  num: number;
  type?: 'main' | 'detail';
}

const convertTime = ({ num, type }: Props) => {
  if (num < 60)
    return (
      <ConvertTimeDd className={cx([type])}>
        <mark>{num}</mark>분
      </ConvertTimeDd>
    );
  if (num % 60 === 0)
    return (
      <ConvertTimeDd className={cx([type])}>
        <mark>{Math.floor(num / 60)}</mark>시간
      </ConvertTimeDd>
    );
  return (
    <ConvertTimeDd className={cx([type])}>
      <div>
        <span className="time">
          <mark>{Math.floor(num / 60)}</mark> 시간
        </span>
        <span className="minute">
          <mark>{num % 60}</mark>분
        </span>
      </div>
    </ConvertTimeDd>
  );
};

export default convertTime;
