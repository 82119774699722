export function useCoordinate() {
  const setCoordinate = () => {
    const y = window.scrollY;
    sessionStorage.setItem('yCoordinate', y.toString());
  };

  return {
    setCoordinate,
  };
}
