import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import { useRecoilValue } from 'recoil';
import { dateState } from '@states/DateState';
import BodyCheckItem from '@pages/main/MyChange/bodyCheck/components/BodyCheckItem';
import usePostMessageReceiver from '@hooks/usePostMessageReceiver';
import { NativeMessage } from '@models/native';
import dayjs from 'dayjs';
import SpinnerInBox from '@components/SpinnerInBox';
import useTopModal from '@hooks/useTopModal';
import NotificationModal from '@components/modal/NotificationModal';
import WarningImage from '@assets/images/modal/notification/warning_image.png';
import { getCookie, setCookie } from '@utils/cookie';
import { CookieKey } from '@constants/cookie';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { useNavigate } from 'react-router-dom';

const BodyCheck = () => {
  const navigate = useNavigate();
  const [photos, setPhotos] = useState({
    front: {
      src: '',
      imageIdx: '',
    },
    side: {
      src: '',
      imageIdx: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const modalConfirmed = getCookie(CookieKey.BODY_CHECK_MODAL);

  const { openModal, closeModal } = useTopModal();

  const selectedDate = useRecoilValue(dateState);

  const createPopupOpen = (type: string) => {
    sendReactNativeMessage({
      type: 'body-check-create-open',
      payload: {
        type,
        createdAt: dayjs(selectedDate).format('YYYY-MM-DD'),
      },
    });
  };

  const updatePopupOpen = (type: string) => {
    sendReactNativeMessage({
      type: 'body-check-detail-open',
      payload: {
        type,
        selectedDate: dayjs(selectedDate).format('YYYY-MM-DD'),
      },
    });
  };

  usePostMessageReceiver((event: NativeMessage) => {
    try {
      const { type, data } = JSON.parse(event.data) as NativeMessage;
      console.log('Native event: ', data);

      const receiveHandler: {
        [key: string]: () => void;
      } = {
        'today-body-check': () => {
          setIsLoading(true);
          const photos = JSON.parse(data);
          setPhotos(photos);
          setIsLoading(false);
        },
        'move-to-backup-setting': () => {
          navigate('/myPage/setting/bodyCheckBackup');
        },
      };

      receiveHandler[type]?.();
    } catch (e) {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    try {
      setIsLoading(true);

      // try/catch 문을 사용하기 위해 sendReactNativeMessage 함수를 사용하지 않고 내부 로직을 직접 가져와서 사용했습니다.
      const sendData = {
        type: 'get-body-check-image',
        payload: JSON.stringify({
          selectedDate: dayjs(selectedDate).format('YYYY-MM-DD'),
        }),
      };
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(sendData));
    } catch (e) {
      setIsLoading(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (modalConfirmed !== 'opened') {
      openModal(NotificationModal, {
        headerText: '폰에서 원본 사진을 지우면\n눈바디 기록도 삭제돼요',
        descriptionText: '신체 사진은 민감한 개인 정보라\n인아웃 서버에 저장하지 않아요',
        warningView: (
          <WarningImageBox>
            <img src={WarningImage} alt="warningImage" />
          </WarningImageBox>
        ),
        onClose: () => {
          setCookie(CookieKey.BODY_CHECK_MODAL, 'opened');
          sendReactNativeMessage({
            type: 'cloud-available',
          });
          closeModal(NotificationModal);
        },
      });
    }
  }, []);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <SpinnerInBox />
      </SpinnerWrapper>
    );
  }

  return (
    <Wrapper>
      <TitleBox>
        <span>오늘 내 눈바디는</span>
        <span className="special">?</span>
      </TitleBox>
      <ContentField>
        <BodyCheckItem
          type="front"
          imgUrl={photos.front.src}
          isDeleted={!!photos.front.imageIdx && !photos.front.src}
          onClick={() =>
            photos.front.imageIdx ? updatePopupOpen('front') : createPopupOpen('front')
          }
        />
        <BodyCheckItem
          type="side"
          imgUrl={photos.side.src}
          isDeleted={!!photos.side.imageIdx && !photos.side.src}
          onClick={() => (photos.side.imageIdx ? updatePopupOpen('side') : createPopupOpen('side'))}
        />
      </ContentField>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 28px;

  padding: 92px 20px 99px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 491px;
`;

const TitleBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: ${COLORS.WHITE};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.08px;

  .special {
    font-family: Campton;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.09px;
  }
`;

const ContentField = styled.div`
  display: flex;
  gap: 13px;
`;

const WarningImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 101px;
  padding-top: 27px;
  > img {
    width: 120px;
    object-fit: contain;
  }
`;

export default BodyCheck;
