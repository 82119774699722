interface Props {
  size: '9*13' | '6*12';
}

export default function MainCalroieSlice({ size }: Props) {
  const slice = (() => {
    switch (size) {
      case '9*13':
        return {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="13"
              viewBox="0 0 9 13"
              fill="none"
            >
              <path d="M5.61667 0H8.75L3.25 13H0.25L5.61667 0Z" fill="white" />
            </svg>
          ),
        };

      case '6*12':
        return {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="13"
              viewBox="0 0 6 13"
              fill="none"
            >
              <path d="M4 0.5H6L2 12.5H0L4 0.5Z" fill="white" />
            </svg>
          ),
        };

      default:
        return {
          icon: <></>,
        };
    }
  })();

  return <>{slice.icon}</>;
}
