import axios, { AxiosRequestConfig } from 'axios';
import { ACCESS_TOKEN, SEARCHURL, CALENDER_DIARY_URL, BACKURL } from '../../shared/link';
import { deleteCookie, getCookie } from '../../utils/cookie';

export const exerciseSearchAxios = axios.create({
  baseURL: BACKURL,
});

exerciseSearchAxios.interceptors.request.use((config) => {
  const token = getCookie('is_login');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

// grapql
export const searchAxios = axios.create({
  baseURL: SEARCHURL,
});

export function calenderDiaryQl<T>(config?: AxiosRequestConfig) {
  return searchAxios.post<T>(CALENDER_DIARY_URL, config?.params);
}

export async function searchGet<T>(config?: AxiosRequestConfig) {
  return searchAxios.get<T>(SEARCHURL, config);
}

export async function post<T>(config?: AxiosRequestConfig, data?: any) {
  return searchAxios.post<T>(SEARCHURL, config, data);
}

searchAxios.interceptors.request.use((config) => {
  const token = getCookie('is_login');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

searchAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errCode = error.response.status;
    if (errCode === 409) {
      deleteCookie(ACCESS_TOKEN);
      window.location.href = '/login';
    } else if (errCode === 500) {
      //
    }
    return Promise.reject(error);
  },
);
