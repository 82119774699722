import { del, get, post } from '@modules/HttpWebClient/default';
import {
  FoodDetailImage,
  MealListConfirmRequest,
  RegisteredFoodList,
  RequestDate,
} from '@models/dietDiary';
import { FoodTimeType } from '@constants/food';

export const setFoodBasket = async (
  request: Omit<MealListConfirmRequest, 'existingFoodMenuRegIdxArray'> & { memberIdx: number },
) => {
  const { foods, memberIdx, timeDateInformation } = request;
  const url = `/members/${memberIdx}/foods/${timeDateInformation.created_at}/${timeDateInformation.time}`;
  await post(url, foods);
};

export async function getRegisteredList({
  time,
  created_at,
}: RequestDate): Promise<RegisteredFoodList> {
  const url = `/food/reg/${time}/${created_at}`;
  const { data } = await get<RegisteredFoodList>(url);
  return data;
}

export async function getFoodImageListByTime(
  memberIdx: number,
  time: FoodTimeType,
  createdAt: string,
) {
  const url = `/members/${memberIdx}/foods/imgs/${createdAt}/${time}`;
  const { data } = await get<FoodDetailImage[]>(url);

  return data;
}

export async function deleteFoodImage(memberIdx: number, menuImgIdx: number) {
  const url = `/members/${memberIdx}/foods/imgs/${menuImgIdx}`;
  const { status } = await del(url);
  return status === 200;
}
