import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import React from 'react';
import ForceRouter from '@routes/ForceRouter';
import ForceUpdateModal from 'components/modal/forceUpdateModal/ForceUpdateModal';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

export const App: React.FC = () => {
  const classes = useStyle();

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Grid className={classes.background} />
      <Grid className={classes.inoutMode}>
        <ForceRouter />
        <Grid className={classes.splash}>
          <ForceUpdateModal />
        </Grid>
      </Grid>
    </StylesProvider>
  );
};

const useStyle = makeStyles(() =>
  createStyles({
    inoutMode: {
      position: 'relative',
      width: '100vw',
      maxWidth: 428,
      height: 'auto',
      minHeight: '100vh',
      margin: 'auto',
      background: '#fff',
    },
    background: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: '#F3F4F5',
    },
    splash: {
      position: 'absolute',
      top: 0,
      zIndex: 10000,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      background: '#fff',
    },
  }),
);

export default App;
