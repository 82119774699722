import { atom } from 'recoil';
import {
  KetogramCategoryNumber,
  KetogramFeed,
  KetogramImg,
  KetogramPostingModal,
  PopularKetogramFeed,
  ReplyInfo,
} from '../types/community';

interface KetogramPostingData {
  title: string;
  description: string;
  img: string;
  imgs: string[];
  time: number;
  created_at: string;
  my_recipe_idx: number;

  imgList: KetogramImg[];
  text: string;
}

const ketogramPostingState = atom<KetogramPostingData>({
  key: 'ketogramPostingMeal',
  default: {
    title: '',
    description: '',
    img: 'null',
    imgs: [],
    time: 0,
    created_at: '',
    my_recipe_idx: 0,
    imgList: [],
    text: '',
  },
});

const ketogramCategoryIndexState = atom<KetogramCategoryNumber>({
  key: 'ketogramCategoryIndexState',
  default: 99,
});

const ketogramFeedListState = atom<KetogramFeed[]>({
  key: 'ketogramFeedListState',
  default: [],
});

const ketogramFeedIndexState = atom<number>({
  key: 'ketogramFeedIndexState',
  default: 0,
});

const ketogramReplyInfoState = atom<ReplyInfo>({
  key: 'ketogramReplyInfoState',
  default: {
    comment_idx: undefined,
    member_idx: undefined,
    member_name: undefined,
  },
});

const ketogramMemberNameState = atom<string>({
  key: 'ketogramMemberNameState',
  default: '',
});

const ketogramPostingModalState = atom<KetogramPostingModal>({
  key: 'ketogramPostingModalState',
  default: {
    open: false,
    proccess: 'type',
  },
});

const KetogramModalState = atom<boolean>({
  key: '#KetogramModalActive',
  default: false,
});

const KetogramModalTypeState = atom<'meal' | 'recipe' | ''>({
  key: '#KetogramModalTypeState',
  default: '',
});

const KetogramBlockState = atom<Map<number, boolean>>({
  key: '#KetogramBlockState',
  default: new Map(),
});

// 키토그램 인기 게시물 가상뷰 list
const KetogramVirtualizedList = atom<Map<number, PopularKetogramFeed>>({
  key: '#KetogramVirtualizedList',
  default: new Map<number, PopularKetogramFeed>(),
});

export {
  ketogramPostingState,
  ketogramFeedListState,
  ketogramReplyInfoState,
  ketogramMemberNameState,
  ketogramPostingModalState,
  ketogramCategoryIndexState,
  ketogramFeedIndexState,
  KetogramModalState,
  KetogramModalTypeState,
  KetogramBlockState,
  KetogramVirtualizedList,
};
