import { BreakfastIcon, DessertIcon, DinnerIcon, LunchIcon } from '@assets/svgs/food';

interface Props {
  mealTime: number;
}

const MealTimeIcon = ({ mealTime }: Props) => {
  const icon = {
    0: <BreakfastIcon />,
    1: <LunchIcon />,
    2: <DinnerIcon />,
    3: <DessertIcon />,
  }[mealTime];
  return <>{icon}</>;
};

export default MealTimeIcon;
