interface Props {
  size?: 'default' | 'medium' | 'small';
}

export default function FireDefault({ size = 'default' }: Props) {
  const fireIcon = {
    default: <FireDefaultSize />,
    medium: <FireMediumSize />,
    small: <FireSmallSize />,
  }[size];
  return fireIcon;
}

const FireDefaultSize = () => {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.782 9.82016C13.3588 6.31674 8.29174 6.12783 9.51546 1.03586C9.60611 0.658037 9.18007 0.366086 8.83562 0.563582C5.54517 2.40116 3.17931 6.0849 5.16446 10.9107C5.32762 11.3057 4.83813 11.6749 4.48461 11.4173C2.84392 10.2409 2.67169 8.54931 2.81673 7.33857C2.87111 6.89206 2.25472 6.67739 1.99185 7.04662C1.37546 7.93965 0.75 9.38223 0.75 11.5547C1.09445 16.3633 5.38201 17.8402 6.92299 18.0292C9.12568 18.2953 11.5097 17.9089 13.2229 16.4234C15.1083 14.7662 15.7972 12.1214 14.782 9.82016Z"
        fill="#FF4800"
      />
    </svg>
  );
};

const FireMediumSize = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2822_64429)">
        <path
          d="M15.5801 12.2541C14.0101 8.17407 8.4201 7.95407 9.7701 2.02407C9.8701 1.58407 9.4001 1.24407 9.0201 1.47407C5.3901 3.61407 2.7801 7.90407 4.9701 13.5241C5.1501 13.9841 4.6101 14.4141 4.2201 14.1141C2.4101 12.7441 2.2201 10.7741 2.3801 9.36407C2.4401 8.84407 1.7601 8.59407 1.4701 9.02407C0.790098 10.0641 0.100098 11.7441 0.100098 14.2741C0.480098 19.8741 5.2101 21.5941 6.9101 21.8141C9.3401 22.1241 11.9701 21.6741 13.8601 19.9441C15.9401 18.0141 16.7001 14.9341 15.5801 12.2541Z"
          fill="#FF4800"
        />
      </g>
      <defs>
        <clipPath id="clip0_2822_64429">
          <rect width="16.225" height="22.0196" fill="white" transform="translate(0 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const FireSmallSize = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8573_4139)">
        <path
          d="M11.6851 9.19058C10.5076 6.13058 6.31507 5.96558 7.32757 1.51808C7.40257 1.18808 7.05007 0.933084 6.76507 1.10558C4.04257 2.71058 2.08507 5.92808 3.72757 10.1431C3.86257 10.4881 3.45757 10.8106 3.16507 10.5856C1.80757 9.55808 1.66507 8.08058 1.78507 7.02308C1.83007 6.63308 1.32007 6.44558 1.10257 6.76808C0.592573 7.54808 0.0750732 8.80808 0.0750732 10.7056C0.360073 14.9056 3.90757 16.1956 5.18257 16.3606C7.00507 16.5931 8.97757 16.2556 10.3951 14.9581C11.9551 13.5106 12.5251 11.2006 11.6851 9.19058Z"
          fill="#FF4800"
        />
      </g>
      <defs>
        <clipPath id="clip0_8573_4139">
          <rect width="12.1688" height="16.5147" fill="white" transform="translate(0 0.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};
