import { exerciseSearchAxios } from 'modules/HttpWebClient/search';
import {
  ExerciseBookMarkResponse,
  ExerciseCategoriesResponse,
  ExerciseDetailByIdxResponse,
  ExerciseFormType,
  ExerciseFreeFormType,
  ExerciseListBySubCategoryResponse,
  ExerciseMainInfoType,
  ExerciseRegistrationRequestFormType,
  ExerciseSubCategoriesResponse,
  ExerciseWalkRequest,
} from 'types/exercise';

/**
 *
 * @remark 메인페이지에서 운동정보 가져오기
 * @return
 *   "totalCalories": number,
 *   "totalTime": string,
 *   "exerciseCalories": number
 */
export async function getExerciseMain({
  memberIdx,
  createdAt,
}: {
  memberIdx: number;
  createdAt: string;
}) {
  const { data } = await exerciseSearchAxios.get<ExerciseMainInfoType>(
    `/members/${memberIdx}/exercise/${createdAt}`,
  );

  return data;
}

/**
 *
 * @remark 운동 카테고리 가져오기
 */
export async function getExerciseCategories() {
  const { data } = await exerciseSearchAxios.get<ExerciseCategoriesResponse[]>(
    '/exercise/categories',
  );
  return data;
}

/**
 *
 * @remark 즐겨찾기 (북마크) 리스트 가져오기
 */
export async function getExerciseSearchBookMark(memberIdx?: number) {
  const { data } = await exerciseSearchAxios.get<ExerciseBookMarkResponse[]>(
    `/members/${memberIdx}/bookmark/exercise`,
  );
  return data;
}

/**
 *
 * @remark 해당 운동이 북마크된 운동인지 아닌지 조회
 */
export async function getCheckExerciseBookMark({
  memberIdx,
  exerciseInfoIdx,
}: {
  memberIdx?: number;
  exerciseInfoIdx: number;
}) {
  const { data } = await exerciseSearchAxios.get<boolean>(
    `/members/${memberIdx}/bookmark/exercise/${exerciseInfoIdx}`,
  );
  return data;
}

/**
 *
 * @remark 운동 검색 결과
 */
export async function getExerciseSearchList(keyword: string) {
  const { data } = await exerciseSearchAxios.get<ExerciseListBySubCategoryResponse[]>(`/exercise`, {
    params: {
      exerciseTitle: keyword,
    },
  });
  return data;
}

/**
 *
 * @remark 북마크 등록
 */
export async function postExerciseBookMark({
  memberIdx,
  exerciseInfoIdx,
  createdAt,
}: {
  memberIdx?: number;
  exerciseInfoIdx: number;
  createdAt: string;
}) {
  const { data } = await exerciseSearchAxios.post<{ msg: string }>(
    `/members/${memberIdx}/bookmark/exercise`,
    {
      exerciseInfoIdx,
      createdAt,
    },
  );
  return data;
}

/**
 *
 * @remark 북마크 삭제
 */
export async function deleteExerciseBookMark({
  memberIdx,
  exerciseInfoIdx,
}: {
  memberIdx?: number;
  exerciseInfoIdx: number;
}) {
  const { data } = await exerciseSearchAxios.delete(
    `/members/${memberIdx}/bookmark/exercise/${exerciseInfoIdx}`,
  );
  return data;
}

/**
 *
 * @remark 서브카테고리 리스트 가져오기
 */
export async function getExerciseSubCategories(mainCategory: string) {
  const { data } = await exerciseSearchAxios.get<ExerciseSubCategoriesResponse[]>(
    `/exercise/categories/${mainCategory}/categories`,
  );
  return data;
}

/**
 *
 * @remark 서브카테고리 별 운동 리스트 가져오기
 */
export async function getExerciseListBySubCategory({
  mainCategory,
  subCategory,
}: {
  mainCategory: string;
  subCategory: string;
}) {
  const { data } = await exerciseSearchAxios.get<ExerciseListBySubCategoryResponse[]>(
    `/exercise/categories/${mainCategory}/categories/${subCategory}`,
  );
  return data;
}

/**
 *
 * @remark exerciseInfoIdx에 해당하는 운동 상세정보 가져오기
 */
export async function getExerciseDetailByIdx(exerciseInfoIdx: number) {
  const { data } = await exerciseSearchAxios.get<ExerciseDetailByIdxResponse>(
    `/exercise/${exerciseInfoIdx}`,
  );
  return data;
}

/**
 *
 * @param { memberIdx, createdAt }
 * @returns weight
 */
export async function getWeight({
  memberIdx,
  createdAt,
}: {
  memberIdx: number;
  createdAt: string;
}) {
  const { data } = await exerciseSearchAxios.get<{ weight: number }>(
    `/members/${memberIdx}/weight/${createdAt}`,
  );
  return data;
}

/**
 *
 * @param { memberIdx, createdAt, exerciseList }
 * @returns w
 */

export async function postExerciseList({
  memberIdx,
  createdAt,
  exerciseBasketList,
}: {
  memberIdx: number;
  createdAt: string;
  exerciseBasketList: (ExerciseFormType | ExerciseFreeFormType)[];
}) {
  const { data } = await exerciseSearchAxios.post<{ msg: string }>(
    `/members/${memberIdx}/exercise/${createdAt}`,
    [...exerciseBasketList],
  );
  return data;
}

export async function getExerciseList({
  memberIdx,
  createdAt,
}: {
  memberIdx: number;
  createdAt: string;
}) {
  const { data } = await exerciseSearchAxios.get<ExerciseFormType[]>(
    `/members/${memberIdx}/exercise/list/${createdAt}`,
  );

  return data;
}

interface ExerciseRequestType extends ExerciseWalkRequest {
  memberIdx: number;
}

export async function postExerciseWalks({ walks, createdAt, memberIdx }: ExerciseRequestType) {
  const { data } = await exerciseSearchAxios.post<{ msg: string }>(
    `/members/${memberIdx}/exercise/walk`,
    {
      walks,
      created_at: createdAt,
    },
  );
  return data;
}

export async function getExerciseWalks({
  createdAt,
  memberIdx,
}: {
  createdAt: string;
  memberIdx: number;
}) {
  const { data } = await exerciseSearchAxios.get<{
    member_idx: number;
    walks: number;
    exerciseCalories: number;
    createdAt: string;
  }>(`/members/${memberIdx}/exercise/walk/${createdAt}`);
  return data;
}

export async function postExerciseRegistrationRequest({
  form,
  memberIdx,
}: {
  form: ExerciseRegistrationRequestFormType;
  memberIdx: number;
}) {
  const { data } = await exerciseSearchAxios.post<{ success: boolean; message: string }>(
    `/members/${memberIdx}/report/exercise`,
    {
      ...form,
    },
  );
  return data;
}
