const setCookie = (name: string, value: string, period?: number): void => {
  const date = new Date();

  date.setTime(date.getTime() + 1 * 1000 * 60 * 60 * 24 * (period ? period : 365));
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;`;
};

// fcmtoken
const setCookieApp = (name: string, value: string): void => {
  const token = value.split('/')[0];
  const date = new Date(Number(value.split('/')[1]) + 1 * 1000 * 60 * 60 * 24);
  document.cookie = `${name}=${token};expires=${date.toUTCString()};path=/;`;
};

// fcmtoken
const setCookieMonth = (name: string, value: string): void => {
  const token = value.split('/')[0];
  const date = new Date(Number(value.split('/')[1]) + 1 * 1000 * 60 * 60 * 24 * 30);
  document.cookie = `${name}=${token};expires=${date.toUTCString()};path=/;`;
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts: string[] = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const part: any = parts.pop();
    return part.split(';').shift();
  }
};

const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1999 00:00:10 GMT;path=/;`;
};

export { setCookie, getCookie, deleteCookie, setCookieApp, setCookieMonth };
