export const RouterPrefix = {
  MY_PAGE: '/myPage',
} as const;
export const HomePath = {
  THEME_SELECT: 'home/themeSelect',
} as const;

export const MyPagePath = {
  MAIN: '/',
  NEWS: '/news/:type',
  PROFILE: '/myProfile',
  USER_SEARCH: '/userSearch',
  FOOD_DETAIL: '/ketogram/feed/:ketogram_idx',

  SETTING: '/setting',
  ACCOUNT: '/setting/accountDisclosure',
  LOCK: '/setting/accountLock',
  ALARM: '/setting/alarm',
  ALARM_CUSTOM: '/setting/customAlarm',
  ALARM_CUSTOM_POSTING: '/setting/customAlarm/posting',
  ALARM_CUSTOM_EDIT: '/setting/customAlarm/posting/edit',
  INOUT_TEAM: '/setting/inoutTeam',
  TERMS_PRIVACY: '/setting/termsPrivacy',
  AUTH_INFO: '/setting/logoutSignout',
  MANAGE_POSTS: '/setting/managePost',
  MANAGE_SCRAPS: '/setting/manageScrap',
  MY_FOOD_REVIEW: '/setting/myFoodReview',
  COMMUNICATION: '/setting/communication',
} as const;

export const RecipePath = {
  RECIPE_REGISTRATION: '/:mealId/:page',
  INGREDIENT_DETAIL: '/ingredient/:id',
  RECIPE_DETAIL: '/detail/:mealId/:id',
} as const;

export const NotionPath = {
  LOGIN_INQUIRY: '/loginInquiry',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  UPDATE_NEWS: '/updateNews',
  FAQ: '/faq',
  ANDROID_HEALTH_CONNECT: '/androidHealthConnect',
  HEALTH_APP_AUTH: '/healthAppAuth',
  EVENT: '/event',
} as const;
