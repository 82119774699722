import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { toastMsgTextState } from '../../states/globalAlert';
import { toastMsgState } from '../../states/SearchResultState';

interface Props {
  children: React.ReactNode;
}

export default function ToastMsg({ children }: Props) {
  const classes = useStyle();
  const [onToast, setOnToast] = useRecoilState(toastMsgState);
  const setToastTextNumber = useSetRecoilState(toastMsgTextState);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (onToast) {
      setOpen(true);
      setTimeout(() => {
        setOnToast(false);
      }, 300);
      setTimeout(() => {
        setOpen(false);
        setToastTextNumber(0);
      }, 2500);
    }
  }, [onToast]);

  return (
    <>
      {open && (
        <Grid className={classes.toast} container justifyContent={'center'} alignItems={'center'}>
          <p>{children}</p>
        </Grid>
      )}
    </>
  );
}

const useStyle = makeStyles(() =>
  createStyles({
    toast: {
      position: 'fixed',
      margin: '0 auto',
      left: 0,
      right: 0,
      top: 'calc(50vh - 40px)',
      minWidth: 230,
      // maxWidth: 300,
      width: 230,
      minHeight: 40,
      opacity: 0,
      borderRadius: 5,
      background: 'rgba(0,0,0,0.75)',
      zIndex: 10000,
      animation: '$myEffect 2500ms ease',
      padding: '10px 15px',
      '& p': {
        fontSize: 14,
        lineHeight: 1.3,
        letterSpacing: -0.54,
        color: '#fff',
        textAlign: 'center',
        whiteSpace: 'pre-line',
      },
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
      },
      '10%': {
        opacity: 0,
      },
      '20%': {
        opacity: 1,
      },
      '90%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
  }),
);
