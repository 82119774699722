import { getMiddleNumber } from '@utils/getMiddleNumber';

const useBMICalculation = () => {
  const handleBMIalCalculation = (weight: number, height: number) => {
    let bmi = Number((weight / Math.pow(height / 100, 2)).toFixed(1));
    let bmiText = '';
    let bmiValue = 0;

    bmi = getMiddleNumber([0.1, bmi, 999]);

    if (bmi < 18.5) {
      bmiText = '저체중';
      bmiValue = 0.1;
    } else if (bmi >= 18.5 && bmi < 23) {
      bmiText = '정상 체중';
      bmiValue = 0.15;
    } else if (bmi >= 23 && bmi < 25) {
      bmiText = '과체중';
      bmiValue = 0.2;
    } else if (bmi >= 25 && bmi < 30) {
      bmiText = '비만';
      bmiValue = 0.25;
    } else if (bmi >= 30) {
      bmiText = '고도 비만';
      bmiValue = 0.3;
    }

    return { bmi, bmiText, bmiValue };
  };

  return { handleBMIalCalculation };
};

export default useBMICalculation;
