import { useRecoilState } from 'recoil';
import { dateState } from 'states/DateState';

import ThemeContainer from 'components/Molecules/ThemeConTainer';

import { myEatTabIndexState } from 'pages/main/states';

import WaterDiary from 'pages/main/components/WaterDiary';
import AreaFooter from 'pages/main/elements/AreaFooter';
import SupplementDiary from 'pages/main/MyEat/SupplementDiary';
import { useLayoutEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
import DietDiary from './DietDiary/DietDiary';
import { AlarmIcon } from '@assets/svgs';
import { AlbumIcon } from '@assets/svgs/_Icons';
import { useNavigate } from 'react-router-dom';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { COLORS } from '@styles/constants/_colors';

const tabList = [
  {
    name: '식단',
    id: 'theme_diet',
  },
  {
    name: '물 섭취',
    id: 'theme_water',
  },
  {
    name: '영양제',
    id: 'theme_supplement',
  },
];

const MyEat = () => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useRecoilState(myEatTabIndexState);
  const [date] = useRecoilState(dateState);
  const preDate = usePrevious(date);

  const footerInfo = useMemo(() => {
    const info = {
      0: {
        icon: <AlbumIcon />,
        text: '식단 앨범 한눈에 보기 !',
        button: {
          text: '식단 앨범',
          onClick: () => {
            sendReactNativeMessage({
              type: 'food-album-gallery-open',
              payload: {
                selectedDate: date,
              },
            });
          },
          bgColor: COLORS.PRIMITIVES_BLUE_NAVY_450,
        },
      },
      1: {
        icon: <AlarmIcon />,
        text: '물 마시는 습관 들이기',
        button: {
          text: '알림 설정',
          onClick: () => {
            navigate('/myPage/setting/customAlarm');
          },
          bgColor: COLORS.PRIMITIVES_BLUE_NAVY_450,
        },
      },
      2: {
        icon: <AlarmIcon />,
        text: '영양제 꼭꼭 챙겨먹기',
        button: {
          text: '알림 설정',
          onClick: () => {
            navigate('/myPage/setting/customAlarm');
          },
          bgColor: COLORS.PRIMITIVES_BLUE_NAVY_450,
        },
      },
    }[tabIndex as 0 | 1 | 2];

    return info;
  }, [tabIndex]);

  useLayoutEffect(() => {
    if (preDate === undefined || preDate === date) return;
    setTabIndex(0);
  }, [date, preDate]);

  return (
    <>
      <ThemeContainer
        mainTitle="먹었어요"
        bgColor="navy"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        tabList={tabList}
        minHeight="553"
        maxHeight="680"
        paddingBottom="30"
      >
        {tabIndex === 0 && <DietDiary />}
        {tabIndex === 1 && <WaterDiary />}
        {tabIndex === 2 && <SupplementDiary />}
        <div>
          <AreaFooter footerInfo={footerInfo} />
        </div>
      </ThemeContainer>
    </>
  );
};

export default MyEat;
