import { getExerciseWalks, postExerciseWalks } from '@apis/exercise';
import RefreshArrow from '@assets/svgs/_Icons/arrow/RefreshArrow';
import { WidgetKind } from '@constants/widget';
import useHealthExceptionCase from '@hooks/health/useHealthExceptionCase';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import useToastModal from '@hooks/useToastModal';
import { deviceOs } from '@modules/platformCheck';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { COLORS } from '@styles/constants/_colors';
import { WalkingBurning } from 'assets/svgs';
import SpinnerInBox from 'components/SpinnerInBox';
import useCount from 'pages/main/hooks/useCount';
import usePostMessageReceiver from 'pages/main/hooks/usePostMessageReceiver';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { dateState } from 'states/DateState';
import { ShoesIllustWrapper } from '../walkingInterlock/styles';
import { Container, Progress, ProgressBar } from './styles';

const SHOES_UNDER_100PERCENT_URL =
  'https://dw543otwhx7wx.cloudfront.net/main/main4-walkingShoesWithGrass.png';

const SHOES_OVER_100PERCENT_URL =
  'https://dw543otwhx7wx.cloudfront.net/main/main4-walkingShoesCongratulation.png';

const WalkingDetail = () => {
  const FINAL_VALUE = 10000;
  const widgetKinds = [WidgetKind.DAILY_HEALTH_LOG, WidgetKind.DASHBOARD];
  const [walk, setWalk] = useState(0);
  const [kcal, setKcal] = useState(0);
  const date = useRecoilValue(dateState);
  const { openModal } = useToastModal();
  const {
    onAndroidHealthNotionPage,
    onUnSyncHealthDataPopup,
    redirectHealthConnectPlayStore,
    checkHealthConnectModalView,
  } = useHealthExceptionCase();

  const count = useCount(walk, 1300);
  const kcalCount = useCount(kcal, 1300);

  const { data: userData } = useUserInfoQuery();
  const memberIdx = useMemo(() => userData?.member_idx ?? 0, [userData?.member_idx]);
  const { refetch, isLoading } = useQuery(
    ['#walks', memberIdx, date],
    () => getExerciseWalks({ memberIdx, createdAt: date }),
    {
      enabled: false,
      onSuccess: (res) => {
        setWalk(res.walks || 0);
        setKcal(res.exerciseCalories || 0);
        openModal({ children: <p>업데이트가 완료되었어요</p> });
        widgetKinds.forEach((widgetKind) => {
          sendReactNativeMessage({
            type: 'widgetReloadTimelines',
            payload: {
              widgetKind,
            },
          });
        });
      },
      onError: (err) => {
        onUnSyncHealthDataPopup();
      },
    },
  );

  const { mutate } = useMutation(postExerciseWalks);

  const onWalkingPostMessage = () => {
    sendReactNativeMessage({
      type: 'health',
      payload: { date },
      service: 'getStep',
    });
  };

  usePostMessageReceiver((event: any) => {
    const { type, data } = JSON.parse(event.data);

    if (type === 'healthConnectUnInstall') {
      redirectHealthConnectPlayStore();
      return;
    }

    if (type === 'getStep' && data !== '') {
      onWalking(Math.round(data));
      return;
    } else if (type === 'getStep' || type === 'iosHealthSyncFail') {
      onUnSyncHealthDataPopup();
      return;
    }
  });

  const onWalking = (data: number) => {
    mutate(
      { memberIdx, createdAt: date, walks: data },
      {
        onSuccess: async () => {
          await refetch();
        },
        onError: (err) => {
          console.log('err: ', err);
        },
      },
    );
  };

  const onPercent = () => {
    return (walk / FINAL_VALUE) * 100 > 100 ? 100 : Math.floor((walk / FINAL_VALUE) * 100);
  };

  useEffect(() => {
    if (deviceOs === 'android') {
      const isCheckedModal = checkHealthConnectModalView();
      if (isCheckedModal !== 'checked') {
        onAndroidHealthNotionPage();
        return;
      }
    }
    onWalkingPostMessage();
  }, []);

  if (isLoading) {
    return <SpinnerInBox height={'calc(97.6vw + 93px)'} />;
  }

  return (
    <Container>
      <b className="title">오늘 걸음 수</b>
      <b className="content">{count}</b>
      <div className="time" onClick={onWalkingPostMessage}>
        <p>데이터 업데이트</p>
        <span className="refresh">
          <RefreshArrow color={COLORS.PRIMITIVES_KHAKI_500} />
        </span>
      </div>

      <ShoesIllustWrapper
        src={onPercent() === 100 ? SHOES_OVER_100PERCENT_URL : SHOES_UNDER_100PERCENT_URL}
      />

      <div className="bottom">
        <dl className="info">
          <dt>
            매일 만보 걷기<span className="bang">!</span>
          </dt>
          <dd>
            <span>{onPercent()}%</span> 달성
          </dd>
        </dl>
        <Progress>
          <ProgressBar percent={onPercent()} />
        </Progress>
        <dl className="kcal">
          <span>
            <WalkingBurning />
          </span>
          <dt>소모량</dt>
          <dd>{kcalCount}kcal</dd>
        </dl>
      </div>
    </Container>
  );
};

export default WalkingDetail;
