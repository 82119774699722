const S3Address = [
  'inout-posting-imgs.s3-ap-northeast-2.amazonaws.com', // web 업로드 주소
  'inout-posting-imgs.s3.amazonaws.com', // RN 업로드 주소
];
const CDNAddress = 'dw543otwhx7wx.cloudfront.net';

/**
 * @description
 * CDN에서 1순위로 resized 버킷에서 이미지 url get
 * 없다면, 2순위로 원본 버킷에서 이미지 url get
 */

const CDNURLConvert = (URL: string | null | undefined) => {
  if (!URL) return '';

  const handleReplace = () => {
    const address = S3Address.find((address) => URL.includes(address));
    if (address) {
      return URL.replace(address, CDNAddress);
    } else return URL;
  };

  return handleReplace();
};

export default CDNURLConvert;
