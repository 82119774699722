export default function DropDownDefault() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.888855 3.6665H4.98811H9.08737L4.98811 7.76576L0.888855 3.6665Z"
        fill="black"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}
