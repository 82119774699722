import { Grid, makeStyles, createStyles } from '@material-ui/core';
import classNames from 'classnames';
import { CupMasking } from 'assets/svgs/water';
import waterCupWave from 'assets/images/png/waterCupWave.png';

interface Props {
  waterTotal: number;
}

export default function WaterCup({ waterTotal }: Props) {
  const classes = useStyles();

  const waterTop = 120 - waterTotal * 0.055;

  return (
    <>
      <Grid
        className={classes.cup}
        container
        justifyContent={'space-between'}
        alignItems={'flex-end'}
      >
        <Grid
          className={classNames([classes.wave])}
          style={{
            top: waterTotal === 0 ? 122 : waterTotal >= 2000 ? 11 : waterTop,
          }}
        />
        <Grid className={classes.light} />
        <Grid className={classes.maskingWrapper}>
          <CupMasking />
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wave: {
      background: `url(${waterCupWave})`,
      position: 'absolute',
      width: 1378,
      height: 120,
      top: 20,
      transition: '1500ms ease',
      animation: '$wave 2.5s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite',
      transform: 'translate3d(0, 0, 0)',
    },
    '@keyframes wave': {
      '0%': {
        marginLeft: 0,
      },
      '100%': {
        marginLeft: -700,
      },
    },
    cup: {
      position: 'relative',
      height: 122,
      width: 100,
      backgroundColor: '#DEEAFB',
      overflow: 'hidden',
    },
    maskingWrapper: {
      position: 'absolute',
      left: -25,
      top: -15,
      zIndex: 300,
      overflow: 'hidden',
    },
    light: {
      position: 'absolute',
      zIndex: 301,
      width: 10.5,
      height: 33,
      borderRadius: 20,
      backgroundColor: '#FFF',
      left: 16,
      top: 55,
      rotate: '-2deg',
    },
  }),
);
