import MainCalroieSlice from '@assets/svgs/atomic/MainCalroieSlice';
import BadgePercent from '@pages/main/TodayNutrients/components/BadgePercent';
import { COLORS } from '@styles/constants/_colors';
import { ReactNode } from 'react';
import styled from 'styled-components';
import HalfGaugeChartSVG from './molecules/HalfGaugeChartSVG';

interface Props {
  dayCalorie: number;
  goalCalorie: number;
  usageCalorie: number;
  color3: string;
  color2: string;
  characterImg: string;
  previewImg: string | ReactNode;
  dayPercent: number[];
  isFetching: boolean;
}

const CalorieGaugeView = ({
  dayCalorie,
  goalCalorie,
  usageCalorie,
  characterImg,
  color2 = '#669ACB',
  color3 = '#4A85BC',
  dayPercent,
  isFetching,
}: Props) => {
  const nutrientPercentList = [
    {
      badgeName: '탄',
      themeBadgeColor: COLORS.WHITE,
      themeTextColor: color3,
      percent: dayPercent[0],
    },
    {
      badgeName: '단',
      themeBadgeColor: COLORS.PRIMITIVES_YELLOW_400,
      themeTextColor: color3,
      percent: dayPercent[1],
    },
    {
      badgeName: '지',
      themeBadgeColor: COLORS.PRIMITIVES_NAVY_400,
      themeTextColor: COLORS.WHITE,
      percent: dayPercent[2],
    },
  ];

  return (
    <Wrapper>
      <CalorieTextBox themeColor={color3}>
        <span className="day">{Math.round(dayCalorie)}</span>
        <MainCalroieSlice size="9*13" />
        <span className="goal">{Math.round(goalCalorie)} kcal</span>
      </CalorieTextBox>

      <NutrientsPercentBox>
        {nutrientPercentList.map((v, idx) => (
          <BadgePercent key={idx} {...v} />
        ))}
      </NutrientsPercentBox>

      <GaugeChartField>
        <HalfGaugeChartSVG
          day={dayCalorie}
          goal={goalCalorie}
          usage={usageCalorie}
          themeColor={color2}
        />
        <MainCharacter>{!isFetching && <img src={characterImg} />}</MainCharacter>
      </GaugeChartField>
    </Wrapper>
  );
};

export default CalorieGaugeView;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CalorieTextBox = styled.div<{ themeColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5.25px;

  margin-top: 15px;

  font-family: Campton;
  letter-spacing: -0.5px;

  .day {
    font-size: 32px;
    font-weight: 600;
    color: ${COLORS.WHITE};
    margin-bottom: 5px;
  }

  .goal {
    font-family: Campton;
    font-size: 18px;
    font-weight: 800;
    color: ${(props) => props.themeColor};
  }

  svg > path {
    fill: ${(props) => props.themeColor};
  }
`;

const NutrientsPercentBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;

  width: 100%;
  margin-top: 6px;
`;

const GaugeChartField = styled.div`
  width: 100%;
  position: relative;
  margin-top: 24px;
`;

const MainCharacter = styled.div`
  display: flex;
  position: absolute;

  width: 100%;
  justify-content: center;
  top: 0;

  > * {
    max-width: 250px;
  }
`;
