import {
  BoardRequest,
  CommunityReport,
  KetogramComment,
  KetogramCommentRequest,
  KetogramDetailCheck,
  KetogramDetailInfo,
  KetogramEditRequest,
  KetogramFeed,
  KetogramFeedResponse,
  KetogramFollower,
  KetogramFollowing,
  KetogramLike,
  KetogramMealDetailRequest,
  KetogramMealRequest,
  KetogramNormalRequest,
  KetogramPostingMealResponse,
  KetogramPostingRecipeResponse,
  KetogramRecipeRequest,
  KetogramReplyEditRequest,
  KetogramReplyRequest,
  PopularCommunityTypeEN,
  PopularFeedResponse,
  ResponseKetogramComment,
  ResponseKetogramReply,
  ResponseMsg,
  popularKetogramQueryParams,
} from '@models/community';
import { RegisteredFoodList } from '@models/dietDiary';
import {
  Feed,
  MyProfileRequest,
  NavigationNews,
  News,
  NewsStatus,
  ProfileInfo,
  ScrapFeed,
} from '@models/myPage';
import { del, get, patch, post } from '@modules/HttpWebClient/default';

export async function postKetogramNormal(request: KetogramNormalRequest): Promise<ResponseMsg> {
  const url = '/ketogram/diary';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function postKetogramRecipe(request: KetogramRecipeRequest): Promise<ResponseMsg> {
  const url = '/ketogram/recipe';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function postingKetogramMeal(request: KetogramMealRequest): Promise<ResponseMsg> {
  const url = '/ketogram/menu';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function getKetogramFeedDetail(ketogram_idx: number): Promise<KetogramFeed> {
  const url = `/ketogram/feed/detail/${ketogram_idx}`;
  const { data } = await get<KetogramFeed>(url);
  return data;
}

export async function editKetogram(request: KetogramEditRequest): Promise<ResponseMsg> {
  const url = '/ketogram';
  const { data } = await patch<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function delKetogram(
  ketogram_idx: number,
  ketogram_type: number,
): Promise<ResponseMsg> {
  const url = `/ketogram/${ketogram_idx}/${ketogram_type}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function checkKetogramDetail(ketogram_idx: number): Promise<KetogramDetailCheck> {
  const url = `/ketogram/detail/check/${ketogram_idx}`;
  const { data } = await get<KetogramDetailCheck>(url);
  return data;
}

// regCHeck => 1: 이미 공유된 ; 0: 아직 공유 안된
export async function getPostingKetogramRecipeList(
  regCheck: 1 | 0,
): Promise<KetogramPostingRecipeResponse> {
  const url = `/ketogram/recipe/${regCheck}`;
  const { data } = await get<KetogramPostingRecipeResponse>(url);
  return data;
}
// regCHeck => 1: 이미 공유된 ; 0: 아직 공유 안된
export async function getPostingKetogramMealList(
  regCheck: 1 | 0,
): Promise<KetogramPostingMealResponse> {
  const url = `/ketogram/menu/${regCheck}`;
  const { data } = await get<KetogramPostingMealResponse>(url);
  return data;
}

export async function getKetogramFeedList(ketogram_idx: number): Promise<KetogramFeed[]> {
  const url = `/ketogram/feed/${ketogram_idx}`;
  const { data } = await get<KetogramFeed[]>(url, {
    // cancelToken: cancelSource.token,
  });
  return data;
}

export async function getKetogramFeedList2(
  ketogram_type: number,
  ketogram_idx: number,
): Promise<KetogramFeedResponse> {
  let url = `/ketogram/feed/category/${ketogram_idx}/${ketogram_type}`;

  if (ketogram_type === 99) {
    url = `/ketogram/feed/${ketogram_idx}`;
  }

  const { data } = await get<KetogramFeed[]>(url, {
    // cancelToken: cancelSource.token,
  });
  return {
    feedList: data,
    isMore: data.length === 10,
    lastIndex: data[data.length - 1].ketogram_idx,
  };
}

export async function getPopularKetogram(
  type: PopularCommunityTypeEN,
  params: popularKetogramQueryParams,
): Promise<PopularFeedResponse> {
  const url = `/v2/ketogram/popular/${type}`;

  const { data } = await get<PopularFeedResponse>(url, { params });
  return data;
}

export async function getKetogramCategoryFeedList(
  ketogram_idx: number,
  ketogram_type: number,
): Promise<KetogramFeed[]> {
  const url = `/ketogram/feed/category/${ketogram_idx}/${ketogram_type}`;
  const { data } = await get<KetogramFeed[]>(url, {});
  return data;
}

export async function getKetogramDetailSubInfo(ketogram_idx: number): Promise<KetogramDetailInfo> {
  const url = `/ketogram/detail/sub/info/${ketogram_idx}`;
  const { data } = await get<KetogramDetailInfo>(url);
  return data;
}

export async function postKetogramLike(ketogram_idx: number): Promise<ResponseMsg> {
  const url = '/ketogram/like';
  const { data } = await post<ResponseMsg>(url, {
    ketogram_idx: ketogram_idx,
  });
  return data;
}

export async function delKetogramLike(ketogram_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/like/inout/${ketogram_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function getKetogramLikeList(ketogram_idx: number): Promise<KetogramLike[]> {
  const url = `/ketogram/like/list/${ketogram_idx}`;
  const { data } = await get<KetogramLike[]>(url);
  return data;
}

export async function postKetogramFollowing(following_member_idx: number): Promise<ResponseMsg> {
  const url = '/ketogram/sns/following';
  const { data } = await post<ResponseMsg>(url, {
    following_member_idx: following_member_idx,
  });
  return data;
}

export async function delKetogramFollowing(following_member_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/sns/following/${following_member_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function getKetogramFollowingList(member_idx: number): Promise<KetogramFollowing[]> {
  const url = `/ketogram/sns/following/list/${member_idx}`;
  const { data } = await get<KetogramFollowing[]>(url);
  return data;
}

export async function getKetogramFollowerList(member_idx: number): Promise<KetogramFollower[]> {
  const url = `/ketogram/sns/follower/list/${member_idx}`;
  const { data } = await get<KetogramFollower[]>(url);
  return data;
}

export async function postKetogramScrap(ketogram_idx: number): Promise<ResponseMsg> {
  const url = '/ketogram/scrap';
  const { data } = await post<ResponseMsg>(url, {
    ketogram_idx,
  });
  return data;
}

export async function delKetogramScrap(ketogram_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/scrap/inout/${ketogram_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function postKetogramComment(
  request: KetogramCommentRequest,
): Promise<ResponseKetogramComment> {
  const url = '/ketogram/comment';
  const { data } = await post<ResponseKetogramComment>(url, {
    ...request,
  });
  return data;
}

export async function getKetogramCommentList(
  ketogram_idx: number,
  type: number,
): Promise<KetogramComment[]> {
  const url = `/ketogram/comment/${ketogram_idx}/${type}`; // type은 임시로 달아논 것입니다.
  const { data } = await get<KetogramComment[]>(url);
  return data;
}

export async function editKetogramComment(request: KetogramCommentRequest): Promise<ResponseMsg> {
  const url = '/ketogram/comment';
  const { data } = await patch<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function delKetogramComment(ketogram_comment_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/comment/inout/${ketogram_comment_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function postKetogramReply(
  request: KetogramReplyRequest,
): Promise<ResponseKetogramReply> {
  const url = '/ketogram/reply';
  const { data } = await post<ResponseKetogramReply>(url, {
    ...request,
  });
  return data;
}

export async function editKetogramReply(request: KetogramReplyEditRequest): Promise<ResponseMsg> {
  const url = '/ketogram/reply';
  const { data } = await patch<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function delKetogramReply(ketogram_reply_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/reply/inout/${ketogram_reply_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function reportCs(request: CommunityReport): Promise<ResponseMsg> {
  const url = '/cs/report';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function reportFeed(params: {
  ketogram_type: '피드' | '댓글' | '대댓글';
  report_idx: number;
}): Promise<ResponseMsg> {
  const url = `/ketogram/report?ketogram_type=${params.ketogram_type}&ketogram_type_idx=${params.report_idx}`;
  const { data } = await post<ResponseMsg>(url);
  return data;
}

export async function getKetogramRegisterdList({
  ketogram_idx,
  time,
  created_at,
}: KetogramMealDetailRequest): Promise<RegisteredFoodList> {
  const url = `/food/reg/ketogram/${time}/${created_at}/${ketogram_idx}`;
  const { data } = await get<RegisteredFoodList>(url);
  return data;
}

// 마이페이지
export async function getProfileFeedList(member_idx: number, ketogramIdx: number) {
  const url = `/profile/feed/${member_idx}/${ketogramIdx}`;
  const { data } = await get<Feed[]>(url);

  return data;
}

export async function getMyPageScrapList(
  ketogram_scrap_idx: number,
): Promise<{ myPageScrabs: ScrapFeed[]; isMore: boolean; lastIndex: number }> {
  const url = `/profile/feed/scrap/list/${ketogram_scrap_idx}`;
  const { data } = await get<ScrapFeed[]>(url);
  return {
    myPageScrabs: data,
    isMore: data.length === 10,
    lastIndex: data[data.length - 1].ketogram_scrap_idx,
  };
}

export async function getProfileInfo(memberIdx: number): Promise<ProfileInfo> {
  const url = `/profile/info/${memberIdx}`;
  const { data } = await get<ProfileInfo>(url);
  return data;
}

export async function postMyProfile(request: MyProfileRequest): Promise<ResponseMsg> {
  const url = '/profile/info';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function getNewsStatus(): Promise<NewsStatus> {
  const url = '/news/tab/check';
  const { data } = await get<NewsStatus>(url);
  return data;
}

export async function getNewsList(news_type: number, news_idx: number): Promise<News[]> {
  const url = `/news/list/${news_type}/${news_idx}`;
  const { data } = await get<News[]>(url);
  return data;
}

export async function getNavigationNews(): Promise<NavigationNews> {
  const url = '/news/navigation/check';
  const { data } = await get<NavigationNews>(url);
  return data;
}

export async function getCheckBookmark(memberIdx: number, boardIdx: number) {
  const url = `/members/${memberIdx}/bookmark/boards/${boardIdx}`;
  const { data } = await get<boolean>(url);

  return data;
}

export async function postBoardScrap({ memberIdx, boardIdx }: BoardRequest) {
  const url = `/members/${memberIdx}/bookmark/boards`;
  const { status } = await post<unknown>(url, {
    board_idx: boardIdx,
  });

  return status === 200;
}

export async function delBoardScrap({ memberIdx, boardIdx }: BoardRequest) {
  const url = `/members/${memberIdx}/bookmark/boards/${boardIdx}`;
  const { status } = await del<unknown>(url);

  return status === 200;
}
