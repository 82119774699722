import { Container, ThemeButton } from './styles';

import { useNavigate } from 'react-router-dom';

import SpinnerInBox from 'components/SpinnerInBox';
import { useCoordinate } from 'hooks/useCoordinate';
import { useDate } from 'hooks/useDate';
import useSupplement from 'pages/main/hooks/useSupplement';
import { useLayoutEffect, useState } from 'react';
import { SupplementComplete, SupplementUnComplete } from '@assets/svgs/main';

const SupplementDiary = () => {
  const navigate = useNavigate();
  const { date } = useDate();
  const { setCoordinate } = useCoordinate();
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useSupplement(date);
  const convert = data?.results
    .map((item) => item.myCheck === 1 && item.supplements_name)
    .filter((item) => item !== false);
  const totalTaken = convert?.join(', ');
  const totalCount = convert?.length;
  const isData = convert?.length;

  const moveToSupplementsPage = () => {
    setCoordinate();
    navigate('/tags/supplements');
  };

  useLayoutEffect(() => {
    (async () => {
      if (date) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      }
    })();
  }, [date]);

  if (isLoading) return <SpinnerInBox height={'492px'} />;

  if (isData)
    return (
      <Container>
        <h2>총 {totalCount}가지를 먹었어요</h2>
        <SupplementComplete />
        <span className="content">{totalTaken}</span>
        <ThemeButton color={'#22487D'}>
          <a onClick={moveToSupplementsPage}>수정하기</a>
        </ThemeButton>
      </Container>
    );

  return (
    <Container>
      <h2>
        챙겨먹은 영양제가 있나요<span className="question">?</span>
      </h2>
      <SupplementUnComplete />
      <span className="noneData">아직 기록이 없어요</span>
      <ThemeButton color={'#22487D'}>
        <a onClick={moveToSupplementsPage}>기록하기</a>
      </ThemeButton>
    </Container>
  );
};

export default SupplementDiary;
