import { createStyles, Grid, makeStyles } from '@material-ui/core';
import {
  BattleIcon,
  BattleActiveIcon,
  BattleNewsIcon,
  CommunityActiveIcon,
  CommunityIcon,
  FastActiveIcon,
  FastIcon,
  HomeActiveIcon,
  HomeIcon,
  MyActiveIcon,
  MyActiveNewsIcon,
  MyIcon,
  MyNewsIcon,
} from 'assets/svgs/tabBar';
import { exerciseThemeIndex } from 'pages/exercise/states';
import { myChangeTabIndexState, myEatTabIndexState } from 'pages/main/states';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { communityTypeState } from 'states/CommunityState';
import { countingApiInMainPage } from '../../../apis/analyticsApi';
import { NavigationNews } from '../../../types/myPage';
import { ketogramCategoryIndexState, ketogramFeedIndexState } from '../../../states/KetogramState';
import { mainDiaryIndexState } from '../../../states/MainInfoState';
import { useNavigate } from 'react-router-dom';
interface Props {
  index: number;
  isSelected: boolean;
  newsState: NavigationNews;
}

export default function Menu({ index, isSelected, newsState }: Props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const setDiaryIndexState = useSetRecoilState(mainDiaryIndexState);
  const setFeedIndexForInfiniteScroll = useSetRecoilState(ketogramFeedIndexState);
  const setCategory = useSetRecoilState(ketogramCategoryIndexState);
  const [, setCommunityType] = useRecoilState(communityTypeState);

  const [, setMyChangeTabIndex] = useRecoilState(myChangeTabIndexState);
  const [, setMyEatTabIndex] = useRecoilState(myEatTabIndexState);
  const [, SetExerciseThemeIndex] = useRecoilState(exerciseThemeIndex);

  const menuList = [
    { title: '홈', icon: <HomeIcon />, selectedIcon: <HomeActiveIcon /> },
    { title: '단식', icon: <FastIcon />, selectedIcon: <FastActiveIcon /> },
    { title: '소통', icon: <CommunityIcon />, selectedIcon: <CommunityActiveIcon /> },
    { title: '배틀', icon: <BattleIcon />, selectedIcon: <BattleActiveIcon /> },
    { title: '마이', icon: <MyIcon />, selectedIcon: <MyActiveIcon /> },
  ];
  const currentMenu = menuList[index];

  const moveToPageTop = () => {
    setDiaryIndexState(0);
    window.scrollTo(0, 0);
  };

  const resetMainIndex = () => {
    setMyChangeTabIndex(0);
    setMyEatTabIndex(0);
    SetExerciseThemeIndex(0);
  };

  const moveToPage = (index: number) => {
    switch (index) {
      case 0:
        navigate('/');
        moveToPageTop();
        setCommunityType('');
        return;
      case 1:
        navigate('/fasting/timer');
        countingApiInMainPage('fastingOpen');
        moveToPageTop();
        setCommunityType('');
        return;
      case 2:
        setFeedIndexForInfiniteScroll(0);
        setCategory(98);
        // setState가 한타이밍 늦게 반응 전역상태 관리때문일듯함 나중에 리팩토링
        setTimeout(() => {
          navigate('/communication/anonymity/98');
          moveToPageTop();
        }, 100);
        return;
      case 3:
        navigate('/battle/group');
        moveToPageTop();
        setCommunityType('');
        return;
      case 4:
        navigate('/myPage');
        setCommunityType('');
        moveToPageTop();
        return;
      default:
        navigate('/');
        setCommunityType('');
        moveToPageTop();
        return;
    }
  };

  const handleActiveIcon = () => {
    if (index === 3 && newsState.battle) {
      return <BattleNewsIcon />;
    }

    if (index === 4 && newsState.myProfile) {
      return <MyActiveNewsIcon />;
    }

    return currentMenu.selectedIcon;
  };

  const handleIcon = () => {
    if (index === 3 && newsState.battle) {
      return <BattleNewsIcon />;
    }

    if (index === 4 && newsState.myProfile) {
      return <MyNewsIcon />;
    }

    return currentMenu.icon;
  };

  useEffect(() => {
    if (isSelected && index !== 0) {
      resetMainIndex();
    }
  }, [isSelected, index]);

  return (
    <>
      {isSelected ? (
        <Grid
          className={classes.menuItem}
          container
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={moveToPageTop}
        >
          {handleActiveIcon()}
          <span
            style={{ color: currentMenu.title === '배틀' ? '#FF4800' : '#000', fontWeight: 700 }}
          >
            {currentMenu.title}
          </span>
        </Grid>
      ) : (
        <Grid
          className={classes.menuItem}
          container
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => moveToPage(index)}
        >
          {handleIcon()}
          <span>{currentMenu.title}</span>
        </Grid>
      )}
    </>
  );
}

const useStyle = makeStyles(() =>
  createStyles({
    menuItem: {
      display: 'flex',
      position: 'relative',
      height: 66,
      '& svg': {
        marginBottom: '-0.5px',
      },
      '& span': {
        // position: 'absolute',
        // width: 60,
        // top: 41,
        // left: 'calc(50% - 30px)',
        fontSize: 10,
        fontWeight: 500,
        color: 'var(--text-grey)',
        textAlign: 'center',
      },
    },
    newsDot: {
      position: 'absolute',
      top: 13,
      right: 23.5,
      width: 6,
      height: 6,
      borderRadius: '50%',
      background: '#FF4800',
      zIndex: 1005,
    },
  }),
);
