import { atom } from 'recoil';

export const myChangeTabIndexState = atom({
  key: '#myChangeTabIndexState',
  default: 0,
});

export const myEatTabIndexState = atom({
  key: '#myEatTabIndexState',
  default: 0,
});

export const myWeight = atom({
  key: '#myWeight',
  default: 0,
});
