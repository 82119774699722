import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';

const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 72px 20px 0px;
  color: ${COLORS.WHITE};
  gap: 20px;

  .title {
    padding-bottom: 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.5px;

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.005em;
    }
  }

  dl {
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.5px;
    color: ${COLORS.PRIMITIVES_KHAKI_500};
  }

  .subText {
    font-family: 'Noto Sans KR';
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.4375px;
    text-align: center;
    color: ${COLORS.PRIMITIVES_KHAKI_500};
  }
`;

const ShoesIllustWrapper = styled.img`
  width: 335px;
  height: 192px;
`;

interface Props {
  device: 'ios' | 'android';
}

const InterlockButton = styled.div<Props>`
  position: relative;

  .msg {
    position: absolute;
    gap: 0;
    left: 215px;
    bottom: 44px;
  }

  dl {
    display: flex;
    align-items: center;
    padding: ${(props) => (props.device === 'ios' ? '14px 67px 14px' : '14px 61px 14px')};
    background-color: ${COLORS.WHITE};
    border-radius: 120px;
  }

  dt {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  dd {
    font-family: 'Noto Sans KR';
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.3333333432674408px;
    color: ${COLORS.BLACK};

    span {
      font-family: 'Campton';
      font-size: 15px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: -0.3333333432674408px;
      text-align: left;
    }
  }
`;

export { Container, InterlockButton, ShoesIllustWrapper };
