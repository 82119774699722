import ArrowDefault from '@assets/images/icon/ArrowDefault';
import { COLORS } from '@styles/constants/_colors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NutrientDetailGraph from '@assets/images/icon/NutrientDetailGraph';
import { NutrientDetailBubble } from '@assets/svgs/bubble';
import { getCookie, setCookie } from '@utils/cookie';
import { useMemo } from 'react';

interface Props {
  sugar: number;
  themeBackgroundColor?: string;
  themeChartColor?: string;
}

const NutrientsDetailButton = ({
  sugar,
  themeBackgroundColor = COLORS.PRIMITIVES_SKY_BLUE_500,
  themeChartColor = COLORS.PRIMITIVES_SKY_BLUE_500,
}: Props) => {
  const navigate = useNavigate();

  const moveToNutrientDetail = () => {
    navigate('/home/mealDetail/daily');
  };

  return (
    <ButtonWrapper $themeColor={themeBackgroundColor}>
      <Sugar>
        <AmountBox>
          당
          <span className="campton">
            <span className="number">{sugar}</span>g
          </span>
        </AmountBox>
      </Sugar>

      <NutrientDetailBox onClick={moveToNutrientDetail}>
        <NutrientDetailGraph background={themeChartColor} />
        <p>영양소 상세</p>
        <ArrowDefault size="medium" weight="bold" />
      </NutrientDetailBox>
    </ButtonWrapper>
  );
};

export default NutrientsDetailButton;

const DefaultNotoSan = styled.p`
  margin-bottom: 2px;

  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.WHITE};
`;

const ButtonWrapper = styled.div<{ $themeColor: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 20px;
  background-color: ${({ $themeColor }) => $themeColor};
  border-radius: 120px;
`;

const Sugar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const AmountBox = styled(DefaultNotoSan)`
  .campton {
    font-family: Campton;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  .number {
    margin: 0 1px 0 4px;
  }
`;

const NutrientDetailBox = styled(DefaultNotoSan)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  > svg {
    margin-top: 1px;
  }
`;

const InfoBubble = styled.div`
  position: absolute;
  top: -32px;
  right: 0;
`;
