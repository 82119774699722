import { atom } from 'recoil';

export const mainPopupOpenState = atom<boolean>({
  key: 'mainPopupOpenState',
  default: false,
});

export const mainPopupTextState = atom<number>({
  key: 'mainPopupTextState',
  default: 0,
});
