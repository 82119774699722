interface Props {
  color: string;
}

export default function RefreshArrow({ color }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.62026 8.51186C1.32616 4.68833 4.28891 1.6001 7.94373 1.6001C9.78466 1.6001 11.7673 2.33539 12.9438 3.85913"
        stroke={color}
        strokeWidth="1.47059"
        strokeLinecap="round"
      />
      <path
        d="M11.0801 4.68604L13.706 4.65741L13.6774 2.03145"
        stroke={color}
        strokeWidth="1.17647"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6074 7.60038C14.6074 11.4534 11.9141 14.3945 8.09059 14.3945C6.24966 14.3945 4.48349 13.4663 3.28389 12.2531"
        stroke={color}
        strokeWidth="1.47059"
        strokeLinecap="round"
      />
      <path
        d="M5.03516 11.3252L2.42051 11.5676L2.66288 14.1822"
        stroke={color}
        strokeWidth="1.17647"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
