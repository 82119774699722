interface Props {
  size: 'large' | 'semiLarge' | 'medium' | 'mediumBold' | 'small' | 'smallBold';
  color: string;
}

export default function XDefault({ size, color }: Props) {
  const fragment = (() => {
    switch (size) {
      case 'large':
        return {
          icon: (
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M26 26L10 10" stroke={color} strokeWidth="1.8" strokeLinecap="round" />
              <path d="M26 10L10 26" stroke={color} strokeWidth="1.8" strokeLinecap="round" />
            </svg>
          ),
        };
      case 'semiLarge':
        return {
          icon: (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 5L15 19" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
              <path d="M1 19L15 5.00002" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
            </svg>
          ),
        };
      case 'medium':
        return {
          icon: (
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 2L13 13" stroke={color} strokeWidth="1.4" strokeLinecap="round" />
              <path d="M2 13L13 2" stroke={color} strokeWidth="1.4" strokeLinecap="round" />
            </svg>
          ),
        };
      case 'mediumBold':
        return {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path d="M17 17L1 1" stroke={color} strokeWidth="1.8" strokeLinecap="round" />
              <path d="M17 1L1 17" stroke={color} strokeWidth="1.8" strokeLinecap="round" />
            </svg>
          ),
        };
      case 'smallBold':
        return {
          icon: (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.1333 2.13281L13.8666 13.8661"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M2.1333 13.8661L13.8666 2.13281"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.5 2L10.5 10" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
              <path d="M2.5 10L10.5 2" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}
