import { makeStyles, Grid, createStyles } from '@material-ui/core';
import GreenSmileWithStar from '../../../assets/images/icon/characters/GreenSmileWithStar';
import LightGreenSmile from '../../../assets/images/icon/characters/LightGreenSmile';
import LightGreenSmileWithFire from '../../../assets/images/icon/characters/LightGreenSmileWithFire';
import { fixFloatingPoint } from '../../../modules/calorieCalculator';

interface Props {
  closePopup(): void;
  weight: number;
  weightChange: number;
  status: boolean;
}

export default function WeightResponsePopup({ closePopup, weight, weightChange, status }: Props) {
  const classes = useStyle();

  const typeHandler = (status: boolean, weightChange: number) => {
    if (status) {
      return 'first';
    } else if (weightChange === 0) {
      return 'zero';
    } else if (weightChange > 0) {
      return 'plus';
    } else {
      return 'minus';
    }
  };

  const type = typeHandler(status, weightChange);
  const fragment = (() => {
    switch (type) {
      case 'first':
        return {
          icon: <LightGreenSmileWithFire size="medium" active />,
          textFirst: '',
          textSecond: '첫 체중 입력 성공!',
        };
      case 'plus':
        return {
          icon: <LightGreenSmile size="medium" active />,
          textFirst: '이런...',
          textSecond: '늘었어요',
        };
      case 'zero':
        return {
          icon: <LightGreenSmile size="medium" active />,
          textFirst: '',
          textSecond: '체중 변화가 없어요',
        };
      case 'minus':
        return {
          icon: <GreenSmileWithStar size="medium" active />,
          textFirst: '우와!',
          textSecond: '빠졌어요',
        };
    }
  })();

  return (
    <>
      <div className={classes.wrap} onClick={closePopup} />
      <Grid
        className={classes.modalContent}
        container
        direction={'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid className={classes.character}>{fragment.icon}</Grid>
        <Grid
          container
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          className={classes.contentContainer}
        >
          {type !== 'first' && weightChange !== null && (
            <p className={classes.textSecond}>
              이전 체중 <span>{fixFloatingPoint(weight - weightChange)}kg</span>
            </p>
          )}
          <p className={classes.textFirst}>
            기록 완료!{' '}
            {(type === 'plus' || type === 'minus') && (
              <span style={type === 'plus' ? { color: '#FA5064' } : { color: '#007aff' }}>
                {type === 'plus' && '+'}
                {weightChange && weightChange}kg
              </span>
            )}{' '}
            {fragment.textSecond}
          </p>
        </Grid>

        <Grid
          className={classes.buttonContainer}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <p onClick={closePopup}>확인</p>
        </Grid>
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() =>
  createStyles({
    wrap: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.3)',
      zIndex: 3000,
    },
    modalContent: {
      position: 'fixed',
      zIndex: 3001,
      top: 'calc(50vh - 104px)',
      left: 30,
      width: 'calc(100vw - 60px)',
      height: 190,
      borderRadius: 10,
      background: '#fff',
    },
    character: {
      position: 'absolute',
      left: 'calc(50% - 29.5px)',
      top: -20,
      width: 59,
      height: 59,
    },
    contentContainer: {
      height: 141,
      paddingTop: 35,
    },
    textFirst: {
      fontSize: 17,
      fontWeight: 700,
      lineHeight: '23px',
      letterSpacing: -0.5,
      textAlign: 'center',
      '& span': {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    textSecond: {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: -0.42,
      color: '#999FAB',
      marginBottom: 9,
      '& span': {
        fontSize: 14,
        lineHeight: 1.2,
      },
    },
    buttonContainer: {
      height: 15,
      marginBottom: 30,
      '& p': {
        width: 'calc(50% - 1px)',
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center',
      },
      '& div': {
        width: 1,
        height: 16,
        background: '#d3dbe0',
      },
    },
  }),
);
