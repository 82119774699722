import { makeStyles, Grid, createStyles, Dialog } from '@material-ui/core';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { mainPopupOpenState, mainPopupTextState } from '../../../states/MainPopupState';

export default function MainPopup() {
  const classes = useStyles();
  const popupTextNumber = useRecoilValue(mainPopupTextState);
  const [open, setOpen] = useRecoilState(mainPopupOpenState);
  const confirm = () => {
    setOpen(false);
  };

  const popupText = (() => {
    switch (popupTextNumber) {
      case 0:
        return (
          <p className={classes.desc}>
            식단 상세 정보는
            <br />
            오늘 날짜까지만 볼 수 있어요
          </p>
        );
      case 1:
        return (
          <p className={classes.desc}>
            케톤 측정 결과는
            <br />
            오늘 날짜까지만 입력할 수 있어요
          </p>
        );
      case 2:
        return <p className={classes.desc}>단식 기록을 완료했어요!</p>;
      case 3:
        return <p className={classes.desc}>단식 기록을 취소했어요</p>;
      case 4:
        return <p className={classes.desc}>간식 참기를 완료했어요!</p>;
      case 5:
        return <p className={classes.desc}>간식 참기를 취소했어요</p>;
      default:
        return (
          <p className={classes.desc}>
            식단 상세 정보는
            <br />
            오늘 날짜까지만 볼 수 있어요
          </p>
        );
    }
  })();

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <>
      {open && (
        <Grid className={classes.container}>
          {/* <Grid className={classes.back} onClick={confirm}/> */}
          <Dialog open={open}>
            <Grid
              className={classes.paper}
              container
              direction={'column'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {popupText}
              <p className={classes.confirm} onClick={confirm}>
                확인
              </p>
            </Grid>
          </Dialog>
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      height: '100vh',
      width: '100vw',
      zIndex: 1000,
    },
    back: {
      width: '100vw',
      height: '100vh',
      // background: '#0000006f',
    },
    paper: {
      position: 'fixed',
      top: 'calc(50vh - 95px)',
      left: 'calc(50vw - 157.5px)',
      width: 315,
      background: '#fff',
      borderRadius: 7,
      padding: '62px 0 30px',
      zIndex: 1002,
    },
    desc: {
      width: '100%',
      fontSize: 15,
      lineHeight: 1.33,
      letterSpacing: -0.58,
      textAlign: 'center',
      paddingBottom: 44,
    },
    confirm: {
      fontSize: 15,
      fontWeight: 'bold',
      lineHeight: 0.93,
      letterSpacing: -0.58,
      textAlign: 'center',
    },
  }),
);
