interface Props {
  color?: string;
  background?: string;
}

export default function QuestionIcon({ color = '#C7CACF', background = '#E6E7EA' }: Props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill={background} />
      <path
        d="M9.544 10.592L9.64 9.472C11.528 9.264 12.728 8.16 12.728 6.4C12.728 4.32 11.096 2.944 8.984 2.944C6.744 2.944 5.464 4.24 5.32 6.176H7.432C7.528 5.344 8.184 4.8 9.08 4.8C10.056 4.8 10.696 5.44 10.696 6.416C10.696 7.44 9.896 8.048 8.904 8.048H7.752L7.912 10.592H9.544ZM8.68 14.096C9.384 14.096 9.96 13.568 9.96 12.864C9.96 12.176 9.384 11.648 8.68 11.648C7.992 11.648 7.416 12.176 7.416 12.864C7.416 13.568 7.992 14.096 8.68 14.096Z"
        fill={color}
      />
    </svg>
  );
}
