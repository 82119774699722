import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  active: boolean;
  size: 'medium' | 'small';
}

export default function LightGreenSmileWithFire({ active, size }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'medium':
        return {
          icon: active ? (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27.5" cy="29.3438" r="27.5" fill="#AFEB8C" />
              <path
                d="M21.6714 34.8354C22.3183 38.1037 24.9504 39.8409 27.7073 39.8409C30.7625 39.8409 33.1669 38.1137 33.7572 34.8414"
                stroke="black"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
              <path d="M33 22L38 19" stroke="black" strokeWidth="2.8" strokeLinecap="round" />
              <ellipse cx="18.5137" cy="27.7344" rx="2.75" ry="2.76563" fill="black" />
              <ellipse cx="36.55" cy="27.75" rx="2.75" ry="2.75" fill="black" />
              <path
                d="M58.48 43.2539C56.91 39.1739 51.32 38.9539 52.67 33.0239C52.77 32.5839 52.3 32.2439 51.92 32.4739C48.29 34.6139 45.68 38.9039 47.87 44.5239C48.05 44.9839 47.51 45.4139 47.12 45.1139C45.31 43.7439 45.12 41.7739 45.28 40.3639C45.34 39.8439 44.66 39.5939 44.37 40.0239C43.69 41.0639 43 42.7439 43 45.2739C43.38 50.8739 48.11 52.594 49.81 52.8139C52.24 53.1239 54.87 52.674 56.76 50.944C58.84 49.014 59.6 45.9339 58.48 43.2539Z"
                fill="black"
              />
            </svg>
          ) : (
            <svg
              width="59"
              height="59"
              viewBox="0 0 59 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_596_6855)">
                <circle cx="27.5" cy="29.3438" r="27.5" fill="#E6E7EA" />
                <path
                  d="M21.6714 34.8354C22.3183 38.1037 24.9504 39.8409 27.7073 39.8409C30.7625 39.8409 33.1669 38.1137 33.7572 34.8414"
                  stroke="#999FAB"
                  strokeWidth="2.8"
                  strokeLinecap="round"
                />
                <path d="M33 22L38 19" stroke="#999FAB" strokeWidth="2.8" strokeLinecap="round" />
                <ellipse cx="18.5137" cy="27.7344" rx="2.75" ry="2.76563" fill="#999FAB" />
                <ellipse cx="36.55" cy="27.75" rx="2.75" ry="2.75" fill="#999FAB" />
                <path
                  d="M58.48 43.2539C56.91 39.1739 51.32 38.9539 52.67 33.0239C52.77 32.5839 52.3 32.2439 51.92 32.4739C48.29 34.6139 45.68 38.9039 47.87 44.5239C48.05 44.9839 47.51 45.4139 47.12 45.1139C45.31 43.7439 45.12 41.7739 45.28 40.3639C45.34 39.8439 44.66 39.5939 44.37 40.0239C43.69 41.0639 43 42.7439 43 45.2739C43.38 50.8739 48.11 52.594 49.81 52.8139C52.24 53.1239 54.87 52.674 56.76 50.944C58.84 49.014 59.6 45.9339 58.48 43.2539Z"
                  fill="#999FAB"
                />
              </g>
              <defs>
                <clipPath id="clip0_596_6855">
                  <rect width="59" height="59" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ),
        };
      case 'small':
        return {
          icon: (
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14.9153" cy="15.9153" r="14.9153" fill="#AFEB8C" />
              <path
                d="M11.754 18.8936C12.1049 20.6662 13.5325 21.6084 15.0277 21.6084C16.6848 21.6084 17.9889 20.6716 18.309 18.8968"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <path
                d="M17.8982 11.9323L20.6101 10.3052"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="10.0412" cy="15.0425" rx="1.49153" ry="1.5" fill="black" />
              <ellipse cx="19.8237" cy="15.0511" rx="1.49152" ry="1.49153" fill="black" />
              <g clipPath="url(#clip0_518_6475)">
                <path
                  d="M31.7178 23.4602C30.8663 21.2473 27.8344 21.128 28.5666 17.9117C28.6209 17.6731 28.366 17.4887 28.1599 17.6134C26.1911 18.7741 24.7755 21.1009 25.9633 24.149C26.0609 24.3985 25.768 24.6317 25.5565 24.469C24.5748 23.7259 24.4717 22.6575 24.5585 21.8927C24.5911 21.6107 24.2222 21.4751 24.065 21.7083C23.6961 22.2724 23.3219 23.1836 23.3219 24.5558C23.528 27.5931 26.0934 28.5259 27.0155 28.6453C28.3334 28.8134 29.7599 28.5693 30.785 27.631C31.9131 26.5843 32.3253 24.9137 31.7178 23.4602Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_518_6475">
                  <rect
                    width="8.8"
                    height="11.9429"
                    fill="white"
                    transform="translate(23.2677 17.356)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
      default:
        return {
          icon: (
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14.9153" cy="15.9153" r="14.9153" fill="#AFEB8C" />
              <path
                d="M11.754 18.8936C12.1049 20.6662 13.5325 21.6084 15.0277 21.6084C16.6848 21.6084 17.9889 20.6716 18.309 18.8968"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <path
                d="M17.8982 11.9323L20.6101 10.3052"
                stroke="black"
                strokeWidth="1.51864"
                strokeLinecap="round"
              />
              <ellipse cx="10.0412" cy="15.0425" rx="1.49153" ry="1.5" fill="black" />
              <ellipse cx="19.8237" cy="15.0511" rx="1.49152" ry="1.49153" fill="black" />
              <g clipPath="url(#clip0_518_6475)">
                <path
                  d="M31.7178 23.4602C30.8663 21.2473 27.8344 21.128 28.5666 17.9117C28.6209 17.6731 28.366 17.4887 28.1599 17.6134C26.1911 18.7741 24.7755 21.1009 25.9633 24.149C26.0609 24.3985 25.768 24.6317 25.5565 24.469C24.5748 23.7259 24.4717 22.6575 24.5585 21.8927C24.5911 21.6107 24.2222 21.4751 24.065 21.7083C23.6961 22.2724 23.3219 23.1836 23.3219 24.5558C23.528 27.5931 26.0934 28.5259 27.0155 28.6453C28.3334 28.8134 29.7599 28.5693 30.785 27.631C31.9131 26.5843 32.3253 24.9137 31.7178 23.4602Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_518_6475">
                  <rect
                    width="8.8"
                    height="11.9429"
                    fill="white"
                    transform="translate(23.2677 17.356)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
