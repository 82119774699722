import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import LazyLoad from 'react-lazyload';

import './index.css';
import App from './shared';

import GlobalStyle from './styles';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Provider } from 'react-redux';
import { store } from './redux/config/store';

import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';

// axios.defaults.baseURL = BACKURL;
export const queryClient = new QueryClient();

ReactDOM.render(
  <>
    <StrictMode>
      <BrowserRouter>
        <GlobalStyle />
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <LazyLoad>
                <App />
              </LazyLoad>
              <ReactQueryDevtools position="top-right" />
            </Provider>
          </QueryClientProvider>
        </RecoilRoot>
      </BrowserRouter>
    </StrictMode>
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
