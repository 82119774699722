import { Grid, makeStyles, createStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

interface StyleProps {
  textColor: string;
  backgroundColor: string;
}
interface Props {
  footerInfo: {
    icon: JSX.Element;
    text: string;
    button: {
      text: string;
      onClick: () => void;
      bgColor: string;
      textColor?: string;
    };
  };
}

export default function AreaFooter({ footerInfo }: Props) {
  const classes = useStyles({
    textColor: footerInfo.button.textColor ?? '#fff',
    backgroundColor: footerInfo.button.bgColor,
  });

  return (
    <Grid
      className={classNames([classes.container])}
      container
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Grid className={classes.textContainer} container alignItems={'center'}>
        <p className={classes.text}>
          {footerInfo.icon}
          {footerInfo.text}
        </p>
      </Grid>
      <Grid
        className={classes.button}
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        onClick={footerInfo.button.onClick}
      >
        <p>{footerInfo.button.text}</p>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    container: {
      padding: '6px 20px 0px',
    },
    textContainer: {
      width: 'auto',
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      gap: '9px',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: -0.62,
      color: '#fff',
      paddingTop: 3,
      '& svg': {
        marginBottom: 3,
      },
    },
    button: {
      width: 'auto',
      height: 36,
      padding: '11px 18px 12px',
      borderRadius: 17.5,
      background: ({ backgroundColor }) => backgroundColor,
      color: ({ textColor }) => textColor,
      '& p': {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1,
        letterSpacing: -0.5,
      },
      '& svg': {
        marginBottom: 2,
      },
    },
  }),
);
