import { useQuery } from 'react-query';
import { getMainFirstDisplay } from '@apis/mainApi';
import { useDate } from '@hooks/useDate';
import { MainFirstDisplay } from '@models/main';
import { useMemo } from 'react';
import { calculatePercentage } from '@utils/formatNumber';
import { COLORS } from '@styles/constants/_colors';
import { calculateCalorie, calculateCalories } from '@utils/formatCalroie';
import { CommonChartData } from '@models/chart';

const useMainFirstQuery = () => {
  const { date } = useDate();

  const { data, ...queryState } = useQuery<MainFirstDisplay>(
    ['main1', 'nutrient', date],
    () => getMainFirstDisplay(date),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      suspense: true,
      retry: 3,
    },
  );

  const allNutrientsCapacity = useMemo(() => {
    if (!data) return 1;
    const { day } = data;
    const { fat_day, net_carbohydrate_day, protein_day } = day;

    return calculateCalories(net_carbohydrate_day, protein_day, fat_day);
  }, [data]);

  const nutrientList: CommonChartData[] = useMemo(() => {
    if (!data) return [];
    const { day, goals } = data;
    const { fat_day, net_carbohydrate_day, protein_day, calories_day } = day;
    const { fat_goal, protein_goal, net_carbohydrate_goal } = goals;

    const dayNetCarbohydrateCalorie = calculateCalorie('net_carbohydrate', net_carbohydrate_day);
    const dayProteinCalorie = calculateCalorie('protein', protein_day);
    const dayFatCalorie = calculateCalorie('fat', fat_day);

    const dayNetCarbohydratePercent = calculatePercentage(
      dayNetCarbohydrateCalorie,
      allNutrientsCapacity,
    );

    const dayProteinPercent = calculatePercentage(dayProteinCalorie, allNutrientsCapacity);
    const dayFatPercent = calculatePercentage(dayFatCalorie, allNutrientsCapacity);

    return [
      {
        title: '순탄수',
        value: Math.round(net_carbohydrate_day),
        goal: net_carbohydrate_goal,
        percent: dayNetCarbohydratePercent,
        fill: COLORS.WHITE,
      },
      {
        title: '단백질',
        value: Math.round(protein_day),
        goal: protein_goal,
        percent: dayProteinPercent,
        fill: '#FEFFA0',
      },
      {
        title: '지방',
        value: Math.round(fat_day),
        goal: fat_goal,
        percent: dayFatPercent,
        fill: COLORS.PRIMITIVES_NAVY_400,
      },
    ];
  }, [data]);

  return { data, allNutrientsCapacity, nutrientList, ...queryState };
};

export default useMainFirstQuery;
