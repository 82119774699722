import { getExerciseList } from '@apis/exercise';
import { ExerciseFormType } from '@models/exercise';
import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

interface Props {
  memberIdx: number;
  createdAt: string;
}

const useGetExerciseListQuery = (
  { memberIdx, createdAt }: Props,
  options?: UseQueryOptions<ExerciseFormType[], AxiosError, ExerciseFormType[]>,
) => {
  return useQuery<ExerciseFormType[], AxiosError, ExerciseFormType[]>(
    ['#exerciseList', memberIdx, createdAt],
    () =>
      getExerciseList({
        memberIdx,
        createdAt,
      }),
    {
      ...options,
    },
  );
};

export default useGetExerciseListQuery;
