import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import Portal from '@components/portal';
import LEVEL from '@styles/constants/_levels';
import BottomButton from '@components/BottomButton';
import useStopBodyScroll from '@hooks/useStopBodyScroll';
import HighlightText, { Case } from '@components/HighlightText';

interface Props {
  headerText: string;
  descriptionText: string;
  warningView: ReactNode;
  onClose: () => void;
  noteText?: string;
  textPatterns?: Case[];
}

const NotificationModal = ({
  warningView,
  descriptionText,
  headerText,
  noteText,
  onClose,
  textPatterns,
}: Props) => {
  const displayHeaderText = splitLineBreak(headerText).map((line, idx) => <p key={idx}>{line}</p>);

  const displayDescriptionText = splitLineBreak(descriptionText).map((line, idx) =>
    textPatterns ? (
      <HighlightText key={idx} cases={textPatterns}>
        {line}
      </HighlightText>
    ) : (
      <p key={idx}>{line}</p>
    ),
  );

  function splitLineBreak(text: string) {
    return text.split('\n');
  }

  useStopBodyScroll();

  return (
    <Portal>
      <Wrapper>
        <DimmedLayerUnit onClick={onClose} />
        <ContentField>
          <HeaderUnit>{displayHeaderText}</HeaderUnit>
          {warningView}
          <DescriptionBox>
            <DescriptionTextUnit>{displayDescriptionText}</DescriptionTextUnit>
          </DescriptionBox>
          <BottomButton color="black" onClickEvent={onClose}>
            확인했어요
          </BottomButton>
          {noteText && <NoteTextUnit>{noteText}</NoteTextUnit>}
        </ContentField>
      </Wrapper>
    </Portal>
  );
};

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 428px;
  z-index: ${LEVEL.BACKGROUND_MODAL};
`;

const DimmedLayerUnit = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: ${LEVEL.BACKGROUND_MODAL};
`;

const ContentField = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 25px 32px;
  background-color: ${COLORS.WHITE};
  width: 100%;
  max-width: 315px;
  border-radius: 16px;
  z-index: ${LEVEL.BACKGROUND_MODAL};
`;

const HeaderUnit = styled.div`
  color: ${COLORS.BLACK};
  font-family: Cafe24 Ssurround;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.5px;
`;

const DescriptionBox = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px 12px 15px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
  border-radius: 10px;
  margin-bottom: 32px;
`;

const DescriptionTextUnit = styled.div`
  color: ${COLORS.PRIMITIVES_GRAY_500};
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.5px;
`;

const NoteTextUnit = styled.p`
  color: ${COLORS.PRIMITIVES_GRAY_500};
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.5px;
  margin-top: 10px;
`;

export default NotificationModal;
