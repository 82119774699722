import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import TodayNutrientsQuestionTooltip from './components/molecules/TodayNutrientsQuestionTooltip';
import SpinnerInBox from '@components/SpinnerInBox';
import { Suspense, useEffect, useMemo } from 'react';
import useSummaryPromiseAll from '@pages/summaryView/hooks/useSummaryPromiseAll';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import ThemeChangeButton from '@pages/main/TodayNutrients/components/ThemeChangeButton';
import CharacterTheme from '@pages/main/TodayNutrients/CharacterTheme';
import { HomePath } from '@constants/internalPath';
import { ErrorBoundary } from 'react-error-boundary';
import useConfigQuery from '@hooks/queries/useConfigQuery';
import DashboardTheme from '@pages/main/TodayNutrients/DashboardTheme';

const TodayNutrients = () => {
  const { data: userData } = useUserInfoQuery();
  const { selectThemeColor, selectedTheme } = useConfigQuery();

  const memberIdx = useMemo(() => userData?.member_idx ?? 0, [userData?.member_idx]);
  const { setIsClick } = useSummaryPromiseAll(memberIdx);

  const usageTheme = () => {
    if (selectedTheme === 'skyblue') return <DashboardTheme />;

    return <CharacterTheme />;
  };

  const handleCollectionModalOpen = () => {
    setIsClick(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ErrorBoundary
      fallback={
        <LoadingContainer>
          <SpinnerInBox height="493px" />
        </LoadingContainer>
      }
    >
      <Suspense
        fallback={
          <LoadingContainer>
            <SpinnerInBox height="493px" />
          </LoadingContainer>
        }
      >
        <Wrapper $themeColor={selectThemeColor.color1}>
          <Header>
            <TitleBox>
              <TextUnit>오늘 하루</TextUnit>
              <TodayNutrientsQuestionTooltip themeColor={selectThemeColor.color2} />
            </TitleBox>
            <CollectionButtonUnit
              $themeColor={selectThemeColor.color2}
              onClick={handleCollectionModalOpen}
            >
              모아보기
            </CollectionButtonUnit>
          </Header>
          {usageTheme()}
          <ThemeChangeButton href={HomePath.THEME_SELECT}>테마 변경</ThemeChangeButton>
        </Wrapper>
      </Suspense>
    </ErrorBoundary>
  );
};

export default TodayNutrients;

const Wrapper = styled.div<{ $themeColor: string }>`
  display: flex;
  flex-direction: column;
  padding: 24px 0 26px;
  background-color: ${({ $themeColor }) => $themeColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 22px;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextUnit = styled.p`
  font-family: Noto Sans KR;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 683px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
`;

const CollectionButtonUnit = styled.div<{ $themeColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8.5px 14px 9.5px;
  border-radius: 120px;

  color: ${COLORS.WHITE};
  background-color: ${({ $themeColor }) => $themeColor};

  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.5px;
`;
