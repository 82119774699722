import { createStyles, makeStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface ButtonProps {
  isOn?: boolean;
  onClick(): void;
  fixed?: boolean;
}

export default function ModalBottomButton({
  isOn,
  onClick,
  children,
  fixed = false,
}: PropsWithChildren<ButtonProps>) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      className={classNames([classes.buttonContainer, fixed && classes.fixedButton])}
    >
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        onClick={isOn ? onClick : () => undefined}
        className={classNames([classes.button, isOn && classes.isOn])}
      >
        <p>{children}</p>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      padding: '12px 20px',
    },
    fixedButton: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      maxWidth: 428,
      background: '#fff',
    },
    button: {
      background: '#EFF0F2',
      color: '#999FAB',
      borderRadius: 120,
      height: 54,
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: -0.62,
      textAlign: 'center',
    },
    isOn: {
      background: '#000',
      color: '#fff',
    },
  }),
);
