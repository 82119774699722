import { Container, ThemeButton } from './styles';

import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';

import { getMainFourthDisplay } from 'apis/mainApi';

import SpinnerInBox from 'components/SpinnerInBox';
import { useDate } from 'hooks/useDate';
import { useLayoutEffect, useMemo, useState } from 'react';
import { mainFourthDisplayState } from 'states/MainInfoState';
import { MainFourthDisplay } from 'types/main';
import { COLORS } from '@styles/constants/_colors';

const CONDITION_UNRECORD_SVG =
  'https://dw543otwhx7wx.cloudfront.net/main/main3_condition_unRecord.svg';
const CONDITION_RECORD_SVG = 'https://dw543otwhx7wx.cloudfront.net/main/main3_condition_record.svg';

const ConditionDiary = () => {
  const navigate = useNavigate();
  const { date } = useDate();
  const [isLoading, setIsLoading] = useState(false);
  const [mainFourthDisplay, setMainFourthDisplay] = useRecoilState(mainFourthDisplayState);

  const [isData, setIsData] = useState(false);

  const conditionCount = useMemo(() => {
    return mainFourthDisplay?.results?.bodyCondition?.split('/').length;
  }, [mainFourthDisplay]);

  const moveToBodyConditionPage = () => {
    navigate('/tags/bodyCondition');
  };

  useLayoutEffect(() => {
    (async () => {
      if (date) {
        setIsLoading(true);
        const data = await getMainFourthDisplay(date);
        if (data) {
          setMainFourthDisplay(data);
          if (data?.results?.bodyCondition !== '-') {
            setIsData(true);
          }
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      }
    })();

    return () => {
      setMainFourthDisplay({} as MainFourthDisplay);
    };
  }, [date]);

  if (isLoading) return <SpinnerInBox height={'492px'} />;

  if (isData)
    return (
      <Container>
        <h2>총 {conditionCount}개를 기록했어요</h2>
        <img src={CONDITION_RECORD_SVG} alt="컨디션 기록 이미지" />
        <span className="content">
          {mainFourthDisplay.results.bodyCondition.replaceAll(' / ', ', ')}
        </span>
        <ThemeButton color={COLORS.PRIMITIVES_APRICOT_500}>
          <a onClick={moveToBodyConditionPage}>수정하기</a>
        </ThemeButton>
      </Container>
    );

  return (
    <Container>
      <h2>
        컨디션과 몸 상태는 어떤가요<span className="question">?</span>
      </h2>
      <img src={CONDITION_UNRECORD_SVG} alt="컨디션 미기록 이미지" />
      <span className="noneData">아직 기록이 없어요</span>
      <ThemeButton color={COLORS.PRIMITIVES_APRICOT_500}>
        <a onClick={moveToBodyConditionPage}>기록하기</a>
      </ThemeButton>
    </Container>
  );
};

export default ConditionDiary;
