type Size = 'extraLarge' | 'large' | 'semiMedium' | 'medium' | 'semiSmall' | 'small' | 'extraSmall';

interface DefaultProps {
  color: string;
  weight?: 'bold' | 'normal';
  className?: string;
}

interface Props extends DefaultProps {
  size: Size;
}

export default function CheckDefault({ size, color, weight = 'normal', className = '' }: Props) {
  const component = {
    extraLarge: <ExtraLargeCheckbox color={color} className={className} />,
    large: <LargeCheckbox color={color} weight={weight} className={className} />,
    semiMedium: <SemiMediumCheckbox color={color} weight={weight} className={className} />,
    medium: <MediumCheckbox color={color} weight={weight} className={className} />,
    semiSmall: <SemiSmallCheckbox color={color} weight={weight} className={className} />,
    small: <SmallCheckbox color={color} weight={weight} className={className} />,
    extraSmall: <ExtraSmallCheckbox color={color} weight={weight} className={className} />,
  };
  return component[size];
}

const ExtraLargeCheckbox = ({ className = '', weight = 'normal', color }: DefaultProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      className={className}
    >
      <path
        d="M2.59998 17.5L12.6 27L33.6 8"
        stroke={color}
        strokeWidth="4.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const LargeCheckbox = ({ className = '', weight = 'normal', color }: DefaultProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.30003 14.9221L11.2163 21.953L27.0301 6.2777"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const MediumCheckbox = ({ className = '', weight = 'normal', color }: DefaultProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.19295 12.2665L9.71967 17.175L20.1106 6.87535"
        stroke={color}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SemiMediumCheckbox = ({ className = '', weight = 'normal', color }: DefaultProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22223 10.3143L7.19984 14.9811L17.7778 5.12915"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SemiSmallCheckbox = ({ className = '', weight = 'normal', color }: DefaultProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.98 8.95305L6.72978 13.1716L16.218 3.76642"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SmallCheckbox = ({ className = '', weight = 'normal', color }: DefaultProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.76001 7.95837L5.98204 11.7082L14.416 3.34804"
        stroke={color}
        strokeWidth={weight === 'bold' ? 2 : 1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ExtraSmallCheckbox = ({ className = '', weight = 'normal', color }: DefaultProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.53972 6.96363L5.23399 10.2447L12.6137 2.92959"
        stroke={color}
        strokeWidth={weight === 'bold' ? 2 : 1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
