import { Grid, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  direction: 'left' | 'right';
  color: string;
  onClick?(): void;
}

export default function ArrowDate({ direction, color, onClick = () => undefined }: Props) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.wrapper}
      style={direction === 'right' ? { paddingLeft: 20 } : { paddingRight: 20 }}
      onClick={onClick}
    >
      {direction === 'right' ? (
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 1.92871L8.57107 8.99978L1.5 16.0708"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 16.0713L1.42893 9.00022L8.5 1.92915"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: 30,
      height: 18,
    },
  }),
);
// color guide-line : #000 / #D0D2D6 / #FFF / #ABB5E3 / #A7D9DF
