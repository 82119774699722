import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { countingApiInMainPage } from '../../../apis/analyticsApi';
import DropDownDefault from '../../../assets/images/icon/DropDownDefault';
import DateNavigationDefaultWrapper from '../../../components/DateNavigationDefaultWrapper';
import { setChangeDate, timeReverseCalculator, translateTextDate } from '../../../modules/date';
import { dateState } from '../../../states/DateState';
import { CalenderIcon } from '@assets/svgs/_Icons';
import { selectDateState } from '@pages/calenderDiary/states';
import { StickerDiaryBubble } from '@assets/svgs/bubble';
import { getCookie, setCookie } from '@utils/cookie';

interface Props {
  scrollDirection?: null | 'down' | 'up';
}

export default function MainDateNavigation({ scrollDirection }: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const isBubbleClicked = getCookie('isDiaryBubble');

  const [date, setDate] = useRecoilState(dateState);
  const setSelectedDate = useSetRecoilState(selectDateState);

  const moveToCalendar = () => {
    countingApiInMainPage('mainCalendar');
    setCookie('isDiaryBubble', 'true');
  };

  const dateHandler = (() => {
    const translatedDate = translateTextDate(date, 'M.D (ddd)');
    const today = translateTextDate('', 'M.D (ddd)');
    const _today = translateTextDate('', 'YYYY-MM-DD');
    const subtractDayValue = timeReverseCalculator(date, _today).subtractedDay;

    if (translatedDate === today) {
      return '오늘';
    } else if (subtractDayValue === 1) {
      return '어제';
    } else if (subtractDayValue === -1) {
      return '내일';
    } else {
      return translateTextDate(date, 'M.D (ddd)');
    }
  })();

  const moveToBeforeDate = () => {
    setDate(setChangeDate(date, 'day', -1));
    setSelectedDate((prev) => prev.add(-1, 'day'));
  };
  const moveToNextDate = () => {
    setDate(setChangeDate(date, 'day', 1));
    setSelectedDate((prev) => prev.add(1, 'day'));
  };

  const bubble = useMemo(() => {
    if (scrollDirection !== 'down' && isBubbleClicked !== 'true') {
      return <StickerDiaryBubble className={classes.bubble} />;
    }
    return <></>;
  }, [scrollDirection, isBubbleClicked]);

  return (
    <DateNavigationDefaultWrapper leftClick={moveToBeforeDate} rightClick={moveToNextDate}>
      <Grid
        className={classes.textWrapper}
        container
        justifyContent={'center'}
        alignItems={'center'}
        onClick={moveToCalendar}
      >
        {bubble}
        <div
          className={classes.title}
          onClick={() => {
            navigate('/calenderDiary');
          }}
        >
          <div className="calenderIcon">
            <CalenderIcon />
          </div>
          <p className={classes.text}>{dateHandler}</p>
          <DropDownDefault />
        </div>
      </Grid>
      {/* 
      <Grid className={classes.bubble}>
        <StickerDiaryBubble />
      </Grid> */}
    </DateNavigationDefaultWrapper>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    textWrapper: {
      position: 'relative',
      width: 125,
    },
    bubble: {
      position: 'absolute',
      top: '-48px',
      left: '105px',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      '& .calenderIcon': {
        marginTop: '5px',
      },
    },
    text: {
      padding: '0px 8px 2px 6px',
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: -0.5,
      wordSpacing: '-1px',
    },
    // bubble: {
    //   position: 'absolute',
    // },
  }),
);
