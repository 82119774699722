import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';

interface Props {
  pinColor: string;
  title: string;
  percent: number;
}

const NutrientPercent = ({ pinColor, title, percent = 0 }: Props) => {
  return (
    <Wrapper>
      <InfoBox>
        <PinColorUnit $color={pinColor} />
        <TitleUnit>{title}</TitleUnit>
      </InfoBox>
      <PercentBox>
        <div>{percent}</div>
        <span>%</span>
      </PercentBox>
    </Wrapper>
  );
};

export default NutrientPercent;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PinColorUnit = styled.div<{ $color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;

const TitleUnit = styled.div`
  color: ${COLORS.WHITE};
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 700;
  word-break: keep-all;
  line-height: 120%;
  letter-spacing: -0.462px;
`;

const PercentBox = styled.div`
  display: flex;
  align-items: flex-end;
  text-align: end;
  color: ${COLORS.WHITE};
  gap: 2px;

  > div {
    font-size: 20px;
    font-weight: 600;

    letter-spacing: -0.5px;
  }

  > span {
    font-family: Campton;
    font-size: 12px;
    font-weight: 900;
    margin-bottom: 2px;
  }
`;
