import { NavContainer } from 'pages/communication/components/PostContainer/styles';
import { useEffect } from 'react';
import { cx } from 'styles';
import Navigation from '../../components/Navigation';
import { Container } from '../../components/elements/Wrapper/Container';
import MainTabHeader from './components/MainTabHeader';

interface Props {
  scrollDirection?: null | 'down' | 'up';
  headerContents: React.ReactNode;
  children: React.ReactNode;
}

export default function MainLayout({ scrollDirection, headerContents, children }: Props) {
  // const [staticPeriodIndex, setStaticPeriodIndex] = useRecoilState(staticPeriodIndexState);

  useEffect(() => {
    const yCoordinate = sessionStorage.getItem('yCoordinate');

    if (yCoordinate) {
      window.scrollTo(0, Number(yCoordinate));
      sessionStorage.removeItem('yCoordinate');
    }
  }, []);

  return (
    <Container>
      <NavContainer className={cx(scrollDirection)}>
        <div>
          <MainTabHeader />
        </div>
        <div className="categoryContainer">{headerContents}</div>
      </NavContainer>
      {children}
      <Navigation />
    </Container>
  );
}
