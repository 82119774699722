export default function Fire() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_335_13021)">
        <path
          d="M15.5801 12.2541C14.0101 8.17407 8.4201 7.95407 9.7701 2.02407C9.8701 1.58407 9.4001 1.24407 9.0201 1.47407C5.3901 3.61407 2.7801 7.90407 4.9701 13.5241C5.1501 13.9841 4.6101 14.4141 4.2201 14.1141C2.4101 12.7441 2.2201 10.7741 2.3801 9.36407C2.4401 8.84407 1.7601 8.59407 1.4701 9.02407C0.790098 10.0641 0.100098 11.7441 0.100098 14.2741C0.480098 19.8741 5.2101 21.5941 6.9101 21.8141C9.3401 22.1241 11.9701 21.6741 13.8601 19.9441C15.9401 18.0141 16.7001 14.9341 15.5801 12.2541Z"
          fill="#FF4800"
        />
      </g>
      <defs>
        <clipPath id="clip0_335_13021">
          <rect width="16.225" height="22.0196" fill="white" transform="translate(0 1)" />
        </clipPath>
      </defs>
    </svg>
  );
}
