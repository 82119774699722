import useDeleteSubmitWeightMutation from '@pages/main/hooks/useDeleteSubmitWeightMutation';
import useGetMainWeightQuery from '@pages/main/hooks/useGetMainWeightQuery';
import usePostSubmitWeightMutation from '@pages/main/hooks/usePostSubmitWeightMutation';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDate } from './useDate';
import useAdBrix from './adBrix/useAdBrix';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { WidgetKind } from '@constants/widget';

export function useWeightChange() {
  const { sendAdBrixData } = useAdBrix();
  const [ref, inView] = useInView({ triggerOnce: true });
  const { date } = useDate();

  const { data, isLoading } = useGetMainWeightQuery(date, {
    enabled: inView,
    refetchOnWindowFocus: false,
  });
  const { mutateAsync: PostSubmitWeightMutateAsync } = usePostSubmitWeightMutation();
  const { mutateAsync: deleteSubmitMutateAsync } = useDeleteSubmitWeightMutation();

  const [weightState, setWeightState] = useState({
    weight: 0,
    weightChange: 0,
    weightGoal: 0,
    status: true,
  });

  const onSubmitDelete = async () => {
    const data = await deleteSubmitMutateAsync(date);
    if (data.status) {
      setWeightState((prev) => ({
        weight: 0,
        weightChange: 0,
        weightGoal: prev.weightGoal,
        status: true,
      }));
      sendReactNativeMessage({
        type: 'widgetReloadTimelines',
        payload: {
          widgetKind: WidgetKind.DAILY_HEALTH_LOG,
        },
      });
    }
  };

  const onSubmitWeight = async (value: number) => {
    const data = await PostSubmitWeightMutateAsync({ weight: value, created_at: date });
    if (data.status) {
      sendAdBrixData('customEvent', {
        eventKey: 'register_weight_record',
      });
      setWeightState((prev) => ({
        weight: value,
        weightChange: data.weightChange,
        weightGoal: prev.weightGoal,
        status: data.msg !== 'none',
      }));

      sendReactNativeMessage({
        type: 'widgetReloadTimelines',
        payload: {
          widgetKind: WidgetKind.DAILY_HEALTH_LOG,
        },
      });

      return {
        weightChange: data.weightChange,
        status: data.msg !== 'none',
      };
    }
    return {
      weightChange: 0,
      status: data.msg !== 'none',
    };
  };

  useEffect(() => {
    if (data) {
      setWeightState((prev) => ({
        ...prev,
        ...data.data.weightData,
      }));
    }
  }, [data]);

  return {
    isLoading,
    onSubmitWeight,
    weightState,
    onSubmitDelete,
    ref,
  };
}
