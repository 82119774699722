import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil';

import {
  ExerciseFormType,
  ExerciseFreeFormType,
  ExerciseRegistrationRequestFormType,
} from 'types/exercise';

export const exerciseTabAtom = atom<'빠른 추가' | '즐겨찾기'>({
  key: '#exerciseTabAtom',
  default: '빠른 추가',
});

export const exerciseSearchKeywordAtom = atom<string>({
  key: '#exerciseSearchKeywordAtom',
  default: '',
});

/**
 * @remark 운동 기록으로 보낼 폼 입니다.
 *
 */
export const exerciseFormItemAtomFamily = atomFamily<ExerciseFormType, number>({
  key: '#exerciseFormItemAtomFamily',
  default: (idx) => ({
    exerciseInfoIdx: idx,
    mainCategory: '',
    exerciseTitle: '',
    exerciseTime: 0,
    exerciseLevels: 0,
    exerciseCalories: 0,
    coefficient: 0,
    exerciseDetail: {
      count: [],
      weight: [],
      distance: [],
    },
    exerciseRegIdx: 0,
  }),
});

/**
 * @remark exerciseFormItemAtomFaily의 idx 들이 들어갈 배열입니다.
 */
export const exerciseFormListIdsAtom = atom<ExerciseFormType['exerciseInfoIdx'][]>({
  key: '#exerciseFormListIdsAtom',
  default: [],
});

/**
 * @get : get에서 들어온 idx에 해당하는 exerciseFormItemAtomFaily 배열안의 객체를 찾아 리턴합니다.
 * @set : 첫번째 if 문은 Reset에 사용되는 로직, 리턴 그밑에는 (1) get 에서 찾은 객체를 변경시키고
 * (2) 에서 배열에 해당 idx 를 넣어줍니다.
 */
export const exerciseFormState = selectorFamily<ExerciseFormType, number>({
  key: '#exerciseFormState',
  get:
    (idx) =>
    ({ get }) =>
      get(exerciseFormItemAtomFamily(idx)),
  set:
    (idx) =>
    ({ get, set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(exerciseFormItemAtomFamily(idx));
        set(exerciseFormListIdsAtom, (prevValue) =>
          prevValue.filter((exerciseInfoIdx) => exerciseInfoIdx !== idx),
        );
        return;
      }

      set(exerciseFormItemAtomFamily(idx), newValue); // (1)
      // (2)
      set(exerciseFormListIdsAtom, (prev) => {
        if (prev.includes(newValue.exerciseInfoIdx)) {
          return [...prev];
        }
        return [...prev, newValue.exerciseInfoIdx];
      });
    },
});

/**
 * @remark 자유입력 폼 입니다.
 *
 */
export const exerciseFreeFormItemAtom = atom<ExerciseFreeFormType>({
  key: '#exerciseFreeFormItemAtom',
  default: {
    exerciseInfoIdx: 0,
    mainCategory: '자유 입력',
    exerciseTitle: '',
    exerciseTime: 0,
    exerciseLevels: 1,
    exerciseCalories: '',
    coefficient: null,
    exerciseDetail: {
      count: [],
      weight: [],
      distance: [],
    },
    exerciseRegIdx: 0,
  },
});

/**
 * @remark id 배열로 모든 운동 기록들 가져오기
 */
export const exerciseFormListState = selector<ExerciseFormType[]>({
  key: '#exerciseFormListState',
  get: ({ get }) => {
    const ids = get(exerciseFormListIdsAtom);
    return ids.map((id) => get(exerciseFormItemAtomFamily(id)));
  },
});

/**
 * @remark 장바구니에 들어갈 운동 기록 리스트
 */
export const exerciseBasketListAtom = atom<(ExerciseFormType | ExerciseFreeFormType)[]>({
  key: '#exerciseBasketListAtom',
  default: [],
});

/**
 * @remark 장바구니에 들어갈 운동 기록 리스트 selector
 *
 */
export const exerciseBasketListState = selector({
  key: '#exerciseBasketListState',
  get: ({ get }) => {
    const exerciseBasketList = get(exerciseBasketListAtom);
    return exerciseBasketList;
  },
  set: ({ get, set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(exerciseBasketListAtom);
      return;
    }

    set(exerciseBasketListAtom, newValue);
  },
});

export const exerciseRegistrationRequestFormAtom = atom<ExerciseRegistrationRequestFormType>({
  key: '#exerciseRegistrationRequestFormAtom',
  default: {
    exercise_data: {
      main_category: '',
      exercise_title: '',
    },
    created_at: '',
  },
});

export const exerciseThemeIndex = atom({
  key: '#exerciseThemeIndex',
  default: 0,
});
