import styled from 'styled-components';
import { FOUNDATION_COLOR } from 'styles/constants/_colors';
import LEVEL from 'styles/constants/_levels';

export const BoarderContainer = styled.article<{ categoryIndex: number }>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.categoryIndex === 98 ? '120px 0 0px' : '132px 20px 40px')};
  gap: 12px;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const InquireBoardContainer = styled.article`
  display: flex;
  flex-direction: column;
  padding: 114px 20px 40px;
  gap: 12px;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const ContentContainer = styled.article`
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const NavContainer = styled.header`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 428px;
  z-index: ${LEVEL.COMMUNICATIONS_HEADER};

  &.up {
    transform: translateY(0px);
    transition-duration: 300ms;
  }
  &.down {
    transform: translateY(-68px);
    transition-duration: 300ms;
  }

  .categoryContainer {
    width: 100%;
    max-width: 428px;
    z-index: ${LEVEL.COMMUNICATIONS_SUB_HEADER};
    background-color: ${FOUNDATION_COLOR.BASIC_WHITE};
  }
`;
