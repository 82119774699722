import { uniqueId } from 'lodash';
import { ComponentProps, FunctionComponent, ReactNode } from 'react';
import { atom } from 'recoil';

/**
 * modal을 최상단에서 하나만 쓰기 위해 useSplashModal Hook을 만들었지만
 * 어느 이유에서 인지 security modal을 호출했을 때는 두 번씩 렌더링 되는 이슈가 있어
 * security modal만 위로 빼기로 했음
 */
export const securityModalState = atom<boolean>({
  key: `#securityModalState`,
  default: false,
});

export const splashModalState = atom<
  Array<{ Component: FunctionComponent<any>; props: ComponentProps<FunctionComponent<any>> }>
>({
  key: `#splashModalState/${uniqueId('splash-modal')}`,
  default: [],
});

export const topModalState = atom<
  Array<{
    Component: FunctionComponent<any>;
    props: ComponentProps<FunctionComponent<any>>;
  }>
>({
  key: `#topModalState/${uniqueId('top-modal')}`,
  default: [],
});

export const timeoutModalState = atom<{
  isPopup: boolean;
  props: ComponentProps<FunctionComponent<{ children: ReactNode; millisecond: number }>>;
}>({
  key: `#timeoutModalState/${uniqueId('timeout-modal')}`,
  default: {
    isPopup: false,
    props: {
      children: null,
      millisecond: 2000,
    },
  },
});

export const toastModalState = atom<{
  isPopup: boolean;
  props: ComponentProps<FunctionComponent<{ children: ReactNode }>>;
}>({
  key: '#toastModalState',
  default: {
    isPopup: false,
    props: {
      children: null,
    },
  },
});
