import React, { PropsWithChildren, useState } from 'react';
import { ClickAwayListener, Grid, Theme, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import QuestionIcon from '../../assets/images/icon/QuestionIcon';

interface StyleProps {
  place?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  backgroundColor?: string;
  color?: string;
  rightPadding?: number;
  topPadding?: number;
  marginRight?: number;
}

interface Props extends StyleProps {
  message: NonNullable<React.ReactNode>;
}
export function QuestionTooltip({
  message,
  backgroundColor,
  color,
  rightPadding,
  topPadding = 5,
  marginRight,
  place,
}: PropsWithChildren<Props>) {
  const classes = useStyles({ backgroundColor, color, rightPadding, marginRight, topPadding });
  const [opened, setOpened] = useState(false);
  const handleToggle = () => setOpened(!opened);
  const handleClickAway = () => {
    setOpened(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip
        title={message}
        placement={place === undefined ? 'bottom' : place}
        open={opened}
        classes={{ popper: classes.popper, arrow: classes.arrow, tooltip: classes.tooltip }}
      >
        <Grid className={classes.container} onClick={handleToggle}>
          <QuestionIcon color={color} background={backgroundColor} />
        </Grid>
      </Tooltip>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    container: {
      marginRight: ({ marginRight }) => (marginRight ? marginRight : 0),
      position: 'relative',
      paddingRight: ({ rightPadding }) => (rightPadding ? rightPadding : 0),
      cursor: 'pointer',
      zIndex: 10,
      paddingTop: ({ topPadding }) => topPadding,
      '& svg': {
        marginLeft: 6,
      },
    },
    popper: {
      zIndex: 1600,
    },
    arrow: {
      color: '#E8EFFC',
    },
    tooltip: {
      fontFamily: 'Campton, Noto Sans KR',
      padding: '16px',
      backgroundColor: '#fff',
      color: '#646973',
      maxWidth: 324,
      fontSize: 12,
      borderRadius: 5,
      boxShadow: '2px 2px 14px 0 rgba(0, 0, 0, 0.15)',
      margin: '0 15px 8px 0',
      '& strong': {
        color: 'var(--text-black)',
      },
      '& .title': {
        marginBottom: 18,
        fontSize: 13,
        letterSpacing: -0.5,
      },
      '& .desc': {
        lineHeight: 1.5,
        letterSpacing: -0.46,
        marginTop: 6,
        marginBottom: 15,
      },
      '& .noMarginDesc': {
        lineHeight: 1.5,
        letterSpacing: -0.46,
        marginBottom: 15,
      },
      '& .lastDesc': {
        lineHeight: 1.5,
        letterSpacing: -0.46,
        marginTop: 6,
      },
      '& .noneDesc': {
        lineHeight: 1.5,
        letterSpacing: -0.46,
        marginTop: 6,
      },
    },
  }),
);
