import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '@styles/constants/_colors';

type ColorType = 'blue' | 'black' | 'disabled' | 'gray' | 'white' | 'red';

interface Props {
  children: React.ReactNode;
  color?: ColorType;
  isDisabled?: boolean;
  onClickEvent: () => void | undefined | Promise<void>;
  icon?: React.ReactElement;
}

const BottomButton = ({ children, onClickEvent, color, isDisabled = false, icon }: Props) => {
  return (
    <Button
      onClick={onClickEvent}
      $color={isDisabled ? 'disabled' : color ?? 'blue'}
      disabled={isDisabled}
    >
      {icon}
      {children}
    </Button>
  );
};

export default BottomButton;

const ButtonColorStyle: Record<ColorType, ReturnType<typeof css>> = {
  blue: css`
    background-color: ${COLORS.PRIMITIVES_BLUE_400};
    color: ${COLORS.WHITE};
  `,
  black: css`
    background-color: ${COLORS.BLACK};
    color: ${COLORS.WHITE};
  `,
  gray: css`
    background-color: ${COLORS.PRIMITIVES_GRAY_70};
    color: ${COLORS.BLACK};
  `,
  disabled: css`
    background-color: ${COLORS.PRIMITIVES_GRAY_70};
    color: ${COLORS.PRIMITIVES_GRAY_500};
  `,
  white: css`
    background-color: ${COLORS.WHITE};
    color: ${COLORS.BLACK};
  `,
  red: css`
    background-color: #ff5647;
    color: ${COLORS.WHITE};
  `,
};

const Button = styled.button<{ $color: ColorType; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  border-radius: 120px;
  min-height: 54px;
  width: 100%;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 15px */
  letter-spacing: -0.615px;
  padding: 18px 0 20px;

  ${({ $color, disabled }) => (disabled ? ButtonColorStyle['disabled'] : ButtonColorStyle[$color])};
`;
