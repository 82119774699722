import { useMediaQuery } from '@material-ui/core';

export const DESKTOP_VIEW_WIDTH = 745;
export default function usePlatform() {
  const isDesktop = useMediaQuery(`(min-width: ${DESKTOP_VIEW_WIDTH}px)`);
  const isMobile = !isDesktop;
  return {
    isDesktop,
    isMobile,
  };
}
