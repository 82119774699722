import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';

interface Props {
  goal: number;
  eat: number;
  usage: number;
  themeColor?: string;
}

const CalorieResult = ({
  goal = 0,
  eat = 0,
  usage = 0,
  themeColor = COLORS.PRIMITIVES_SKY_BLUE_500,
}: Props) => {
  const calculatedCalorie = goal + usage - eat;
  const displayCalorie = Math.round(Math.abs(calculatedCalorie));

  const isExceeded = 0 > calculatedCalorie;

  const displayGoalTitle = isExceeded ? '초과됐어요' : '더 먹을 수 있어요';

  const figureData = [
    {
      text: '내 목표',
      kcal: Math.round(goal),
    },
    {
      text: '섭취량',
      kcal: Math.round(eat),
    },
    {
      text: '소모량',
      kcal: Math.round(usage),
    },
  ];

  return (
    <Wrapper $themeColor={themeColor}>
      <GoalTitleBox $exceeded={isExceeded}>
        <span>“</span>
        <span className="highlight">{displayCalorie} kcal</span>
        <span className="title">{displayGoalTitle}</span>
        <span>”</span>
      </GoalTitleBox>
      <CalorieInfoField>
        {figureData.map(({ text, kcal }, idx) => (
          <InfoBox key={idx}>
            <FigureUnit>{kcal}</FigureUnit>
            <CalorieTitleUnit>{text}</CalorieTitleUnit>
          </InfoBox>
        ))}
      </CalorieInfoField>
    </Wrapper>
  );
};

export default CalorieResult;

const Wrapper = styled.div<{ $themeColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 25px;
  margin-top: 28px;
  border-radius: 12px;
  background-color: ${({ $themeColor }) => $themeColor};
`;

const GoalTitleBox = styled.div<{ $exceeded: boolean }>`
  display: flex;
  color: ${COLORS.WHITE};
  font-size: 15px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  text-align: center;
  gap: 4px;

  .highlight {
    font-family: Campton;
    font-weight: 900;
    letter-spacing: -1px;
    color: ${({ $exceeded }) => ($exceeded ? '#FF5647' : '#55fa90')};
  }

  .title {
    font-family: Noto Sans KR;
    font-size: 14px;
  }
`;

const CalorieInfoField = styled.div`
  display: flex;
  color: ${COLORS.WHITE};
  gap: 20px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 72px;
  gap: 6px;
  justify-content: center;
  align-items: center;
`;

const FigureUnit = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.5px;
`;

const CalorieTitleUnit = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;
`;
