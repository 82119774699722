import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';

const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 82px 20px 52px;
  gap: 21px;
  color: ${COLORS.WHITE};

  .conditionImg {
    width: 100%;
    height: 100%;
  }

  h2 {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
    letter-spacing: -0.5px;

    .question {
      font-size: 18px;
    }
  }

  .content {
    max-width: 255px;
    /* height: 21px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    line-height: 1.2em;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 11px;
    letter-spacing: -0.5px;
  }
  .noneData {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #ef6c3c;
    margin-bottom: 11px;
    letter-spacing: -0.5px;
  }
`;

const ThemeButton = styled.button<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 255px;
  height: 60px;
  border-radius: 120px;
  background-color: ${COLORS.PRIMITIVES_NAVY_500};
  background-color: ${(props) => props.color};

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 6px;
    font-size: 15px;
    font-weight: 700;
    color: ${COLORS.WHITE};
    letter-spacing: -0.5px;
  }
`;

export { Container, ThemeButton };
