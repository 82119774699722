import { COLORS } from '@styles/constants/_colors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getRegisteredList } from '@apis/cartApi';
import { useDate } from '@hooks/useDate';
import { MealInfo } from '@models/main';
import { foodBasketListState, foodBasketPrevListState } from '@states/BasketState';
import { folderSelectedState } from '@states/Folder';
import { foodSearchAdSubscribe } from '@states/adState';
import CDNURLConvert from '@utils/CDNUrlConvert';
import { useMutation } from 'react-query';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import MealTimeIcon from '../../../../../assets/svgs/food/MealTimeIcon';
import FastingOnOffCheck from './FastingOnOffCheck';
import MealRecordCheckIcon from './MealRecordCheckIcon';

interface Props {
  mealInfo: Omit<MealInfo, 'saveTime'>;
}

const DietDiaryCard = ({ mealInfo }: Props) => {
  const navigate = useNavigate();
  const { date } = useDate();

  const setBasket = useSetRecoilState(foodBasketListState);
  const setPrevBasket = useSetRecoilState(foodBasketPrevListState);
  const resetSelectedFolder = useResetRecoilState(folderSelectedState);
  const setFoodSearchAdSubscription = useSetRecoilState(foodSearchAdSubscribe);

  const { mutate } = useMutation(
    (request: { time: number; created_at: string }) => getRegisteredList(request),
    {
      onSuccess: (res) => {
        setPrevBasket(res);
        setBasket([...res.menuInfo]);
      },
    },
  );

  const isMealRecorded = mealInfo.nutrient !== null && !mealInfo.fasting;

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isMealRecorded) return;
    mealInfo.check ? moveToMealBasketPage() : moveToSearchPage();
    setFoodSearchAdSubscribeInfo();
  };

  const handleIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (mealInfo.fasting) return;
    mealInfo.check ? moveToMealBasketPage() : moveToSearchPage();
    setFoodSearchAdSubscribeInfo();
  };

  const moveToSearchPage = () => {
    mutate({
      time: mealInfo.time,
      created_at: date,
    });
    resetSelectedFolder();
    navigate(`/search/main/${mealInfo.time}`);
  };

  const moveToMealBasketPage = () => {
    mutate({
      time: mealInfo.time,
      created_at: date,
    });
    resetSelectedFolder();
    navigate(`/basket/food/edit/${mealInfo.time}/${date}`);
  };

  const setFoodSearchAdSubscribeInfo = () => {
    const key = `${date}-${mealInfo.time}`;
    setFoodSearchAdSubscription((prev) => new Map(prev).set(key, []));
  };

  const CalorieView = () => {
    return (
      <Calorie>
        {mealInfo.nutrient?.calories}
        <span>kcal</span>
      </Calorie>
    );
  };

  return (
    <Card onClick={handleCardClick}>
      <MealRecordCheck onClick={handleIconClick}>
        <IconWrapper>
          {mealInfo.img ? (
            <MealImg src={CDNURLConvert(mealInfo.img)} />
          ) : (
            <MealTimeIcon mealTime={mealInfo.time} />
          )}
        </IconWrapper>
        <MealRecordCheckIcon isMealRecord={mealInfo.check} />
      </MealRecordCheck>

      <MealRecordStatus>
        <TimeName>{mealInfo.time_name}</TimeName>
        {isMealRecorded ? (
          <CalorieView />
        ) : (
          <FastingOnOffCheck isFasting={mealInfo.fasting} mealTime={mealInfo.time} />
        )}
      </MealRecordStatus>
    </Card>
  );
};

export default DietDiaryCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  aspect-ratio: 1 / 1.04;
  max-width: 187px;
  padding: 14px 20px 20px 16px;
  border-radius: 16px;
  background-color: ${COLORS.PRIMITIVES_BLUE_NAVY_450};
`;

const MealRecordCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  width: 52px;
  height: 52px;
`;

const MealImg = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 2px;
`;

const MealRecordStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 4px;
`;

const TimeName = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
`;

const Calorie = styled.p`
  font-family: Campton;
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.55px;
  color: ${COLORS.WHITE};

  span {
    margin-left: 1px;
  }
`;
