import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePath } from '@constants/internalPath';

import FallBackSpinner from '@components/spinner/FallBackSpinner';

import { MainThemeListPage } from '@pages/main/mainTheme';
import Main from '@pages/main/Main';

const Statistics = lazy(() => import('@pages/statistics'));
const BodyCondition = lazy(() => import('@pages/main/BodyCondition'));
const Supplements = lazy(() => import('@pages/main/Supplements'));
const WaterDiaryDetail = lazy(() => import('@pages/main/components/WaterDiaryDetail'));
const DietDetailRenewal = lazy(() => import('@pages/ketosis/records/components/DietDetailRenewal'));

export default function MainRouter() {
  return (
    <Suspense fallback={<FallBackSpinner />}>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/home/mealDetail/daily" element={<DietDetailRenewal />} />
        <Route path="/tags/bodyCondition" element={<BodyCondition />} />
        <Route path="/tags/water" element={<WaterDiaryDetail />} />
        <Route path="/tags/supplements" element={<Supplements />} />
        <Route path={HomePath.THEME_SELECT} element={<MainThemeListPage />} />
      </Routes>
    </Suspense>
  );
}
