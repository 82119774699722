import { BookmarkFolder } from '@models/searchResult';
import { atom } from 'recoil';

export const folderBasketState = atom<BookmarkFolder[]>({
  key: `#folderBasketState`,
  default: [],
});

export const folderSelectedState = atom<BookmarkFolder>({
  key: '#folderSelectedState',
  default: {
    food_bookmark_folder_idx: 0,
    emoji: '',
    member_idx: 0,
    name: '',
    created_at: '',
    updated_at: '',
  },
});

export const folderSelectedItemState = atom<BookmarkFolder>({
  key: `#folderSelectedItemState`,
  default: {
    food_bookmark_folder_idx: 0,
    emoji: '',
    member_idx: 0,
    name: '',
    created_at: '',
    updated_at: '',
  },
});

export const folderCheckboxMapState = atom<Map<string, string>>({
  key: `#folderCheckboxMapState`,
  default: new Map<string, string>(),
});
