import { getMainSecondDisplay } from '@apis/mainApi';
import SpinnerInBox from '@components/SpinnerInBox';
import { useDate } from '@hooks/useDate';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import DietDiaryCard from './components/DietDiaryCard';
import { useMemo } from 'react';

const DietDiary = () => {
  const { date } = useDate();
  const { ref, inView } = useInView({ triggerOnce: true });

  const { data, isLoading } = useQuery(
    ['main2', 'mealInfo', date],
    () => getMainSecondDisplay(date),
    {
      enabled: inView,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const card = useMemo(
    () =>
      data?.results.menu.map((item) => (
        <DietDiaryCard mealInfo={item} key={`${item.time}-${item.time_name}`} />
      )),
    [data],
  );

  if (isLoading || !data) {
    return (
      <Container ref={ref}>
        <SpinnerInBox height={'567px'} />
      </Container>
    );
  }

  return (
    <Container ref={ref}>
      <CardWrapper>{card}</CardWrapper>
    </Container>
  );
};

export default DietDiary;

const Container = styled.div`
  padding: 0 20px;
  margin: 60px auto 51px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 13px;
`;
