import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getNavigationNews } from '../apis/communityApi';
import Menu from '../assets/images/Navigation/Menu';
import { deviceOs, safeArea } from '../modules/platformCheck';
import { useLocation } from 'react-router-dom';

export default function Navigation() {
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [news, setNews] = useState({
    myProfile: false,
    battle: false,
  });

  const iterator = Array.from({ length: 5 }, () => 0);
  const selectedIndex = (() => {
    switch (path) {
      case '':
        return 0;
      case 'fasting':
        return 1;
      case 'communication':
        return 2;
      case 'battle':
        return 3;
      case 'myPage':
        return 4;
    }
  })();

  useEffect(() => {
    (async () => {
      const data = await getNavigationNews();
      setNews(data);
    })();
  }, [selectedIndex]);

  return (
    <MainBottomGnbContainer className={classNames([deviceOs === 'ios' && safeArea().block])}>
      {iterator.map((_, index) => (
        <Menu key={index} index={index} isSelected={index === selectedIndex} newsState={news} />
      ))}
    </MainBottomGnbContainer>
  );
}

const MainBottomGnbContainer = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  max-width: 428px;
  padding: 0px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1001;
  padding: 0px 16px;
`;
