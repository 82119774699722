import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';

const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 68px 20px 0px;
  gap: 21px;
  color: ${COLORS.WHITE};

  h2 {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
    letter-spacing: -0.5px;
    line-height: 120%;

    .question {
      font-size: 18px;
    }
  }

  .content {
    max-width: 255px;
    /* height: 21px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    line-height: 1.2em;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 13px;
    letter-spacing: -0.5px;
  }
  .noneData {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: ${COLORS.PRIMITIVES_BLUE_NAVY_450};
    margin-bottom: 13px;
    letter-spacing: -0.5px;
  }
`;

const SupplementIllust = styled.img`
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
`;

const ThemeButton = styled.button<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 255px;
  height: 60px;
  border-radius: 120px;
  background-color: ${(props) => props.color};
  margin-bottom: 55px;
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 6px;
    font-size: 15px;
    font-weight: 700;
    color: ${COLORS.WHITE};
    letter-spacing: -0.5px;
  }
`;

export { Container, ThemeButton, SupplementIllust };
