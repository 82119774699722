import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';

interface IProgressBarProps {
  percent: number;
}

const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 0px;
  color: ${COLORS.WHITE};
  gap: 6px;

  .title {
    font-size: 16px;
    font-weight: 700;
  }

  .img {
    width: 100%;
    height: 100%;
    padding: 6px 0px;
  }

  .content {
    font-weight: 600;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: -0.5px;
    padding-bottom: 6px;
  }

  .time {
    display: flex;
    color: ${COLORS.PRIMITIVES_KHAKI_500};
    padding-bottom: 22px;

    p {
      font-weight: 700;
      font-size: 13px;
      line-height: 15.6px;
      letter-spacing: -0.62px;
      margin-top: 1px;
    }

    .timer {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.5px;
      padding: 0px 2px 0px 3px;
    }

    .refresh {
      padding-left: 4px;
    }
  }

  .bottom {
    padding: 16px 20px 5px;
    width: 100%;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 17px;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.5px;

      dd {
        padding-bottom: 1px;
      }

      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 120%;
      }

      .bang {
        font-family: 'Campton';
        font-size: 16px;
      }
    }

    .kcal {
      display: flex;
      padding-top: 20px;
      justify-content: center;
      align-items: flex-end;

      span {
        padding-right: 8.75px;
      }

      dt {
        display: flex;
        padding-right: 12px;
        padding-bottom: 3px;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.615385px;
      }

      dd {
        padding-bottom: 3px;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.5px;
      }
    }
  }
`;

const Progress = styled.div`
  background-color: ${COLORS.PRIMITIVES_KHAKI_450};
  height: 8px;
  border-radius: 120px;
`;

const ProgressBar = styled.div<IProgressBarProps>`
  width: ${(props) => props.percent}%;
  height: 8px;
  background-color: ${(props) => (props.percent === 100 ? '#50DC82' : COLORS.WHITE)};
  border-radius: 120px;
  transition: 1s ease;
  transition-delay: 0.3s;
`;

export { Container, Progress, ProgressBar };
