import { Wrapper } from './styles';
import { cx } from 'styles';

import { PropsWithChildren } from 'react';

interface Props {
  text: string;
  padding?: boolean;
  isNew?: boolean;
}

export default function TitleWrapper({ text, padding, isNew, children }: PropsWithChildren<Props>) {
  return (
    <Wrapper>
      <div className={cx('title', ['padding', padding])}>
        {text}
        {isNew && <p className="newText">New !</p>}
      </div>
      {children}
    </Wrapper>
  );
}
