import { useEffect } from 'react';

import { exerciseThemeIndex } from 'pages/exercise/states';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { dateState } from 'states/DateState';

import ThemeContainer from 'components/Molecules/ThemeConTainer';
import ExerciseDiary from 'pages/exercise/ExerciseDiary';
import WalkingDetail from './walking/walkingDetail';
import { deepLinkEventDataState } from '@states/DeepLinkState';

const Exercise = () => {
  const [tabIndex, setTabIndex] = useRecoilState(exerciseThemeIndex);
  const [date] = useRecoilState(dateState);
  const deepLinkEventData = useRecoilValue(deepLinkEventDataState);
  const resetDeepLinkEventData = useResetRecoilState(deepLinkEventDataState);

  const tabList = [
    {
      name: '운동',
      id: 'theme_exercise',
    },
    {
      name: '걸음 수',
      id: 'theme_workingCount',
    },
  ];

  const handleDeepLink = () => {
    window.scrollTo(0, 99999);
    setTabIndex(1);
    resetDeepLinkEventData();
  };

  useEffect(() => {
    setTabIndex(0);
  }, [date]);

  useEffect(() => {
    if (deepLinkEventData && deepLinkEventData?.section === 'walk') {
      handleDeepLink();
    }
  }, [deepLinkEventData]);

  return (
    <>
      <ThemeContainer
        mainTitle="나의 활동"
        bgColor="khaki"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        tabList={tabList}
      >
        {tabIndex === 0 && <ExerciseDiary />}
        {tabIndex === 1 && <WalkingDetail />}
      </ThemeContainer>
    </>
  );
};

export default Exercise;
