import { createStyles, Grid, Input, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { ChangeEvent } from 'react';
import usePlatform from '../../../hooks/usePlatform';

interface Props {
  value: number | string;

  setMeasurement: (e: ChangeEvent<any>) => void;
  unit: string;
  placeholder?: string;
}

export default function InputWeight({ value, setMeasurement, unit, placeholder }: Props) {
  const { isDesktop } = usePlatform();
  const classes = useStyle({ isDesktop, value });

  return (
    <>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Input
          value={value}
          onChange={setMeasurement}
          type={'text'}
          inputProps={{ inputMode: 'decimal' }}
          className={classNames([classes.input, 'typing_on'])}
          disableUnderline
          onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
          placeholder={placeholder}
          endAdornment={
            <span className={classNames([classes.unit, value && classes.typingOn])}>{unit}</span>
          }
        />
      </Grid>
    </>
  );
}

interface StyleProps {
  isDesktop: boolean;
  value: string | number;
}

const useStyle = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    input: {
      border: 'none',
      height: 45,
      fontSize: 32,
      fontWeight: 500,
      letterSpacing: '-1.15px',
      textAlign: 'right',
      width: 120,
      marginLeft: ({ value }: StyleProps) => (value.toString().length > 0 ? '-26px' : '-12px'),
      '& .MuiInput-input': {
        fontFamily: 'Campton, Noto Sans KR',
        '&::placeholder': {
          fontFamily: 'Campton, Noto Sans KR',
          letterSpacing: '-1.15px',
          color: 'var(--text-light-grey)',
          opacity: 1,
        },
      },
      '&:focus': {
        outline: 'none',
      },
    },
    unit: {
      marginLeft: 4,
      fontSize: 32,
      letterSpacing: '-1.15px',
      fontWeight: 500,
      color: 'var(--text-light-grey)',
      fontFamily: 'Campton, Noto Sans KR',
    },
    typingOn: {
      color: 'var(--text-black)',
    },
  }),
);
