import { useEffect, useRef, useState } from 'react';

/**
 * 참고1 https://velog.io/@y_jem/react-%EC%8A%AC%EB%A1%AF-%EC%B9%B4%EC%9A%B4%ED%8A%B8-%EA%B8%B0%EB%8A%A5
 * 참고2 https://easings.net/ko#easeOutExpo
 */
const easeOutExpo = (x: number): number => {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
};

const useCount = (maxCount: number, duration: number) => {
  const [count, setCount] = useState(0);
  const ref = useRef(0);
  const DURATION = duration;
  const FRAME = 1000 / (Math.round(DURATION / 1000) * 60);
  const totalFrame = Math.round(DURATION / FRAME);

  const onCount = () => {
    const percent = easeOutExpo(ref.current / totalFrame);
    const currentCount = Math.round(maxCount * percent);

    setCount(currentCount);

    ref.current = ref.current += 1;
    const counting = window.requestAnimationFrame(onCount);

    if (currentCount === maxCount) {
      window.cancelAnimationFrame(counting);
    }
  };

  useEffect(() => {
    onCount();
    const counting = window.requestAnimationFrame(onCount);
    return () => {
      ref.current = 0;
      window.cancelAnimationFrame(counting);
    };
  }, [maxCount]);

  return count;
};

export default useCount;
