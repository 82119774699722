import { createGlobalStyle } from 'styled-components';

import {
  CamptonBoldFont,
  CamptonBookFont,
  CamptonLightFont,
  CamptonMediumFont,
  CamptonSemiBoldFont,
} from 'assets/fonts/index';

import cx from 'classnames';
export { cx };

import font from './base/_font';

const GlobalStyle = createGlobalStyle`

/* @font-face {
  font-family: 'Campton';
  font-weight: 300;
  src: (${CamptonLightFont}) format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
}

@font-face {
  font-family: 'Campton';
  font-weight: 400;
  src: (${CamptonBookFont}) format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
}
@font-face {
  font-family: 'Campton';
  font-weight: 500;
  src: (${CamptonMediumFont}) format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
}
@font-face {
  font-family: 'Campton';
  font-weight: 700;
  src: (${CamptonSemiBoldFont}) format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
}
@font-face {
  font-family: 'Campton';
  font-weight: 900;
  src: (${CamptonBoldFont}) format('woff');
  unicode-range: U+0020-007F, U+2000-2FFFF, U+3200-32FF, U+E007E;
} */
  ${font}
`;

export default GlobalStyle;
