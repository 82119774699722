import React, { ChangeEvent, useCallback, useState } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';

import ModalBottomButton from '../../../components/elements/Button/ModalBottomButton';
import WeightResponsePopup from './WeightResponsePopup';
import DeleteDefaultButton from '../../../components/Molecules/DeleteDefaultButton';
import ButtonSet from 'components/Atoms/Button/ButtonSet';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { toastMsgTextState } from 'states/globalAlert';
import { toastMsgState } from 'states/SearchResultState';
import Portal from 'components/portal';
import InputWeight from '../elements/InputWeight';
import useTopModal from '@hooks/useTopModal';

type convertHandlers = {
  [key: string]: () => void;
  '.': () => void;
  '00': () => void;
  default: () => void;
};

interface Props {
  weightState: {
    weight: number;
    weightChange: number;
    status: boolean;
  };
  closeModal(): void;
  onSubmitWeight: (value: number) => Promise<{
    weightChange: number;
    status: boolean;
  }>;
  onSubmitDelete?(): void;
  type?: 'weight';
}
export default function WeightInputModal({
  weightState,
  closeModal,
  onSubmitWeight,
  onSubmitDelete,
  type,
}: Props) {
  const classes = useStyle();
  const [value, setValue] = useState<number | string>(
    weightState.weight === 0 ? '' : weightState.weight,
  );

  const [, setToastText] = useRecoilState(toastMsgTextState);
  const setToastMsg = useSetRecoilState(toastMsgState);

  const { closeModal: onWeightResponseModalClose, openModal: onWeightResponseModalOpen } =
    useTopModal();

  const handleMaxDeciaml = (data: string) => {
    const MAX_NUMBER = 999.9;
    if (parseFloat(data) >= MAX_NUMBER) {
      setValue(999.9);
      return;
    }
    setValue(data);
  };
  const handleMaxNum = (data: string) => {
    const MAX_NUMBER = 999.9;
    if (parseFloat(data) >= MAX_NUMBER) {
      setValue(999.9);
      return;
    }
    setValue(Number(data));
  };

  const handleChangeWeight = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value.replace(',', '.');
    const handleConvert: convertHandlers = {
      '.': () => {
        return;
      },
      '00': () => {
        return;
      },
      '': () => {
        setValue(data);
      },
      default: () => {
        /* 정수와 소수점 한자리 수만 입력 가능한 정규 표현식 */
        const regExp = /(^\d+$)|(^\d+\.\d{0,1}$)/;
        if (regExp.test(data)) {
          handleMaxDeciaml(data);
        }
        /* 0으로 시작하는 수 ex) 012 */
        const zeroRegExp = /^0[0-9]{1,2}$/;
        if (zeroRegExp.test(data)) {
          setValue(Number(data));
        }
        /* 소수점 0으로 끝나는 수 ex) 120.0 */
        const decimalToNumRegExp = /(^\d+$)|(^\d+\.0$)/;
        if (decimalToNumRegExp.test(data)) {
          handleMaxNum(data);
        }
      },
    };

    (handleConvert[data] || handleConvert.default)();
  }, []);

  const submitHandle = async () => {
    const { status, weightChange } = await onSubmitWeight(Number(value));
    closeModal();
    onWeightResponseModalOpen(WeightResponsePopup, {
      closePopup: () => {
        onWeightResponseModalClose(WeightResponsePopup);
      },
      weight: Number(value),
      weightChange: weightChange,
      status,
    });
  };

  const closeModalHandle = () => {
    setValue(weightState.weight === 0 ? '' : weightState.weight);
    closeModal();
  };

  const deleteHandle = () => {
    onSubmitDelete?.();
    setToastMsg(true);
    setToastText(3);
    closeModal();
  };

  return (
    <Portal>
      <Grid className={classes.container}>
        <Grid className={classes.back} onClick={closeModalHandle} />
        <Grid className={classes.paper}>
          <Grid
            className={classes.header}
            container
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <p className={classes.title}>체중 입력</p>
            <DeleteDefaultButton onClick={closeModalHandle} color={'#000'} size={'large'} />
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Grid item>
              <InputWeight
                value={value}
                placeholder={'00.0'}
                setMeasurement={handleChangeWeight}
                unit={'kg'}
              />
            </Grid>
          </Grid>
          {type === 'weight' && (
            <div className={classes.weightButtonWrapper}>
              <ButtonSet
                text="삭제하기"
                size="half"
                type="button"
                color="gray"
                onClick={deleteHandle}
                isActive
              />
              <ButtonSet
                text="수정하기"
                size="half"
                type="button"
                color="black"
                isActive={Number(value) > 0}
                onClick={submitHandle}
              />
            </div>
          )}
          {!type && (
            <ModalBottomButton isOn={Number(value) > 0} onClick={submitHandle}>
              완료
            </ModalBottomButton>
          )}
        </Grid>
      </Grid>
    </Portal>
  );
}

const useStyle = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2204,
    },
    back: {
      width: '100%',
      height: '100%',
      background: '#000',
      opacity: 0.5,
    },
    paper: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      background: '#fff',
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      padding: '20px 0 0',
    },
    weightButtonWrapper: {
      display: 'flex',
      gap: 7,
      padding: 12,
    },
    header: {
      padding: '0 20px 0 25px',
    },
    inputWrapper: {
      padding: '50px 0 50px',
    },
    title: {
      fontSize: 17,
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: -0.62,
    },
  }),
);
