import Popup from '@components/elements/Popup/Popup';
import { dateState } from '@states/DateState';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

interface Props {
  onClose: () => void;
  onNotionPage: () => void;
}

const UnSyncHealthDataPopup = ({ onClose, onNotionPage }: Props) => {
  const date = useRecoilValue(dateState);
  const convertedDate = dayjs(date).format('M월 D일');

  return (
    <Popup
      headText={`${convertedDate}에 불러올 수 있는\n데이터가 없어요`}
      subText1={'시스템 설정에서 접근 권한이\n꺼져있는지 확인해 주세요'}
      buttonText1={'설정 방법 보기'}
      buttonText2={'확인'}
      onClick={onClose}
      onClose={onClose}
      cancel={onNotionPage}
    />
  );
};

export default UnSyncHealthDataPopup;
