interface Props {
  color?: string;
  innerColor?: string;
}

export default function CheckCircleDefault({ color = 'white', innerColor = 'black' }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <ellipse cx="10" cy="10" rx="10" ry="10" transform="rotate(-90 10 10)" fill={color} />
      <path
        d="M5.93753 10.0806L8.54901 12.6921L14.0984 7.14271"
        stroke={innerColor}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
