import styled from 'styled-components';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';

const Container = styled.section<{
  minHeight?: string;
  maxHeight?: string;
  paddingBottom?: string;
}>`
  width: 100%;
  height: 100%;
  min-height: ${(props) => props.minHeight ?? 619}px;
  max-height: ${(props) => props.maxHeight ?? 649}px;
  padding-top: 27px;
  padding-bottom: ${(props) => props.paddingBottom ?? 60}px;
  background-color: #8796d7;
  &.navy {
    background-color: ${COLORS.PRIMITIVES_BLUE_NAVY_400};
  }
  &.apricot {
    background-color: ${COLORS.PRIMITIVES_APRICOT_400};
  }
  &.khaki {
    background-color: ${COLORS.PRIMITIVES_KHAKI_400};
  }
  &.skyblue {
    background-color: ${FOUNDATION_COLOR.PRIMARY_SKYBLUE};
  }
  overflow: hidden;
`;

const ThemeBox = styled.ul`
  display: flex;
  gap: 12px;
  margin-bottom: 2px;

  li {
    display: flex;
    font-size: 17px;
    font-weight: 700;
  }

  .navy {
    color: ${COLORS.PRIMITIVES_BLUE_NAVY_500};
  }
  .apricot {
    color: ${COLORS.PRIMITIVES_APRICOT_600};
  }
  .khaki {
    color: ${COLORS.PRIMITIVES_KHAKI_500};
  }
  .skyblue {
    color: ${COLORS.PRIMITIVES_SKY_BLUE_700};
  }

  .tabChecked {
    color: ${FOUNDATION_COLOR.BASIC_WHITE};
  }
`;

const ThemeButton = styled.button`
  width: 100%;
  height: 60px;

  border-radius: 120px;

  .navy {
    background-color: ${COLORS.PRIMITIVES_NAVY_450};
  }
  .apricot {
    color: ${COLORS.PRIMITIVES_APRICOT_500};
  }
  .khaki {
    color: ${COLORS.PRIMITIVES_KHAKI_500};
  }
  .skyblue {
    color: ${COLORS.PRIMITIVES_SKY_BLUE_700};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.81;
    color: #ffffff;
    .newText {
      font-size: 14px;
      font-weight: 600;
      color: #ff4e61;
    }
  }
`;

export { ThemeBox, Container, ThemeButton, Wrapper };
