import { del, get, post } from '@modules/HttpWebClient/default';
import {
  CalendarDataForMain,
  FastingResponse,
  IDelResponseMsg,
  RequestDate,
  ResponseMsg,
} from '@models/dietDiary';

export async function setDayWeight({
  weight,
  created_at,
}: {
  weight: number;
  created_at: string;
}): Promise<ResponseMsg> {
  const url = `/weight`;
  const { data } = await post<ResponseMsg>(url, {
    weight,
    created_at,
  });
  return data;
}

export async function deleteDayWeight(created_at: string): Promise<IDelResponseMsg> {
  const url = `/weight`;
  const { data } = await del<IDelResponseMsg>(url, {
    data: { created_at },
  });
  return data;
}

export async function setFasting(request: RequestDate): Promise<FastingResponse> {
  const url = `/food/fasting`;
  const { data } = await post<FastingResponse>(url, {
    ...request,
  });
  return data;
}

export async function deleteFasting({ time, created_at }: RequestDate): Promise<FastingResponse> {
  const url = `/food/fasting/${time}/${created_at}`;
  const { data } = await del<FastingResponse>(url);
  return data;
}

export async function getCalendarForMain(startDay: string): Promise<CalendarDataForMain> {
  const url = `/calendar/list/${startDay}`;
  const { data } = await get<CalendarDataForMain>(url);
  return data;
}
