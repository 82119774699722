import styled from 'styled-components';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';
import LEVEL from 'styles/constants/_levels';

export const TabTextContainer = styled.nav<{ gap?: number }>`
  font-family: 'Noto Sans KR';
  font-weight: 700;
  display: flex;
  align-items: center;
  top: 0;
  max-width: 428px;
  width: 100%;
  z-index: ${LEVEL.COMMUNICATIONS_HEADER};
  background: ${FOUNDATION_COLOR.BASIC_WHITE};

  ul {
    position: relative;
    display: flex;
    width: 100%;
    font-size: 20px;
    letter-spacing: 0.5;
    gap: ${(props) => (props.gap ? props.gap : 14)}px;

    li {
      a {
        position: relative;
        width: 100%;
        color: ${COLORS.PRIMITIVES_GRAY_350};
      }

      .selected {
        color: ${FOUNDATION_COLOR.BASIC_BLACK};
      }
    }

    li {
      a {
        position: relative;
        width: 100%;
        color: ${COLORS.PRIMITIVES_GRAY_350};
      }

      .selected {
        color: ${FOUNDATION_COLOR.BASIC_BLACK};
      }
    }

    &.big {
      height: 68px;
      padding: 30px 0px 10px 20px;
      a {
        cursor: pointer;
      }
      .selected {
        a {
          color: ${FOUNDATION_COLOR.BASIC_BLACK};
        }
        color: ${FOUNDATION_COLOR.BASIC_BLACK};
      }

      .campton {
        font-family: Campton;
        font-size: 23px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.5px;
      }

      /* 
      .selected::after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        top: 3px;
        border-radius: 50%;
        margin-left: 3px;
        background: ${FOUNDATION_COLOR.BASIC_BLACK};
      } */
    }

    &.medium {
      justify-content: space-around;
      height: 62px;
      font-size: 14px;
      padding: 24px 21px 21px;

      .selected::after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        top: 3px;
        border-radius: 50%;
        margin-left: 3px;
        background: ${FOUNDATION_COLOR.BASIC_RED};
      }
    }

    &.thin {
      gap: 0px;
      height: 44px;
      font-size: 14px;
      font-weight: 500;

      li {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        a {
          margin-bottom: 2px;
        }

        &.selected {
          /* border-bottom: 1px solid ${FOUNDATION_COLOR.BASIC_BLACK}; */
          a {
            color: ${FOUNDATION_COLOR.BASIC_BLACK};
          }
          & div {
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(100%);
            height: 1px;
            transition: 0.5s ease-in-out;
            background: ${FOUNDATION_COLOR.BASIC_BLACK};
          }
        }

        a {
          color: ${COLORS.PRIMITIVES_GRAY_500};
        }
      }
    }
  }
`;
