import styled from 'styled-components';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';

const ConvertTimeDd = styled.dd`
  mark {
    font-weight: 500;
    font-size: 30px;
    color: ${COLORS.WHITE};
    background: transparent;
  }

  .minute {
    mark {
      margin-right: 1px;
    }
  }

  .time {
    margin-right: 6px;
  }

  &.main {
    font-weight: 600;
    font-size: 18px;

    .time {
      margin-right: 10.5px;
    }

    mark {
      font-weight: 600;
      font-size: 50px;
      color: ${COLORS.WHITE};
      background: transparent;
    }
  }
`;

export { ConvertTimeDd };
