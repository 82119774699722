import { deleteFasting, setFasting } from '@apis/dietDiaryApi';
import CheckCircleDefault from '@assets/images/icon/Atoms/CheckCircleDefault';
import { WidgetKind } from '@constants/widget';
import { useDate } from '@hooks/useDate';
import useTopModal from '@hooks/useTopModal';
import { RequestDate } from '@models/dietDiary';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { COLORS } from '@styles/constants/_colors';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import FastingConfirmModal from './FastingConfirmModal';

interface Props {
  isFasting: boolean;
  mealTime: number; // 0(아침) | 1(점심) | 2(저녁) | 3(간식)
}

const FastingOnOffCheck = ({ isFasting, mealTime }: Props) => {
  const { date } = useDate();
  const queryClient = useQueryClient();
  const { openModal: openTopModal, closeModal: closeTopModal } = useTopModal();

  const { mutate: onFasting, status: onFastingStatus } = useMutation(
    (request: RequestDate) => setFasting(request),
    {
      onSuccess: () => {
        const modalText = mealTime === 3 ? '간식 참기를 완료했어요!' : '단식 기록을 완료했어요!';
        handleFastingModal(modalText);
        queryClient.refetchQueries(['main2', 'mealInfo', date]);
        sendReactNativeMessage({
          type: 'widgetReloadTimelines',
          payload: {
            widgetKind: WidgetKind.MEAL_RECORD,
          },
        });
      },
    },
  );

  const { mutate: cancleFasting, status: cancelFastingStatus } = useMutation(
    (request: RequestDate) => deleteFasting(request),
    {
      onSuccess: () => {
        const modalText = mealTime === 3 ? '간식 참기를 취소했어요' : '단식 기록을 취소했어요';
        handleFastingModal(modalText);
        queryClient.refetchQueries(['main2', 'mealInfo', date]);
        sendReactNativeMessage({
          type: 'widgetReloadTimelines',
          payload: {
            widgetKind: WidgetKind.MEAL_RECORD,
          },
        });
      },
    },
  );

  const handleFasting = (mealTime: number) => {
    if (onFastingStatus === 'loading' || cancelFastingStatus === 'loading') return;

    const request = {
      time: mealTime,
      created_at: date,
    };

    isFasting ? cancleFasting(request) : onFasting(request);
  };

  const handleFastingModal = (text: string) => {
    openTopModal(FastingConfirmModal, {
      text: text,
      closeModal: () => {
        closeTopModal(FastingConfirmModal);
      },
    });
  };

  return (
    <FastingCheckWrapper
      className={isFasting ? 'checked' : 'unChecked'}
      onClick={() => handleFasting(mealTime)}
    >
      <CheckCircleDefault />
      <p>{mealTime !== 3 ? '단식했어요' : '참았어요'}</p>
    </FastingCheckWrapper>
  );
};

export default FastingOnOffCheck;

const FastingCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  &.checked {
    color: ${COLORS.WHITE};

    ellipse {
      fill: ${COLORS.WHITE};
    }
    path {
      stroke: ${COLORS.PRIMITIVES_BLUE_NAVY_450};
    }
  }

  &.unChecked {
    color: #4f709f;

    ellipse {
      fill: #4f709f;
    }
    path {
      stroke: ${COLORS.PRIMITIVES_BLUE_NAVY_450};
    }
  }

  p {
    margin-bottom: 2px;
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.615px;
  }
`;
