import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';

interface Props {
  badgeName: string;
  percent: number;
  themeBadgeColor: string;
  themeTextColor: string;
}

const BadgePercent = ({ badgeName, percent, themeBadgeColor, themeTextColor }: Props) => {
  return (
    <Wrapper>
      <NameBadgeUnit $badgeColor={themeBadgeColor} $textColor={themeTextColor}>
        {badgeName}
      </NameBadgeUnit>
      <PercentBox>
        <div>{percent}</div>
        <span>%</span>
      </PercentBox>
    </Wrapper>
  );
};

export default BadgePercent;

const Wrapper = styled.div`
  display: flex;
  gap: 7px;
`;

const NameBadgeUnit = styled.div<{
  $textColor: string;
  $badgeColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 1px;
  padding-bottom: 2px;
  background-color: ${({ $badgeColor }) => $badgeColor};

  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: 900;

  color: ${({ $textColor }) => $textColor};
`;

const PercentBox = styled.div`
  display: flex;
  align-items: flex-end;
  text-align: end;
  color: ${COLORS.WHITE};
  gap: 2px;
  padding-bottom: 2px;

  > div {
    font-size: 20px;
    font-weight: 600;

    letter-spacing: -0.5px;
  }

  > span {
    font-size: 12px;
    font-weight: 900;
    padding-bottom: 2px;
  }
`;
