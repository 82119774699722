import { del, get, post } from '../modules/HttpWebClient/default';
import {
  BodyConditionResponse,
  CpType,
  DietPatterns,
  MemoList,
  RequestBodyCondition,
  RequestDietPattern,
  RequestSupplementRecords,
  SupplementsResponse,
} from '../types/tag';
import { ResponseMsg } from '../types/dietDiary';

export async function getDayBodyCondition(createdAt: string): Promise<BodyConditionResponse> {
  const url = `/tags/bodycondition/${createdAt}`;
  const { data } = await get<BodyConditionResponse>(url);
  return data;
}

export async function setDayBodyCondition(
  request: RequestBodyCondition,
): Promise<BodyConditionResponse> {
  const url = `/tags/bodycondition`;
  const { data } = await post<BodyConditionResponse>(url, {
    ...request,
  });
  return data;
}

export async function deleteDayBodyCondition(createdAt: string): Promise<BodyConditionResponse> {
  const url = `/tags/bodycondition/${createdAt}`;
  const { data } = await del<BodyConditionResponse>(url);
  return data;
}

export async function getMySupplements(createdAt: string): Promise<SupplementsResponse> {
  const url = `/tags/supplements/mylist/${createdAt}`;
  const { data } = await get<SupplementsResponse>(url);
  return data;
}

export async function setMySupplements(
  name: string,
  createdAt: string,
): Promise<SupplementsResponse> {
  const url = `/tags/supplements/mylist`;
  const { data } = await post<SupplementsResponse>(url, {
    name,
    created_at: createdAt,
  });
  return data;
}

export async function modifyMySupplements(
  request: RequestSupplementRecords,
): Promise<SupplementsResponse> {
  const url = `/tags/supplements/reg`;
  const { data } = await post<SupplementsResponse>(url, {
    ...request,
  });
  return data;
}

export async function deleteAllSupplementRecords(createdAt: string): Promise<SupplementsResponse> {
  const url = `/tags/supplements/reg/all/${createdAt}`;
  const { data } = await del<SupplementsResponse>(url);
  return data;
}

export async function deleteSupplementRecords(
  name_idx: number,
  date: string,
): Promise<SupplementsResponse> {
  const url = `/tags/supplements/mylist/${name_idx}/${date}`;
  const { data } = await del<SupplementsResponse>(url);
  return data;
}
