import { getMiddleNumber } from '@utils/getMiddleNumber';

export const BMI_GRAPH_DATA = [
  {
    status: '저체중',
    min: 13.5,
    max: 18.4,
    style: {
      width: 39,
      color: '#deeb70',
    },
  },
  {
    status: '정상 체중',
    min: 18.5,
    max: 22.9,
    style: {
      width: 59,
      color: '#acda78',
    },
  },
  {
    status: '과체중',
    min: 23,
    max: 24.9,
    style: {
      width: 43,
      color: '#6dbe84',
    },
  },
  {
    status: '비만',
    min: 25,
    max: 29.9,
    style: {
      width: 59,
      color: '#629bca',
    },
  },
  {
    status: '고도 비만',
    min: 30,
    max: 35,
    style: {
      width: 35,
      color: '#3d5885',
    },
  },
];

export const getBMIRatePercentage = (min: number, max: number, width: number, value: number) => {
  const BMI_MIN_NUMBER = 13.5;
  const BMI_MAX_NUMBER = 35;
  value = getMiddleNumber([BMI_MIN_NUMBER, value, BMI_MAX_NUMBER]);
  const range = max - min;
  const tg = value - min;
  const percentage = Math.floor((tg / range) * 100); // TODO: 중원님 작업하신 백분율 계산 함수 가져다쓰기
  const result = Math.floor(percentage * width) / 100;
  return Math.floor(result);
};
