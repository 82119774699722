import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { COLORS } from '@styles/constants/_colors';
import { CommonChartData } from '@models/chart';

interface Props {
  chartData: CommonChartData[];
  usageCalorie: number;
  themeColor?: string;
}

const SummaryPieChart = ({ chartData, usageCalorie = 0, themeColor = '#69B9C4' }: Props) => {
  const [chartKey, setChartKey] = useState(0);
  const isEmptyData = chartData.every(({ percent }) => percent == 0);
  const hasOnceData = chartData.filter(({ percent }) => percent !== 0).length === 1;

  const usageChartData = useMemo(
    () =>
      isEmptyData
        ? [
            {
              title: 'empty',
              percent: 1,
              fill: 'url(#paint_linear)',
            },
          ]
        : chartData.filter(({ percent }) => percent !== 0),
    [chartData, usageCalorie],
  );

  const usagePiePadding = () => {
    if (isEmptyData || hasOnceData) return 0;

    return 8;
  };

  useEffect(() => {
    setTimeout(() => {
      setChartKey(usageCalorie + 1);
    }, 100);
  }, [usageCalorie]);

  return (
    <Wrapper>
      <ResponsiveContainer maxHeight={214} aspect={1} width="99%">
        <PieChart key={chartKey}>
          <defs>
            <linearGradient
              id="paint_linear"
              x1="21.5"
              y1="21.5"
              x2="236"
              y2="195"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={themeColor} />
              <stop offset="1" stopColor={themeColor} stopOpacity="0.2" />
            </linearGradient>
          </defs>
          <Pie
            dataKey="percent"
            data={usageChartData}
            innerRadius="93%"
            outerRadius="100%"
            paddingAngle={usagePiePadding()}
            cornerRadius={7}
          >
            {usageChartData.map(({ fill }, idx) => (
              <Cell key={idx} tabIndex={idx} fill={fill} stroke="" />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <EatingTextBox>
        <div>
          <span className="value">{Math.round(usageCalorie)}</span>
          <span>kcal</span>
        </div>
        <div>먹었어요</div>
      </EatingTextBox>
    </Wrapper>
  );
};

export default SummaryPieChart;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  max-width: 214px;
  height: 100%;
`;

const EatingTextBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans KR;

  color: ${COLORS.WHITE};
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.5px;

  .value {
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
  }

  > div:nth-child(1) {
    display: flex;
    gap: 2px;
    align-items: flex-end;
    font-family: Campton;
  }
`;
