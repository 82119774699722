import React from 'react';

interface Props {
  background?: string;
}

const NutrientDetailGraph = ({ background = '#469AA6' }: Props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Graph">
        <circle id="Ellipse 112" cx="10" cy="10" r="10" fill={background} />
        <path
          id="Ellipse 113"
          d="M20 10C20 10 15.5228 10 10 10C10 4.47715 10 0 10 0C15.5229 0 20 4.47715 20 10Z"
          fill="#1C3558"
        />
        <path
          id="Ellipse 114"
          d="M20 9.99997C20 11.4222 19.7031 12.775 19.1679 14C19.1679 14 14.5 12 10 9.99997L19.9045 8.61176C19.9674 9.06539 20 9.52899 20 9.99997Z"
          fill="#FEFF92"
        />
      </g>
    </svg>
  );
};

export default NutrientDetailGraph;
