import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import BodyCheckFrontImage from '@assets/images/png/body-check-front.png';
import BodyCheckSideImage from '@assets/images/png/body-check-side.png';
import DeletedImage from '@assets/images/png/deleted-image.png';

interface Props {
  type: 'front' | 'side';
  isDeleted?: boolean;
  onClick?: () => void;
}

const EmptyItem = ({ type, isDeleted, onClick }: Props) => {
  const displayTitle = {
    front: '앞모습',
    side: '옆모습',
  };

  const displayImage = (type: string) => {
    if (isDeleted) return DeletedImage;

    return {
      front: BodyCheckFrontImage,
      side: BodyCheckSideImage,
    }[type];
  };

  return (
    <Wrapper onClick={onClick}>
      <CharacterImageUnit src={displayImage(type)} />
      <TitleUnit>{displayTitle[type]}</TitleUnit>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18px 0;
  gap: 9px;
  transform: translateY(5%);
`;

const CharacterImageUnit = styled.img`
  width: 100%;
  object-fit: contain;
`;

const TitleUnit = styled.div`
  display: flex;
  color: ${COLORS.PRIMITIVES_GRAY_450};
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.312px;
`;

export default EmptyItem;
