import Portal from '@components/portal';
import styled from 'styled-components';
import MyBMIText from 'pages/main/MyChange/WeightDiary/components/MyBMIText';
import XDefault from '@assets/images/icon/Atoms/XDefault';
import { PointerIcon } from '@assets/svgs/_Icons';
import { useMemo } from 'react';
import { getMiddleNumber } from '@utils/getMiddleNumber';
import { BMI_GRAPH_DATA, getBMIRatePercentage } from '@pages/main/util/BMI_RANGE';
import { COLORS } from '@styles/constants/_colors';
import { ThemeButton } from 'pages/exercise/ExerciseDiary/styles';

interface Props {
  closeModal: () => void;
  bmi: number;
}

function MyBMIModal({ closeModal, bmi }: Props) {
  const currentBarIndex = useMemo(() => {
    const MIN_INDEX = 0;
    const MAX_INDEX = BMI_GRAPH_DATA.length - 1;
    // BMI수치가 13.5이하면 13.5, 35넘으면 35로 보정한다.
    const fixBMI = getMiddleNumber([
      BMI_GRAPH_DATA[MIN_INDEX].min,
      bmi,
      BMI_GRAPH_DATA[MAX_INDEX].max,
    ]);

    return BMI_GRAPH_DATA.findIndex((data) => fixBMI >= data.min && fixBMI <= data.max);
  }, [bmi]);
  const pointerPosition = getBMIRatePercentage(
    BMI_GRAPH_DATA[currentBarIndex].min,
    BMI_GRAPH_DATA[currentBarIndex].max,
    BMI_GRAPH_DATA[currentBarIndex].style.width,
    bmi,
  );

  return (
    <Portal>
      <BackdropWrapper>
        <ModalField>
          <HeaderBox>
            <MyBMITitle iconFaceColor="#FFE477" textColor="#000" />
            <CloseButton onClick={closeModal}>
              <XDefault size="large" color="black" />
            </CloseButton>
          </HeaderBox>
          <BMINumber>{bmi}</BMINumber>
          <Status>{BMI_GRAPH_DATA[currentBarIndex].status}</Status>

          <BarGraphField>
            {BMI_GRAPH_DATA.map((data, index) => (
              <Bar key={index} width={data.style.width} color={data.style.color}>
                {currentBarIndex === index && (
                  <PointerBox pointerPosition={pointerPosition}>
                    <PointerIcon color={BMI_GRAPH_DATA[currentBarIndex].style.color} />
                  </PointerBox>
                )}
              </Bar>
            ))}
          </BarGraphField>
          <RangeNumberBox>
            <BMIStandardText>18.5</BMIStandardText>
            <BMIStandardText>23</BMIStandardText>
            <BMIStandardText>25</BMIStandardText>
            <BMIStandardText>30</BMIStandardText>
          </RangeNumberBox>

          <BMIStandardText>
            BMI <span>계산 기준</span>
          </BMIStandardText>

          <Description>
            <span>18.5</span> 미만 : 저체중 <span>/ 18.5 ~ 22.9 :</span> 정상 체중{'\n'}
            <span>23 ~ 24.9 :</span> 과체중 <span>/ 25 ~ 29.9 :</span> 비만
            {'\n'}
            <span>30.0</span> 이상 : 고도 비만{'\n'}
            <span>(WHO</span> 아시아 태평양 지역<span>, 2000</span>년<span>)</span>
          </Description>

          <ConfirmButton color={COLORS.BLACK} onClick={closeModal}>
            확인
          </ConfirmButton>
        </ModalField>
      </BackdropWrapper>
    </Portal>
  );
}

export default MyBMIModal;

const BackdropWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
`;

const ModalField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 30px;
  margin: 0px 30px;
  padding: 20px 30px 30px;
`;

const MyBMITitle = styled(MyBMIText)`
  transform: translateX(-5px);
`;

const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const CloseButton = styled.button`
  cursor: pointer;
  svg {
    transform: translateX(12px);
  }
`;

const BMINumber = styled.p`
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.62px;
  text-align: center;
  margin-bottom: 8px;
`;

const Status = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 14px;
`;

const BarGraphField = styled.div`
  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

const PointerBox = styled.div<{ pointerPosition?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  transform: ${(props) => `translate(calc(${props.pointerPosition}px - 50%), calc(-3px + -100%))`};
`;

const Bar = styled.div<{ width: number; color: string }>`
  height: 8px;
  width: ${(props) => props.width}px;
  border-radius: ${(props) => props.width}px;
  background-color: ${(props) => props.color};
`;

const RangeNumberBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 199px;
  margin: 8px 0px 33px;
`;

const BMIStandardText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -0.5px;

  span {
    font-size: 13px;
  }
`;

const Description = styled.p`
  width: 210px;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.PRIMITIVES_GRAY_500};
  margin-top: 15px;
  margin-bottom: 42px;
  white-space: pre;

  span {
    font-size: 13px;
  }
`;

const ConfirmButton = styled(ThemeButton)`
  color: ${COLORS.WHITE};
  width: 100%;

  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.62px;
  text-align: center;
  cursor: pointer;
`;
