import React, { CSSProperties } from 'react';

export interface Case {
  keywords: string[];
  color?: string;
  weight?: number;
  size?: number;
}

interface Part {
  content: string;
  style: CSSProperties;
}

interface Props {
  children: string;
  cases: Case[];
}

const HighLightText = ({ children, cases }: Props) => {
  // 각 케이스에 대한 정규표현식을 생성합니다.
  const regexList = cases.map((caseItem) => new RegExp(`(${caseItem.keywords.join('|')})`, 'gi'));

  // 문자열을 각 케이스의 매칭된 부분을 기준으로 나누고, 매칭된 부분에 각 케이스에 해당하는 스타일을 적용합니다.
  const parts = regexList.reduce(
    (acc, regex, caseIndex) => {
      return acc.flatMap((part) =>
        part.content.split(regex).map((subPart) => ({
          content: subPart,
          style: regex.test(subPart)
            ? {
                color: cases[caseIndex]?.color,
                fontWeight: cases[caseIndex]?.weight,
                textSize: `${cases[caseIndex]?.size}px`,
              }
            : {},
        })),
      );
    },
    [{ content: children, style: {} }] as Part[],
  );

  return (
    <p>
      {parts.map((part, index) => (
        <span key={index} style={part.style}>
          {part.content}
        </span>
      ))}
    </p>
  );
};

export default HighLightText;
