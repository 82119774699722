import React from 'react';
import styled from 'styled-components';

import SummaryPieChart from './components/SummaryPieChart';
import NutrientsDetailButton from './components/NutrientsDetailButton';
import useMainFirstQuery from '@pages/main/TodayNutrients/hooks/useMainFirstQuery';
import CalorieResult from '@pages/main/TodayNutrients/components/CalorieResult';
import NutrientProgress from '@pages/main/TodayNutrients/components/NutrientProgress';
import NutrientPercent from '@pages/main/TodayNutrients/components/NutrientPercent';
import useConfigQuery from '@hooks/queries/useConfigQuery';

const DashboardTheme = () => {
  const { data, isLoading, nutrientList } = useMainFirstQuery();
  const { selectThemeColor } = useConfigQuery();

  if (!data || isLoading) return <></>;

  const { day, goals } = data;
  const { calories_day, exerciseCalories, sugar_day } = day;
  const { calories_goal } = goals;

  return (
    <DashboardThemeWrapper>
      <ChartField>
        <SummaryPieChart
          chartData={nutrientList}
          usageCalorie={calories_day}
          themeColor={selectThemeColor.color2}
        />
        <NutrientsPercentBox>
          {nutrientList.map(({ fill, title, percent }, idx) => (
            <NutrientPercent key={idx} pinColor={fill} title={title} percent={percent} />
          ))}
        </NutrientsPercentBox>
      </ChartField>
      <ProgressBox>
        {nutrientList.map((v, idx) => (
          <NutrientProgress key={idx} {...v} themeColor={selectThemeColor.color2} />
        ))}
      </ProgressBox>
      <NutrientsDetailButton
        sugar={sugar_day}
        themeBackgroundColor={selectThemeColor.color2}
        themeChartColor={selectThemeColor.color3}
      />
      <CalorieResult
        eat={calories_day}
        goal={calories_goal}
        usage={exerciseCalories}
        themeColor={selectThemeColor.color2}
      />
    </DashboardThemeWrapper>
  );
};

export default DashboardTheme;

const DashboardThemeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 25px;
`;

const ChartField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  gap: 18px;
`;

const NutrientsPercentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 120px;
  height: 100%;
`;

const ProgressBox = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  margin: 22px 0 30px;
  padding: 0 9px;
`;
