// 백분율 구하기
export const calculatePercentage = (value: number, total: number, decimal = 0) => {
  if (total == 0) return 0;

  return Number(((value / total) * 100).toFixed(decimal));
};

// 배열 내 모든 정수의 합
export const sumArray = (...array: number[]) => {
  return array.reduce((acc, cur) => acc + cur);
};

export const overThousand = (num: number) => {
  if (num > 999) return `999+`;
  return num;
};

export const handleOverValue = (value: number, max = 999) => {
  if (value > max) {
    return `${max}+`;
  }
  return value;
};
