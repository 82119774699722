import Add from '@assets/images/icon/Add';
import CheckDefault from '@assets/images/icon/Atoms/CheckDefault';

interface Props {
  isMealRecord: number; // 0(falsy): 미입력 | 1: 입력
}

const MealRecordCheckIcon = ({ isMealRecord }: Props) => {
  return (
    <>
      {isMealRecord ? (
        <CheckDefault size="extraLarge" color="white" />
      ) : (
        <Add size="semiLarge" color="white" />
      )}
    </>
  );
};

export default MealRecordCheckIcon;
