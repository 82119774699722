import { countClickBanner, countViewBanner } from '@apis/adApi';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import Portal from '@components/portal';
import { AdContent } from '@models/ad';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';
import { swipeUp } from '@styles/mixins/_animations';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PROMOTIONKEY } from 'shared/link';
import styled from 'styled-components';
import SwiperRef from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss/pagination';
import { SwiperProps } from 'swiper/swiper-react';
import { setCookie } from 'utils/cookie';

interface Props {
  bannerData: AdContent[];
  closeBanner: () => void;
}

function PromotionModal({ bannerData, closeBanner }: Props) {
  const navigate = useNavigate();
  const viewBannerIdx = useRef<Map<number, number>>(new Map());
  const { mutate: postPromotionView } = useMutation((ad_idx: number) => countViewBanner(ad_idx));
  const { mutate: postPromotionClick } = useMutation((ad_idx: number) => countClickBanner(ad_idx));

  const swiperRef = useRef<SwiperRef>(null);
  const swiperOptions: SwiperProps = {
    slidesPerView: 1,
    modules: [Pagination, Autoplay],
    pagination: {
      enabled: bannerData.length > 1,
      type: 'bullets',
    },
    loop: true,
    autoplay: {
      delay: 2800,
    },
    onSwiper: (swiper: SwiperRef) => {
      swiperRef.current = swiper;
      handleBannerView(swiper.realIndex);
    },
    onSlideChangeTransitionStart: (swiper: SwiperRef) => {
      handleBannerView(swiper.realIndex);
    },
  };

  const handleBannerView = (swiperIndex: number) => {
    if (viewBannerIdx.current.has(swiperIndex)) return;

    viewBannerIdx.current.set(swiperIndex, bannerData[swiperIndex].ad_idx);

    const viewedBannerIdx = viewBannerIdx.current.get(swiperIndex);
    if (viewedBannerIdx) {
      postPromotionView(viewedBannerIdx);
    }
  };

  const moveToPromotionAndClickCounting = (link: string, ad_idx: number) => {
    const isExternal = link.includes('http') ? true : false;
    postPromotionClick(ad_idx);

    if (isExternal) {
      window.location.href = link;
    } else {
      navigate(`${link}`);
    }
  };

  const disableOneDays = () => {
    setCookie(PROMOTIONKEY, 'disableOneDays', 1);
    closeBanner();
  };

  return (
    <Portal>
      <DimmedWrapper onClose={closeBanner}>
        <ModalWrapper>
          <Swiper {...swiperOptions}>
            {bannerData.map(({ img, ad_idx, link }) => (
              <SwiperSlide key={ad_idx}>
                <ImageField>
                  <ImageBox onClick={() => moveToPromotionAndClickCounting(link, ad_idx)}>
                    <img src={img} alt={'promotion img'} />
                  </ImageBox>
                </ImageField>
              </SwiperSlide>
            ))}
          </Swiper>

          <FooterField>
            <DisableButton onClick={disableOneDays}>하루 동안 보지 않기</DisableButton>
            <CloseButton onClick={closeBanner}>닫기</CloseButton>
          </FooterField>
        </ModalWrapper>
      </DimmedWrapper>
    </Portal>
  );
}

export default PromotionModal;

const ModalWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: ${LEVEL.PROMOTION_MODAL};

  width: 100vw;
  max-width: 428px;
  background-color: transparent;
  animation: ${swipeUp} 500ms ease;

  .swiper-pagination-bullets {
    position: fixed !important;
    width: fit-content;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
  }

  .swiper-pagination-bullet {
    background-color: ${COLORS.PRIMITIVES_GRAY_350};
    opacity: 1;
    margin: 0 3.5px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: ${COLORS.BLACK};
  }
`;

const ImageField = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 100%;
`;

const ImageBox = styled.div`
  width: 100%;
  position: absolute;
  bottom: -6px;
  max-width: 428px;

  > img {
    width: 100%;
    object-fit: contain;
    vertical-align: bottom;
  }
`;

const FooterField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 48px;
  background-color: ${FOUNDATION_COLOR.BASIC_WHITE};
`;

const FooterText = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.408px;
`;

const DisableButton = styled(FooterText)`
  color: ${COLORS.PRIMITIVES_GRAY_500};
`;

const CloseButton = styled(FooterText)`
  color: ${COLORS.BLACK};
`;
