import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import MainCalroieSlice from '@assets/svgs/atomic/MainCalroieSlice';
import { calculatePercentage } from '@utils/formatNumber';

interface Props {
  title: string;
  value: number;
  goal: number;
  themeColor?: string;
}

const NutrientProgress = ({
  title,
  value = 0,
  goal = 100,
  themeColor = COLORS.PRIMITIVES_SKY_BLUE_500,
}: Props) => {
  const usedValue = Math.min(value, goal);
  const indicatorPercentage = calculatePercentage(usedValue, goal);

  const isExceeded = value > goal;
  const hasValue = 0 < value;

  return (
    <Wrapper>
      <TitleUnit>{title}</TitleUnit>
      <ProgressBox $themeColor={themeColor} $hasValue={hasValue}>
        <IndicatorUnit $progress={indicatorPercentage} />
      </ProgressBox>
      <GoalResultBox $exceeded={isExceeded}>
        <span className="value">{value}</span>
        <MainCalroieSlice size="6*12" />
        <span>{goal}g</span>
      </GoalResultBox>
    </Wrapper>
  );
};

export default NutrientProgress;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 11px;
  align-items: center;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.5px;
`;

const TitleUnit = styled.div`
  width: 100%;
  text-align: center;
  color: ${COLORS.WHITE};
  font-size: 13px;
  font-weight: 700;
`;

const ProgressBox = styled.div<{ $themeColor: string; $hasValue: boolean }>`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  background: ${({ $hasValue, $themeColor }) =>
    $hasValue ? $themeColor : `linear-gradient(90deg, ${$themeColor} 0%, rgba(0,0,0,0) 140%)`};
`;

const IndicatorUnit = styled.div<{ $progress: number }>`
  border-radius: 4px;
  background-color: ${COLORS.WHITE};
  height: 100%;
  width: ${({ $progress }) => $progress}%;
`;

const GoalResultBox = styled.div<{ $exceeded: boolean }>`
  display: flex;
  gap: 4px;

  font-family: Campton;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  color: ${COLORS.WHITE};

  .value {
    font-weight: 700;

    ${({ $exceeded }) =>
      $exceeded &&
      css`
        color: #ff5647;
      `}
  }
`;
