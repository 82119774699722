interface Props {
  status: 'none' | 'recorded' | 'exceed';
  themeColor: string;
}

const HalfGaugeGradient = ({ status, themeColor }: Props) => {
  const gradientComponent = {
    none: (
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id="none"
        x1="368"
        y1="49.5"
        x2="8"
        y2="211"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={themeColor} />
        <stop offset="0.99361" stopColor={themeColor} stopOpacity={0.1} />
      </linearGradient>
    ),
    recorded: (
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id="recorded"
        x1="249.807"
        y1="144.5"
        x2="8.5"
        y2="15.4252"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5DEE91" />
        <stop offset="0.74" stopColor="#8DCEF5" />
      </linearGradient>
    ),
    exceed: (
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id="exceed"
        x1="353"
        y1="67.5"
        x2="-33.5"
        y2="178"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.033737" stopColor="#FF5344" />
        <stop offset="1" stopColor="#FFD19A" />
      </linearGradient>
    ),
  }[status];

  return <defs>{gradientComponent}</defs>;
};

export default HalfGaugeGradient;
