import PopupBase from '@components/elements/Popup/PopupBase';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import { Portal } from '@material-ui/core';
import LEVEL from '@styles/constants/_levels';

interface Props {
  text: string;
  closeModal: () => void;
}

const FastingConfirmModal = ({ text, closeModal }: Props) => {
  return (
    <Portal>
      <DimmedWrapper level={LEVEL.SPLASH_MODAL}>
        <PopupBase headText={text} buttonText1="확인" cancel={closeModal} />
      </DimmedWrapper>
    </Portal>
  );
};

export default FastingConfirmModal;
