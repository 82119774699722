import { TabTextContainer } from '@components/Molecules/TabsText/styles';
import { cx } from '@styles/index';
import { exerciseThemeIndex } from 'pages/exercise/states';
import { myChangeTabIndexState, myEatTabIndexState } from 'pages/main/states';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { mealDetailPeriodIndexState } from 'states/mainPageState';

export default function MainTabHeader() {
  const [, setMyChangeTabIndex] = useRecoilState(myChangeTabIndexState);
  const [, setMyEatTabIndex] = useRecoilState(myEatTabIndexState);
  const [, SetExerciseThemeIndex] = useRecoilState(exerciseThemeIndex);
  // const [, setStaticPeriodIndex] = useRecoilState(staticPeriodIndexState);
  const [, setMealDetailPeriodIndex] = useRecoilState(mealDetailPeriodIndexState);

  const tabList = [
    {
      index: 0,
      name: '기록',
      to: '',
    },
    {
      index: 1,
      name: '통계',
      to: 'statistics',
    },
  ];

  const handleClickEvent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (event.currentTarget.className.includes('active')) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (location.pathname !== '/') {
      setMyChangeTabIndex(0);
      setMyEatTabIndex(0);
      SetExerciseThemeIndex(0);
      setMealDetailPeriodIndex('day');
      // setStaticPeriodIndex('day');
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <TabTextContainer>
      <ul className={cx(['big'])}>
        {tabList.map((tab) => (
          <li key={tab.index}>
            <NavLink
              to={`/${tab.to}`}
              onClick={handleClickEvent}
              className={cx({
                ['selected']: location.pathname.split('/')[1] === tab.to,
              })}
            >
              {tab.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </TabTextContainer>
  );
}
