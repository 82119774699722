import styled from 'styled-components';
import { Empathy } from '@assets/svgs/_Icons';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  textColor?: string;
  iconFaceColor?: string;
}

function MyBMIText({ textColor, iconFaceColor = '#FFE57C', ...divProps }: Props) {
  return (
    <Container {...divProps}>
      <Empathy color={iconFaceColor} width={24} height={24} />
      <My textColor={textColor}>
        나의 <span>BMI</span>
      </My>
    </Container>
  );
}

export default MyBMIText;

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    transform: translateY(1px);
  }
`;

const My = styled.p<{ textColor?: string }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: -0.62px;
  color: ${(props) => (props.textColor ? props.textColor : '#fff')};
  padding-left: 4px;

  span {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    letter-spacing: -0.62px;
  }
`;
