import { TimePeriodType } from 'modules/myRecords';
import { atom } from 'recoil';

export const staticPeriodIndexState = atom<TimePeriodType>({
  key: '#staticPeriodIndexState',
  default: 'day',
});

export const mealDetailPeriodIndexState = atom<TimePeriodType>({
  key: '#mealDetailPeriodIndexState',
  default: 'day',
});
