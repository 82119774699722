import XDefault from '../../assets/images/icon/Atoms/XDefault';
import IconButtonWrapper from '../WrapperAtoms/IconButtonWrapper';

interface Props {
  onClick(variable: any): void;
  size: 'large' | 'semiLarge' | 'mediumBold' | 'medium' | 'small' | 'smallBold';
  color: string;
}

export default function DeleteDefaultButton({ onClick, size, color }: Props) {
  return (
    <IconButtonWrapper onClick={onClick}>
      <XDefault size={size} color={color} />
    </IconButtonWrapper>
  );
}
