import { getMySupplements } from 'apis/tagApi';
import { useQuery } from 'react-query';

const useSupplement = (date: string) => {
  const { data, refetch } = useQuery(['#main', '#supplement'], () => getMySupplements(date), {
    enabled: !!date,
  });

  return {
    data,
    refetch,
  };
};

export default useSupplement;
