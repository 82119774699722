import { getCookie } from '@utils/cookie';
import { useQuery } from 'react-query';
import { getUserConfig } from '@apis/profileApi';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { useMemo } from 'react';
import { defaultTheme, themeColorChip, themeImage } from '@pages/main/mainTheme/mainTheme';
import { ACCESS_TOKEN } from '@shared/link';

const useConfigQuery = () => {
  const userKey = getCookie(ACCESS_TOKEN);

  const { data: userInfo } = useUserInfoQuery();

  const { data, ...queryState } = useQuery(
    ['config', userKey],
    () => getUserConfig(userInfo?.member_idx ?? 0),
    {
      suspense: true,
    },
  );

  const selectThemeColor = useMemo(() => {
    if (!data) return themeColorChip[defaultTheme.theme];

    const { theme } = data;

    return themeColorChip[theme];
  }, [data]);

  const selectedTheme = useMemo(() => {
    if (!data) return defaultTheme.theme;

    const { theme } = data;

    return theme;
  }, [data]);

  const selectThemeImage = useMemo(() => {
    if (!data) return themeImage['sora'];

    const { theme } = data;

    return themeImage[theme];
  }, [data]);

  return {
    data,
    selectedTheme,
    selectThemeColor,
    selectThemeImage,
    ...queryState,
  };
};

export default useConfigQuery;
