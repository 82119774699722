import React from 'react';
import styled from 'styled-components';
import CheckDefault from '@assets/images/icon/Atoms/CheckDefault';

interface Props {
  imgUrl: string;
  onClick?: () => void;
}

const CompletedItem = ({ imgUrl, onClick }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <ImageUnit src={imgUrl} />
      <IconBox>
        <CheckDefault size="extraLarge" color="white" />
      </IconBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ImageUnit = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IconBox = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

export default CompletedItem;
