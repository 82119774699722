interface Message {
  type: string;
  payload?: any;
  service?: string;
}

export const sendReactNativeMessage = ({ type, payload, service }: Message) => {
  let sendData = payload;

  if (typeof payload === 'object') {
    sendData = JSON.stringify(payload);
  }

  try {
    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({ type, payload: sendData, service }),
    );
  } catch (error) {
    console.log(`PostMessage Error: ${JSON.stringify({ type, payload: sendData, service })}`);
  }
};
