import { getExerciseMain } from 'apis/exercise';
import { getMainSecondDisplay, getStickerInfo, getWaterInfo } from 'apis/mainApi';
import dayjs from 'dayjs';
import { useDate } from 'hooks/useDate';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const useSummaryPromiseAll = (memberIdx: number) => {
  const { date } = useDate();
  const [isClick, setIsClick] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);

  const { data, isLoading, isError, isFetched } = useQuery(
    ['#useSummaryPromiseAll'],
    () =>
      Promise.all([
        getMainSecondDisplay(date),
        getExerciseMain({ memberIdx, createdAt: date }),
        getWaterInfo(date),
        getStickerInfo(memberIdx, date),
      ]).then((rep) => {
        return rep;
      }),
    {
      enabled: isClick,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 3,
      onSuccess: (res) => {
        const message = JSON.stringify({
          type: 'summary',
          day: dayjs(date).format('M.D'),
          date: dayjs(date).format('ddd'),
          rep: res,
        });
        (window as any)?.ReactNativeWebView?.postMessage(message);
        console.log('message', message);
        setTimeout(() => {
          setIsSpinner(false);
        }, 500);

        setIsClick(false);
      },
    },
  );

  useEffect(() => {
    if (!isClick) return;
    setIsSpinner(true);
  }, [isClick]);

  return { data, isLoading, isError, isFetched, isSpinner, setIsSpinner, setIsClick };
};

export default useSummaryPromiseAll;
