import React from 'react';
import useMainFirstQuery from '@pages/main/TodayNutrients/hooks/useMainFirstQuery';
import CalorieGaugeView from '@pages/main/TodayNutrients/components/CalorieGaugeView';
import SummaryCalorie from '@pages/main/TodayNutrients/components/SummaryCalorie';
import NutrientsDetailButton from '@pages/main/TodayNutrients/components/NutrientsDetailButton';
import styled from 'styled-components';
import NutrientProgress from '@pages/main/TodayNutrients/components/NutrientProgress';
import useConfigQuery from '@hooks/queries/useConfigQuery';

const CharacterTheme = () => {
  const { data, isLoading, nutrientList } = useMainFirstQuery();
  const { selectThemeColor, selectThemeImage, isFetching } = useConfigQuery();

  if (!data || isLoading) return <></>;

  const { day, goals } = data;
  const { calories_day, sugar_day, exerciseCalories } = day;
  const { calories_goal } = goals;

  const nutrientPercentList = nutrientList.map(({ percent }) => percent);

  return (
    <CharacterThemeWrapper>
      <CalorieGaugeView
        dayPercent={nutrientPercentList}
        dayCalorie={calories_day}
        goalCalorie={calories_goal}
        usageCalorie={exerciseCalories}
        color2={selectThemeColor.color2}
        color3={selectThemeColor.color3}
        characterImg={selectThemeImage.gif!}
        previewImg={selectThemeImage.thumbnail}
        isFetching={isFetching}
      />
      <SummaryCalorie
        dayCalorie={calories_day}
        goalCalorie={calories_goal}
        exerciseCalorie={exerciseCalories}
      />
      <ProgressBox>
        {nutrientList.map((v, idx) => (
          <NutrientProgress key={idx} {...v} themeColor={selectThemeColor.color2} />
        ))}
      </ProgressBox>
      <NutrientsDetailButton
        sugar={sugar_day}
        themeBackgroundColor={selectThemeColor.color2}
        themeChartColor={selectThemeColor.color3}
      />
    </CharacterThemeWrapper>
  );
};

export default CharacterTheme;

const CharacterThemeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px;
`;

const ProgressBox = styled.div`
  display: flex;
  gap: 22px;

  width: 100%;
  padding: 0 9px;
  margin: 18px 0 30px;
`;
