import FireDefault from '@assets/images/icon/FireDefault';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  dayCalorie: number;
  goalCalorie: number;
  exerciseCalorie: number;
}
const SummaryCalorie = ({ dayCalorie, goalCalorie, exerciseCalorie }: Props) => {
  const calculatedCalorie = goalCalorie + exerciseCalorie - dayCalorie;
  const displayCalorie = Math.round(Math.abs(calculatedCalorie));

  const isExceeded = 0 > calculatedCalorie;
  const displayGoalTitle = isExceeded ? '초과됐어요' : '더 먹을 수 있어요';

  return (
    <Container>
      <ExericseCalorie>
        <FireDefault />
        <CalorieText>
          <span className="campton">
            <span className="number">{exerciseCalorie}</span>kcal
          </span>
          <span className="notoSan">소모</span>
        </CalorieText>
      </ExericseCalorie>
      <CalorieResult $exceeded={isExceeded}>
        <span className="campton">
          <span className="number">{displayCalorie}</span>kcal
        </span>
        <span className="notoSan">{displayGoalTitle}</span>
      </CalorieResult>
    </Container>
  );
};

export default SummaryCalorie;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 44px;

  .notoSan {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
  }

  .number {
    margin-right: 1px;
  }

  line-height: 120%;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
`;

const ExericseCalorie = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }

  &::after {
    content: '';
    width: 1.5px;
    height: 12px;
    border-radius: 2px;
    background-color: ${COLORS.WHITE};
    margin: 0 12px 1px;
  }
`;

const CalorieText = styled.p`
  .campton {
    margin-right: 3px;
    font-family: Campton;
    font-weight: 800;
    font-size: 15px;
  }

  .number {
    margin-right: 1px;
  }
`;

const CalorieResult = styled.p<{ $exceeded: boolean }>`
  margin-bottom: 1px;

  .campton {
    margin-right: 4px;
    font-family: Campton;
    font-weight: 800;
    font-size: 15px;
    color: ${({ $exceeded }) => ($exceeded ? '#FF5647' : '#55fa90')};
  }
`;
