import { useQuery } from 'react-query';
import { getUserInfo } from '@apis/profileApi';
import { getCookie } from '@utils/cookie';
import { ACCESS_TOKEN } from '@shared/link';

const useUserInfoQuery = () => {
  const userKey = getCookie(ACCESS_TOKEN);
  const { data, ...queryState } = useQuery(['getUserInfo', userKey], () => getUserInfo(), {
    suspense: true,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    ...queryState,
  };
};

export default useUserInfoQuery;
