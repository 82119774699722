import UnSyncHealthDataPopup from '@components/health/UnSyncHealthDataPopup';
import { NotionPath } from '@constants/internalPath';
import useTopModal from '@hooks/useTopModal';
import { getCookie } from '@utils/cookie';
import { useNavigate } from 'react-router-dom';

const useHealthExceptionCase = () => {
  const navigate = useNavigate();
  const { openModal: openTopModal, closeModal: closeTopModal } = useTopModal();

  /** 건강 데이터 연동 실패 팝업
   * 권한 거절 상태
   * 네트워크 등 모종의 문제
   * 해당 날짜의 데이터가 없는 경우
   */
  const onUnSyncHealthDataPopup = () => {
    openTopModal(UnSyncHealthDataPopup, {
      onClose: () => closeTopModal(UnSyncHealthDataPopup),
      onNotionPage: () => {
        closeTopModal(UnSyncHealthDataPopup);
        onHealthAppAuthNotionPage();
      },
    });
  };

  /* 건강 권한 설정 설명 노션 페이지 */
  const onHealthAppAuthNotionPage = () => {
    navigate(`/notion${NotionPath.HEALTH_APP_AUTH}`);
  };

  /* 안드로이드 헬스커넥트 연동 설명 노션 페이지 (안드로이드 최초 1회) */
  const onAndroidHealthNotionPage = () => {
    navigate(`/notion${NotionPath.ANDROID_HEALTH_CONNECT}`);
  };

  /* 안드로이드 헬스커넥트 연동 설명 노션 페이지 최초 1회 checked 여부 확인*/
  const checkHealthConnectModalView = () => {
    const ON_HEALTH_CONNECT_INFO_KEY = 'onHealthConnectInfo';
    const onHealthConnectInfoCheck = getCookie(ON_HEALTH_CONNECT_INFO_KEY);

    return onHealthConnectInfoCheck; // checked | undefined
  };

  /* 안드로이드 헬스커넥트 미설치 시, 플레이스토어 리다이렉트 */
  const redirectHealthConnectPlayStore = () => {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.google.android.apps.healthdata&pcampaignid=web_share';
  };

  return {
    onUnSyncHealthDataPopup,
    onAndroidHealthNotionPage,
    onHealthAppAuthNotionPage,
    redirectHealthConnectPlayStore,
    checkHealthConnectModalView,
  };
};

export default useHealthExceptionCase;
